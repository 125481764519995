import { useRef, useCallback, useMemo } from 'react';

export const useScrollToTop = () => {
  const gridRef = useRef();
  const scrollToTopGrid = useCallback(() => {
    gridRef.current.scrollTop = 0;
    gridRef.current.scrollLeft = 0;
  }, []);
  return useMemo(() => [gridRef, scrollToTopGrid], [scrollToTopGrid]);
};
export default useScrollToTop;
