import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp } from '@fortawesome/pro-regular-svg-icons';

const ClickImport = ({ hide }) => {
  if (hide) return null;
  return (
    <div className="no-photos">
      <FontAwesomeIcon.Memo icon={faHandPointUp} />
      <span>Click upload photo's to begin</span>
    </div>
  );
};

export default ClickImport;
