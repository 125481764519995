import React, { useMemo, memo } from 'react';
import SceneHelper from 'utils/SceneHelper';
import { getQualityFromQuality } from 'utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { min } from 'lodash';
import { useSelector } from 'react-redux';
import { usePageConfigContext } from 'context/pageConfig';

const ImageQualityIcon = ({ shape, quality }) => {
  const pageConfig = usePageConfigContext();
  const maxQWidth = useSelector((storeState) => {
    const {
      scene: {
        config: {
          dimensions: { width },
        },
      },
    } = storeState;
    const sizes = SceneHelper.getEditorSizePx(storeState, pageConfig?.pageId);
    return 50 * (width / sizes[0]);
  });
  const dimensionsFrame = useSelector((storeState) => {
    if (shape.x === 0) {
      const dimensions = SceneHelper.getDimensionsSync(storeState);
      return dimensions.frame;
    }
    return 0;
  });
  const qContainerWidth = min([maxQWidth, min([shape.width, shape.height]) / 5]);
  const iconRatio = 1.6;
  const qWidth = qContainerWidth / iconRatio;
  const qMargin = qContainerWidth / 6;
  const [, , isGoodQuality] = useMemo(() => getQualityFromQuality(quality), [quality]);
  if (isGoodQuality) return null;
  return (
    <g
      className={`image-quality-indicator${shape.id}`}
      x="0"
      y="0"
      transform={`translate(${shape.x} ${shape.y}) rotate(${shape.rotation || 0})`}
    >
      <rect
        x={qMargin + dimensionsFrame}
        y={shape.height - qContainerWidth - qMargin - dimensionsFrame}
        width={qContainerWidth}
        height={qContainerWidth}
        fill="#fff7"
        rx={qContainerWidth / 4}
      />
      <FontAwesomeIcon.Memo
        icon={faExclamationTriangle}
        x={(qContainerWidth - qWidth) / 2 + qMargin + dimensionsFrame}
        y={shape.height - qWidth - (qContainerWidth - qWidth) / 2 - qMargin - dimensionsFrame}
        width={qWidth}
        height={qWidth}
        color="red"
      />
    </g>
  );
};

export default memo(ImageQualityIcon, fastDeepEqual);
