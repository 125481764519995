/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useMemo } from 'react';
import Button from 'components/common/Button';
import { deleteImage, uploadPhotos, onCompleteUploadImage, updatePreviewImages } from 'actions/scene';
import * as photoActions from 'actions/photos';
import { batch } from 'react-redux';
import ReactFileReader from 'react-file-reader';
import { chunk } from 'lodash';
import { v4 as uuid } from 'uuid';
import ImageUploader from 'utils/image-upload/adapters/pixaprints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import store from 'store';
import GalleryExplorer from 'utils/gallery-explorer';
import { modalTypes, modes } from 'constants/index';
import MozillaAndroidDoesNotSupportMultiFileSelect from 'constants/mozillaAndroidDoesNotSupportMultiFileSelect';
import SceneHelper from 'utils/SceneHelper';
import { openModal } from 'utils/utils';
import PixaPrints from 'api';
import { generateImagePreview, idMap } from '../utils';

const MemoReactFileReader = React.memo(ReactFileReader);

const selectedOnboardingImage = { selected: false };

const uploader = new ImageUploader();
export const uploadAndWriteToStore = async (files, onClick) => {
  const folderId = GalleryExplorer.getCurrentFolder();
  if (!selectedOnboardingImage.selected) selectedOnboardingImage.selected = true;
  const filesChunks = chunk(files, 50);
  const mode = SceneHelper.getMode(store.getState());
  const canvas = document.createElement(modes.canvas);
  const context = canvas.getContext('2d');

  for (const files of filesChunks) {
    const photosWithoutPreview = [];
    for (const file of files) {
      file.id = file.id || uuid();
      file.folderId = folderId;
      const photo = await generateImagePreview(file, { doNotCreateThumbnail: true });
      photosWithoutPreview.push(photo);
    }
    batch(() => {
      store.dispatch(photoActions.addToStart(photosWithoutPreview));
      if (onClick) {
        onClick(photosWithoutPreview);
      }
      if (mode === modes.photoPrint) {
        store.dispatch(uploadPhotos({ images: photosWithoutPreview }));
      }
    });
    for (const file of files) {
      uploader.enqueue(file, {
        onProgress: ({ progress }) => {
          store.dispatch(photoActions.updateUploadProgress({ id: file.id, progress }));
        },
        onComplete: ({ url, data }) => {
          const { naturalHeight, naturalWidth, width, height, thumbnailSrc, ...photo } =
            GalleryExplorer.normalizePhoto(data);
          store.dispatch(
            onCompleteUploadImage({
              image: {
                oldId: file.id,
                id: photo.id,
                thumbnailSrc,
                naturalHeight,
                naturalWidth,
                width,
                height,
                initialized: false,
                uploaded: true,
              },
            }),
          );
          store.dispatch(
            photoActions.uploadCompleted({
              id: file.id,
              photo: {
                ...photo,
                src: url,
                thumbnailSrc,
                rotate: naturalHeight > naturalWidth,
                naturalHeight,
                naturalWidth,
                width,
                height,
              },
            }),
          );
          idMap[file.id] = photo.id;
          PixaPrints.saveState({ autosave: true });
        },
        onError: ({ error }) => {
          console.log('error:', error);
          store.dispatch(photoActions.uploadError({ id: file.id, error }));
          store.dispatch(deleteImage({ id: file.id, error }));
          const message = 'Photo upload failed. Please try again';
          openModal({ type: modalTypes.warning, modalProps: { message } });
        },
      });
    }
  }

  const filesChunksForImageUpdate = chunk(files, 5);
  for (const files of filesChunksForImageUpdate) {
    const photosWithPreview = [];
    for (const file of files) photosWithPreview.push(await generateImagePreview(file, { canvas, context }));
    const newPreview = photosWithPreview.map(
      ({ id: _id, naturalWidth, naturalHeight, width, height, src, thumbnailSrc }) => ({
        id: _id,
        initialized: false,
        naturalWidth,
        naturalHeight,
        width,
        height,
        src,
        thumbnailSrc,
      }),
    );
    batch(() => {
      store.dispatch(photoActions.update({ addFields: photosWithPreview, updatePreview: true }));
      store.dispatch(updatePreviewImages({ images: newPreview }));
    });
  }
};

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };
const fontAwesomeIconButtonStyle = { borderColor: 'var(--toolbar-bg)', float: 'right', display: 'flex' };

const fileTypes = ['.png', '.jpg', '.jpeg', '.heic'];
const UploadButton = () => {
  const handleFileUpload = React.useCallback((files) => {
    uploadAndWriteToStore(files);
  }, []);
  const fileReaderChildren = useMemo(
    () => (
      <Button
        type="button"
        size="lg"
        textSize="md"
        rounded
        style={fontAwesomeIconButtonStyle}
        color="black"
        className="button-upload-photo"
      >
        <FontAwesomeIcon.Memo icon={faUpload} style={fontAwesomeIconStyle} />
        <span>Upload photo's</span>
      </Button>
    ),
    [],
  );
  return (
    <MemoReactFileReader
      multipleFiles={!MozillaAndroidDoesNotSupportMultiFileSelect}
      fileTypes={fileTypes}
      handleFiles={handleFileUpload}
    >
      {fileReaderChildren}
    </MemoReactFileReader>
  );
};

export default UploadButton;
