import React from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { modes } from 'constants/index';
import { useMode } from 'hooks';

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };

const BackButton = ({ onClick, className }) => {
  const mode = useMode();
  if (!onClick || mode === modes.photoPrint) return null;
  return (
    <Button type="button" className={className} size="lg" textSize="md" rounded outline onClick={onClick}>
      <FontAwesomeIcon.Memo icon={faChevronLeft} style={fontAwesomeIconStyle} />
      Go back
    </Button>
  );
};

export default BackButton;
