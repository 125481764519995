import React from 'react';
import { modes } from 'constants/index';
import { useDimensions, useMode } from 'hooks';

const stroke = '#fff';
const strokeWidth = 1;

const EdgesCrop = ({ object }) => {
  const dimensions = useDimensions();
  const mode = useMode();
  if (mode === modes.canvas && !object.isDraggable) {
    return (
      <>
        <defs>
          <clipPath id="edgesCropClipPath">
            <rect x="0" y="0" width={object.width} height={object.height} />
          </clipPath>
        </defs>
        <g clipPath="url(#edgesCropClipPath)">
          <g transform={`rotate(${-(object.rotation || 0)}) translate(${-object.x},${-object.y})`}>
            <line
              _="top"
              x1={0}
              y1={dimensions.frame}
              x2={dimensions.width + 2 * dimensions.frame}
              y2={dimensions.frame}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <line
              _="right"
              x1={dimensions.width + dimensions.frame}
              y1={0}
              x2={dimensions.width + dimensions.frame}
              y2={dimensions.height + 2 * dimensions.frame}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <line
              _="bottom"
              x1={0}
              y1={dimensions.height + dimensions.frame}
              x2={dimensions.width + 2 * dimensions.frame}
              y2={dimensions.height + dimensions.frame}
              stroke={stroke}
              strokeWidth={strokeWidth}
            />
            <line
              _="left"
              x1={dimensions.frame}
              y1={0}
              x2={dimensions.frame}
              y2={dimensions.height + 2 * dimensions.frame}
              stroke={stroke}
              fill="red"
              strokeWidth={strokeWidth}
            />
          </g>
        </g>
      </>
    );
  }
  return null;
};

export default EdgesCrop;
