import { useMemo } from 'react';
import { useBreakpoints } from 'react-use-breakpoints';

export const useIsMobile = () => {
  const {
    windowSize: { innerWidth },
  } = useBreakpoints();
  const isMobile = useMemo(() => innerWidth < 500, [innerWidth]);
  return isMobile;
};

export default useIsMobile;
