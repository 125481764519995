import React from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { every } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { updateModalProps } from 'actions/modal';
import store from 'store';
import PixaPrints from 'api';
import { useAdmin, useIsOneImageLoaded } from 'hooks';
import { openModal } from 'utils/utils';

const initialResponseStatus = { status: null, message: null };

const openPhotosLoadingModal = () =>
  openModal({
    type: 'warning',
    modalProps: { message: 'Please wait until your photos are uploaded' },
  });
const isAllPhotosLoaded = () =>
  every(store.getState().photos.photos, 'uploaded') ? true : !!(openPhotosLoadingModal() && false);

const handleSave = async () => {
  if (!isAllPhotosLoaded()) return;
  openModal({ type: 'loading', modalProps: { initialResponseStatus } });
  try {
    await PixaPrints.saveState();
    store.dispatch(updateModalProps({ success: true, message: 'Successfully saved' }));
  } catch (e) {
    store.dispatch(
      updateModalProps({
        success: false,
        message: e.fakeMessage || 'Error while saving design',
        cancellable: e.dismissible,
      }),
    );
  }
};

const SaveButton = () => {
  const [designId] = useSelector(
    ({
      scene: {
        config: { designId },
      },
    }) => [designId],
    shallowEqual,
  );
  const admin = useAdmin();
  const isOneImageLoaded = useIsOneImageLoaded();
  const isDisabledSaveButton = (!isOneImageLoaded && !admin) || (admin && !designId);
  return (
    <Button
      onClick={handleSave}
      rounded
      disabled={isDisabledSaveButton}
      color="azure"
      className="nav-bar-button"
      data-for="bottomTooltip"
      data-tip={admin ? 'Save changes' : 'Save draft'}
    >
      <FontAwesomeIcon.Memo icon={faSave} />
    </Button>
  );
};

export default SaveButton;
