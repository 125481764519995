/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useRef } from 'react';
import { clickOnImage, changeLayoutId, onDragEndImage } from 'actions/scene';
import { useDispatch } from 'react-redux';
import { useDrag } from 'react-dnd';
import ProgressBar from 'components/common/ProgressBar';
import Grid from 'components/common/NewGrid';
import classnames from 'classnames';
import { find } from 'lodash';
import store from 'store';
import { useSetIsEditing } from 'components/editors/NewEditor/hooks/useIsEditing';
import { modalTypes, modes, shapeTypes } from 'constants/index';
import { close } from 'actions/modal';
import PixaPrints from 'api';
import { openModal } from 'utils/utils';
import SceneHelper from 'utils/SceneHelper';
import { getPrintLimitMaxMinAndObjectsLengthSync, idMap } from '../utils';

const onDragEnd = (photo, id, objects, dispatch) => {
  let imageId = photo.id;
  if (idMap[imageId]) {
    imageId = idMap[imageId];
  }
  const newPhoto = find(store.getState().photos.photos, { id: imageId });
  dispatch(onDragEndImage({ id, image: newPhoto }));
};

const handleClickOnImage = async (image, { onClick, dispatch }) => {
  const shapes = SceneHelper.getCurrentPageShapes(store.getState(), [shapeTypes.dropZone, shapeTypes.layout]);
  const mode = SceneHelper.getMode(store.getState());
  if (shapes.length === 0 && mode === modes.canvas) {
    const addPhotoContainer =
      ({ withFrame }) =>
      () => {
        store.dispatch(changeLayoutId({ id: 'default', withFrame }));
        dispatch(clickOnImage({ image }));
        PixaPrints.saveState({ autosave: true });
        store.dispatch(close());
      };
    openModal({
      type: modalTypes.confirm,
      modalProps: {
        onConfirm: addPhotoContainer({ withFrame: false }),
        onClose: addPhotoContainer({ withFrame: true }),
        header: 'Add layout',
        text: 'Please select where to place the layout',
        confirmText: 'Colour edges',
        cancelText: 'Image on edges',
      },
    });
  } else if (shapes.length === 0 && mode === modes.photoPrint) {
    store.dispatch(changeLayoutId({ id: 'default' }));
    await dispatch(clickOnImage({ image }));
  } else {
    await dispatch(clickOnImage({ image }));
  }
  onClick && onClick();
  PixaPrints.saveState({ autosave: true });
};

const draggableDivStyle = { height: '100%' };
const Photo = React.memo(
  ({
    id,
    src,
    thumbnailSrc,
    progress,
    uploaded,
    alwaysShowWhiteRectangle,
    isActive,
    style,
    onClick,
    photo,
    ...rest
  }) => {
    const setIsEditing = useSetIsEditing();
    const dispatch = useDispatch();
    const [{ opacity }, dragRef] = useDrag({
      type: 'image',
      item: () => {
        setIsEditing(false);
        return { type: 'image', photo };
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          setIsEditing(false);
          const { objects } = store.getState().scene;
          onDragEnd(item.photo, dropResult.name, objects, dispatch);
        }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    });

    const hasDoubleClick = useRef(false);

    const handleClick = useCallback(() => {
      if (hasDoubleClick.current) return;
      hasDoubleClick.current = true;
      const {
        scene,
        scene: {
          config: { layouts, layoutId, mode },
        },
      } = store.getState();
      if (mode === modes.photoPrint) {
        const { printLimitMax, photoCount } = getPrintLimitMaxMinAndObjectsLengthSync({ scene });
        const isMultiAperture = ((find(layouts, { id: layoutId }) || {}).layout || []).length > 1;
        if (printLimitMax > photoCount) {
          !isMultiAperture && handleClickOnImage({ id, src, uploaded, ...rest }, { onClick, dispatch });
        } else {
          const message = 'Print limit reached for this product';
          openModal({ type: modalTypes.warning, modalProps: { message } });
        }
      } else {
        handleClickOnImage({ id, src, uploaded, ...rest }, { onClick, dispatch });
      }
      setTimeout(() => {
        hasDoubleClick.current = false;
      }, 500);
    }, [dispatch, id, onClick, rest, src, uploaded]);
    return (
      <Grid.Item key={id}>
        <div
          className={classnames({ checked: isActive }, 'cursor-pointer')}
          ref={dragRef}
          draggable
          style={draggableDivStyle}
        >
          <img alt={id} src={thumbnailSrc} opacity={opacity} onClick={handleClick} loading="lazy" />
          <ProgressBar
            alwaysShowWhiteRectangle={alwaysShowWhiteRectangle}
            uploaded={uploaded}
            value={typeof progress === 'number' ? progress : 0}
          />
        </div>
      </Grid.Item>
    );
  },
);

export default Photo;
