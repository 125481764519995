import { createAction, handleActions } from 'redux-actions';
import { findIndex, remove } from 'lodash';

const defaultState = {
  sizes: [],
};

const prefix = 'CONFIG_';

export const setSizes = createAction(`${prefix}SIZES`);
export const setDesigns = createAction(`${prefix}DESIGNS`);
export const addDesign = createAction(`${prefix}ADD_DESIGNS`);
export const deleteDesign = createAction(`${prefix}DELETE_DESIGNS`);

const reducer = handleActions(
  {
    [setSizes]: (state, action) => ({ ...state, sizes: action.payload }),
    [setDesigns]: (state, action) => {
      const designs = (action.payload || []).map(({ objects, ...design } = {}) => ({
        ...design,
        id: design.id.toString(),
        pages: objects.pages ? objects.pages : [{ shapes: objects }],
      })); // transform design object
      return { ...state, designs };
    },
    [addDesign]: (state, action) => {
      const { designs = [] } = state;
      const currentDesign = findIndex(designs, { id: action.payload.id });
      if (currentDesign >= 0) {
        designs[currentDesign] = action.payload;
      } else {
        designs.push(action.payload);
      }
      return { ...state, designs };
    },
    [deleteDesign]: (state, action) => {
      let { designs } = state;
      designs = remove(designs, (design) => design.id !== action.payload);
      return { ...state, designs };
    },
  },
  defaultState,
);

export default reducer;
