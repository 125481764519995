import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import c from './MultiOptionButton.module.scss';

const MultiOptionButton = ({ options = [], value, onChange, disabled, LabelComponent }) => (
  <div className={c.container}>
    {options.map(({ value: optionValue, label }) => {
      const selected = value === optionValue;
      return (
        <div
          className={classnames(c.button, 'no-selection', {
            [c.selected]: selected,
            selected,
          })}
          key={optionValue}
          onClick={() => {
            !disabled && onChange(optionValue);
          }}
        >
          {LabelComponent ? <LabelComponent value={value} optionValue={optionValue} label={label} /> : label}
          {selected ? (
            <div className="check-mark">
              <FontAwesomeIcon.Memo icon={faCheck} />
            </div>
          ) : null}
        </div>
      );
    })}
  </div>
);

export default MultiOptionButton;
