/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { addClipart } from 'actions/scene';
import Grid from 'components/common/NewGrid';
import BackButton from 'components/common/GridBackButton';
import classnames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import c from './Clipart.module.scss';
import { TOOLBAR_WIDTH } from '../Toolbar/Toolbar';

const Photo = ({ id, thumbnailSrc, isActive, onClick, photo }) => {
  const handleClick = useCallback(() => {
    store.dispatch(addClipart({ ...photo }));
  }, [photo]);
  return (
    <Grid.Item key={id} className={classnames({ checked: isActive })}>
      <img alt={id} className={c.img} src={thumbnailSrc} onClick={onClick || handleClick} />
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);

const Clipart = ({ onClickBackButton }) => {
  const photos = useSelector(({ photos: { clipart } }) => clipart || []);
  // const sizeCalculated = photos.length ? '50%' : '100%';
  const isMobile = useIsMobile();
  const photoSizeWidth = isMobile ? 100 : '50%';
  const photoSizeHeight = isMobile ? 100 : TOOLBAR_WIDTH / 2;
  return (
    <div className="colors-container">
      <Grid doNotWrapIntoMotionDiv={photos.length > 20} width={photoSizeWidth} height={photoSizeHeight}>
        <BackButton onClick={onClickBackButton} />
        {photos.map((photo) => (
          <MemoPhoto key={photo.id} photo={photo} {...photo} />
        ))}
      </Grid>
    </div>
  );
};

export default Clipart;
