import { useMemo } from 'react';

const useTextProps = ({ textAlign, shape }) => {
  const textProps = useMemo(() => {
    if (textAlign === 'right') {
      return {
        x: shape.width - shape.margin.right,
        textAnchor: 'end',
      };
    }
    if (textAlign === 'left') {
      return {
        x: shape.margin.left,
        textAnchor: 'start',
      };
    }
    return {
      x: shape.width / 2,
      textAnchor: 'middle',
    };
  }, [shape.margin, shape.width, textAlign]);
  return textProps;
};

export default useTextProps;
