import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useIsCrop from 'components/editors/NewEditor/hooks/useIsCrop';
import { useEditedShapeOnCurrentPage } from 'hooks';
import CropPhotoComponent from './CropPhotoComponent';

const CropPhotoContainer = () => {
  const [, toggleIsCrop] = useIsCrop();
  const shape = useEditedShapeOnCurrentPage();
  const [sceneMaxWidthPx, sceneMaxHeightPx, sceneContainerWidthPx, sceneContainerHeightPx] = useSelector(
    ({ scene: { state } }) => [
      state.sceneWidthPx,
      state.sceneHeightPx,
      state.sceneContainerWidthPx,
      state.sceneContainerHeightPx,
    ],
  );
  const [editorWidthPx, editorHeightPx] = useMemo(() => {
    const scale = Math.min(sceneMaxWidthPx / shape.width, sceneMaxHeightPx / shape.height);
    return [shape.width * scale, shape.height * scale];
  }, [shape, sceneMaxWidthPx, sceneMaxHeightPx]);

  if (!shape.image) return null;
  return (
    <CropPhotoComponent
      objectId={shape.id}
      object={shape}
      rotationMask={shape.rotation}
      editorHeightPx={editorHeightPx} // TODO: rename to objectHeightPx
      editorWidthPx={editorWidthPx}
      toggleIsCrop={toggleIsCrop}
      viewWidth={sceneContainerWidthPx}
      viewHeight={sceneContainerHeightPx}
    />
  );
};

export default CropPhotoContainer;
