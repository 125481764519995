import SceneHelper from 'utils/SceneHelper';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';

export const initImage = (object, config) => {
  if (!object.image) return object;
  const shape = SceneHelper.transformShapeFromPercent({ shape: object, storeState: { scene: { config } } });
  const position = CalculationShapeHelper.fitImageToShape(shape, object.image);
  const newObject = object;
  newObject.image = { ...newObject.image, initialized: true, ...position };
  return newObject;
};
