import React from 'react';
import { useHideEventsInGrid, useToggleHideEventsInGrid } from 'hooks';
import Switch from 'components/common/Switch';
import { useAdmin } from 'hooks/sceneHooks';

const SwitchEvensInGrid = ({ className }) => {
  const hideEventsInGrid = useHideEventsInGrid();
  const toggleHideEventsInGrid = useToggleHideEventsInGrid();
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <Switch
      checked={hideEventsInGrid}
      className={className}
      onChange={toggleHideEventsInGrid}
      label="Hide events in grid"
    />
  );
};

export default SwitchEvensInGrid;
