import React from 'react';
import { useUpdateShape } from 'hooks';
import VerticalDivider from 'components/actionBar/components/VerticalDivider';
import Collapse from './Collapse';

export const CalendarSizesStyle = ({ shape }) => {
  const onChange = useUpdateShape(shape.id);

  return (
    <Collapse text="Size">
      Padding:{' '}
      <div>
        left{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { paddingLeft: parseFloat(e.target.value) || 0 } })}
          value={shape.calendarShapeSize?.paddingLeft || 0}
        />
      </div>
      <div>
        right{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { paddingRight: parseFloat(e.target.value) || 0 } })}
          value={shape.calendarShapeSize?.paddingRight || 0}
        />
      </div>
      <div>
        top{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { paddingTop: parseFloat(e.target.value) || 0 } })}
          value={shape.calendarShapeSize?.paddingTop || 0}
        />
      </div>
      <div>
        bottom{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { paddingBottom: parseFloat(e.target.value) || 0 } })}
          value={shape.calendarShapeSize?.paddingBottom || 0}
        />
      </div>
      <VerticalDivider />
      Day margin:{' '}
      <div>
        left{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { dayMargin: { left: parseFloat(e.target.value) || 0 } } })}
          value={shape.calendarShapeSize?.dayMargin?.left || 0}
        />
      </div>
      <div>
        right{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { dayMargin: { right: parseFloat(e.target.value) || 0 } } })}
          value={shape.calendarShapeSize?.dayMargin?.right || 0}
        />
      </div>
      <div>
        top{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { dayMargin: { top: parseFloat(e.target.value) || 0 } } })}
          value={shape.calendarShapeSize?.dayMargin?.top || 0}
        />
      </div>
      <div>
        bottom{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ calendarShapeSize: { dayMargin: { bottom: parseFloat(e.target.value) || 0 } } })}
          value={shape.calendarShapeSize?.dayMargin?.bottom || 0}
        />
      </div>
    </Collapse>
  );
};

export default CalendarSizesStyle;
