import React from 'react';

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps}>
      {data.Component ? <data.Component /> : null}
    </div>
  );
};

export default CustomOption;
