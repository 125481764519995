import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import Button from 'components/common/Button';
import Lottie from 'react-lottie';
import { isEdge } from 'utils/utils';
import { eye as eyeAnimationData, warning as warningAnimationData } from '../LottieAnimations';
import MSEDGEeyeSrc from '../MSEDGEeye.png';
import MSEDGEwarningSrc from '../MSEDGEwarning.png';

const eyeAnimationOptions = (isEdge) => ({
  loop: !isEdge,
  autoplay: !isEdge,
  animationData: eyeAnimationData,
  rendererSettings: {
    viewBoxSize: '345 345 110 110', // 348 364 101 71
  },
});

const warningAnimationOption = (isEdge) => ({
  loop: false,
  autoplay: !isEdge,
  animationData: warningAnimationData,
  rendererSettings: {
    viewBoxSize: '75 75 350 350',
  },
});

const useLabels = (isAllImageLoaded) =>
  useMemo(
    () =>
      isAllImageLoaded ? ['Yes, add to basket', 'Let me check it again'] : ['Order anyway', 'Let me check it again'],
    [isAllImageLoaded],
  );

const header = 'Everything look ok?';
const text = 'Tip: Check important parts of the photo are showing. To adjust cropping, click on photo.';

const OrderModal = ({ onConfirm, onClose, warnings }) => {
  const isNoWarnings = !warnings?.length;
  const [confirmText, cancelText] = useLabels(isNoWarnings);
  return (
    <>
      <div className="modal-header">
        <div className="modal-header-icon">
          {isEdge() ? (
            <img alt="" src={isNoWarnings ? MSEDGEeyeSrc : MSEDGEwarningSrc} />
          ) : (
            <Lottie
              options={isNoWarnings ? eyeAnimationOptions() : warningAnimationOption()}
              height={50}
              width={50}
              isStopped={false}
              isPaused={false}
            />
          )}
        </div>
        <div className="modal-header-text">{header}</div>
      </div>
      <div className="modal-body">
        {warnings?.map((warning) => (
          <div className="modal-warning">{warning}</div>
        ))}
        <div className="modal-text">{text}</div>
      </div>
      <div className="action-buttons-vertical">
        <Button color="azure" rounded size="lg" onClick={onConfirm}>
          <FontAwesomeIcon.Memo icon={faCheck} style={{ height: '1em', width: '1em', marginRight: '0.8em' }} />
          {confirmText}
        </Button>
        <Button outline rounded size="lg" onClick={onClose}>
          {cancelText}
        </Button>
      </div>
    </>
  );
};

export default OrderModal;
