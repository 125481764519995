import React from 'react';
import { useToggleIsEditing } from 'components/editors/NewEditor/hooks/useIsEditing';
import Button from 'components/common/Button';
import './DoneButton.scss';

export const DoneButton = ({ onClick }) => {
  const toggleIsEditing = useToggleIsEditing();
  return (
    <Button className="done-button" onClick={onClick || toggleIsEditing}>
      Done
    </Button>
  );
};

export default DoneButton;
