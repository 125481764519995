import React from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePlus } from '@fortawesome/pro-regular-svg-icons';
import ReactTooltip from 'react-tooltip';
import useIsMobile from 'hooks/useIsMobile';
import { every } from 'lodash';
import { updateModalProps } from 'actions/modal';
import store from 'store';
import PixaPrints from 'api';
import { useAdmin, useIsOneImageLoaded, useViewAllPages } from 'hooks';
import useTextIsEditing from 'components/editors/NewEditor/hooks/useTextEditing';
import { modalTypes } from 'constants/index';
import { openModal } from 'utils/utils';
import OrderButton from './components/OrderButton';
import NextStepButton from './components/NextStepButton';
import PreviousStepButton from './components/PreviousStepButton';
import SavedTimeAgo from './components/SavedTimeAgo';
import DesignId from './components/DesignId';
import SaveButton from './components/SaveButton';
import Logo from './components/Logo';
import './NavBar.scss';

const openPhotosLoadingModal = () =>
  openModal({
    type: modalTypes.warning,
    modalProps: { message: 'Please wait until your photos are uploaded' },
  });
const isAllPhotosLoaded = () =>
  every(store.getState().photos.photos, 'uploaded') ? true : !!(openPhotosLoadingModal() && false);

const handleSaveAs = () => {
  if (!isAllPhotosLoaded()) return;
  const onSave = async ({ title, description }) => {
    await PixaPrints.saveDesign({ title, description });
    openModal({ type: 'loading', modalProps: { success: true, message: 'Successfully saved' } });
    store.dispatch(updateModalProps({ success: true, message: 'Successfully saved' }));
  };
  openModal({ type: modalTypes.saveDesign, modalProps: { onConfirm: onSave } });
};

const NavBarContent = ({ loading, admin, isDisabledSaveAsButton, viewAllPages, isMobile }) => (
  <div className="navbar">
    <Logo />
    {admin ? <div className="navbar-admin">Admin</div> : null}

    {!loading && (
      <div className={viewAllPages && isMobile ? 'navbar-center' : 'navbar-right'}>
        <SavedTimeAgo />
        <DesignId />
        {admin ? (
          <Button
            onClick={handleSaveAs}
            rounded
            disabled={isDisabledSaveAsButton}
            className="download-button"
            data-for="bottomTooltip"
            data-tip="Save as new design"
          >
            <FontAwesomeIcon.Memo icon={faFilePlus} style={{ height: '1.5em', width: '1.5em' }} />
          </Button>
        ) : null}
        <PreviousStepButton />
        <SaveButton />
        <NextStepButton />
        <OrderButton />
        <ReactTooltip id="bottomTooltip" place="bottom" type="dark" effect="solid" />
      </div>
    )}
  </div>
);
const NavBar = ({ loading }) => {
  const admin = useAdmin();
  const isOneImageLoaded = useIsOneImageLoaded();
  const isMobile = useIsMobile();
  const [textEditing] = useTextIsEditing();
  const isDisabledSaveAsButton = !((!admin && isOneImageLoaded) || admin);
  const viewAllPages = useViewAllPages();

  if (textEditing && isMobile) return null;

  return (
    <NavBarContent
      loading={loading}
      admin={admin}
      isDisabledSaveAsButton={isDisabledSaveAsButton}
      viewAllPages={viewAllPages}
      isMobile={isMobile}
    />
  );
};

export default NavBar;
