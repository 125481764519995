import React from 'react';
import classNames from 'classnames';
import c from './FormField.module.scss';

export default function FormField({ children, label, className }) {
  return (
    <div className={classNames(c.container, className)}>
      <label className={c.label}>{label}</label>
      <div className={c.input}>{children}</div>
    </div>
  );
}
