import React, { memo } from 'react';
import equal from 'fast-deep-equal/es6/react';
import { Helmet } from 'react-helmet';
import { replace } from 'lodash';
import { useAllFonts } from 'hooks';

const HelmetFonts = () => {
  const fonts = useAllFonts();
  return (
    <Helmet>
      {fonts.map((font) => (
        <link
          key={font}
          kay="font"
          href={`https://fonts.googleapis.com/css?family=${replace(font, ' ', '+')}`}
          rel="stylesheet"
        />
      ))}
    </Helmet>
  );
};

export default memo(HelmetFonts, equal);
