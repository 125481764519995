import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSendBackward } from '@fortawesome/pro-regular-svg-icons';
import { useBringBackward } from 'hooks';

const BringBackward = () => {
  const bringBackward = useBringBackward();
  return (
    <div className="icon-button-container order-option" onClick={bringBackward}>
      <FontAwesomeIcon.Memo className="icon-button" icon={faSendBackward} />{' '}
      <span className="order-option-label">Send backward</span>
    </div>
  );
};

export default BringBackward;
