import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from './IconButton.module.scss';

const IconButton = ({ icon, onClick }) => {
  return (
    <div className={c.iconButtonContainer} onClick={onClick}>
      <FontAwesomeIcon.Memo icon={icon} className="icon-button" />
    </div>
  );
};

export default IconButton;
