import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateConfig } from 'actions/scene';

const useUpdateConfig = (key) => {
  const dispatch = useDispatch();
  return useCallback((modifier) => dispatch(updateConfig(key ? { [key]: modifier } : modifier)), [dispatch, key]);
};

export default useUpdateConfig;
