import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { addText, addDropZone, updateViewAllPages } from 'actions/scene';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import Button from 'components/common/Button';
import { faTextSize, faImage, faCut, faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import { handleChangeStartDate } from 'utils/calendarUtils';
import { faCalendarEdit, faSort } from '@fortawesome/pro-light-svg-icons';
import Grid from 'components/common/Grid';
import SceneHelper from 'utils/SceneHelper';
import { modes } from 'constants/index';
import Clipart from '../Clipart';
import Backgrounds from '../Backgrounds';
import c from './Customize.module.scss';

const fontAwesomeIconStyle = { height: '1.2em', width: '1.2em' };

const ButtonItem = ({ icon, onClick, label }) => (
  <Button type="button" size="lg" textSize="md" rounded color="black" onClick={onClick} outline>
    <FontAwesomeIcon.Memo icon={icon} style={fontAwesomeIconStyle} />
    <div>{label}</div>
  </Button>
);

const Customize = () => {
  const currentBackgroundImageId = useSelector(({ scene: { objects } }) => {
    const background = find(objects, { type: 'background' });
    if (background) {
      return background.image.id;
    }
    return false;
  });
  const showBackgroundPicker = useSelector(
    ({
      scene: {
        config: { showBackgroundPicker, admin },
      },
    }) => admin || showBackgroundPicker,
  );

  const showCalendarSettings = useSelector((storeState) => {
    const isAdmin = SceneHelper.getAdmin(storeState);
    const mode = SceneHelper.getMode(storeState);
    return !isAdmin && mode === modes.calendar;
  });

  const showSortPages = useSelector((storeState) => {
    const isAdmin = SceneHelper.getAdmin(storeState);
    const mode = SceneHelper.getMode(storeState);
    return !isAdmin && mode === modes.photoBook;
  });

  const onClickAddText = useCallback(() => {
    store.dispatch(addText());
  }, []);
  const onClickAddDropZone = () => {
    store.dispatch(addDropZone());
  };
  const onClickSortPages = () => {
    store.dispatch(updateViewAllPages({ viewAllPages: true, sortPages: true }));
  };

  const [selectClipart, setSelectClipart] = useState(false);
  const [selectBackground, setSelectBackground] = useState(!!currentBackgroundImageId);
  if (selectClipart) {
    return (
      <Clipart
        onClickBackButton={() => {
          setSelectClipart(false);
        }}
      />
    );
  }
  if (selectBackground) {
    return (
      <Backgrounds
        onClickBackButton={() => {
          setSelectBackground(false);
        }}
      />
    );
  }

  const buttons = [
    {
      onClick: () => {
        setSelectClipart(true);
      },
      icon: faCut,
      label: 'Add Clipart',
    },
    {
      onClick: onClickAddText,
      icon: faTextSize,
      label: 'Add Text',
    },
    {
      onClick: onClickAddDropZone,
      icon: faImage,
      label: 'Add Image',
    },
    showSortPages
      ? {
          onClick: onClickSortPages,
          icon: faSort,
          label: 'Sort pages',
        }
      : null,
    showBackgroundPicker
      ? {
          onClick: () => setSelectBackground(true),
          icon: faFillDrip,
          label: 'Colour Theme',
        }
      : null,
    showCalendarSettings
      ? {
          label: 'Calendar settings',
          onClick: handleChangeStartDate,
          icon: faCalendarEdit,
        }
      : null,
  ].filter(Boolean);
  return (
    <div className={c.container}>
      <Grid size="50%">
        {buttons.map((p) => (
          <Grid.Item key={p.label} className={c.item}>
            <ButtonItem {...p} />
          </Grid.Item>
        ))}
      </Grid>
    </div>
  );
};

export default Customize;
