import React from 'react';
import {
  useAdmin,
  useDimensions,
  useEditedShapeOnCurrentPage,
  useIsEditArea,
  useLayoutArea,
  useMode,
  useToggleIsEditArea,
} from 'hooks';
import { min, max } from 'lodash';
import useTextIsEditing from 'components/editors/NewEditor/hooks/useTextEditing';
import SceneHelper from 'utils/SceneHelper';
import { modes } from 'constants/index';
import Transformer from './Transformers/Transformer';
import TextTransformer from './Transformers/TextTransformer';

const TransformerContainer = () => {
  const object = useEditedShapeOnCurrentPage();
  const admin = useAdmin();
  const mode = useMode();
  const dimensions = useDimensions();
  const toggleEditArea = useToggleIsEditArea();
  const [textEditing, toggleTextEditing] = useTextIsEditing();
  const isEditLayoutArea = useIsEditArea();
  const layoutArea = useLayoutArea();
  if (isEditLayoutArea) {
    return (
      <Transformer
        key="layoutArea"
        object={{ ...layoutArea, id: 'layoutArea' }}
        fill="#40c3d255"
        draggable
        editable
        onClick={toggleEditArea}
        enabledAnchors={[
          'top-left',
          'top-center',
          'top-right',
          'center-left',
          'center-right',
          'bottom-left',
          'bottom-center',
          'bottom-right',
        ]}
      />
    );
  }
  if (
    SceneHelper.isLayout(object) ||
    (SceneHelper.isDropZone(object) && !object.isDraggable) ||
    (SceneHelper.isDropZone(object) && mode === modes.photoPrint)
  ) {
    const newX = max([object.x, dimensions.frame]);
    const newY = max([object.y, dimensions.frame]);
    const newWidth = min([dimensions.width + dimensions.frame - newX, object.width - (newX - object.x)]);
    const newHeight = min([dimensions.height + dimensions.frame - newY, object.height - (newY - object.y)]);
    return (
      <Transformer
        key={object.id}
        object={{
          ...object,
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
        }}
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />
    );
  }
  if (SceneHelper.isCalendar(object)) {
    return (
      <Transformer
        key={object.id}
        object={object}
        draggable={!!admin}
        editable={!!admin}
        rotateEnabled={!!admin}
        enabledAnchors={[
          'top-left',
          'top-center',
          'top-right',
          'center-left',
          'center-right',
          'bottom-left',
          'bottom-center',
          'bottom-right',
        ]}
      />
    );
  }
  if (SceneHelper.isDropZone(object)) {
    return (
      <Transformer
        key={object.id}
        object={object}
        draggable
        editable
        rotateEnabled
        enabledAnchors={[
          'top-left',
          'top-center',
          'top-right',
          'center-left',
          'center-right',
          'bottom-left',
          'bottom-center',
          'bottom-right',
        ]}
      />
    );
  }
  if (SceneHelper.isClipart(object)) {
    return (
      <Transformer
        key={object.id}
        object={object}
        draggable
        editable
        rotateEnabled
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />
    );
  }
  if (SceneHelper.isText(object)) {
    return (
      <TextTransformer object={object} toggleTextEditing={toggleTextEditing} textEditing={textEditing} draggable />
    );
  }
  return null;
};

export default TransformerContainer;
