import React from 'react';
import classNames from 'classnames';
import CB from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import c from './Checkbox.module.scss';

export default function Checkbox({ className, name, checked, onChange, label }) {
  return (
    <label className={classNames(c.container, className)}>
      <CB name={name} className={classNames(c.checkbox)} onChange={onChange} checked={checked} />{' '}
      <span className={c.label}>{label}</span>
    </label>
  );
}
