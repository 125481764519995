import React, { useMemo, memo } from 'react';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import useIsMobile from 'hooks/useIsMobile';

const ArrowMemo = memo(function Arrow({ vertical, width: lineWith, text, color }) {
  const isMobile = useIsMobile();
  let width = lineWith;
  let height = width / 10;
  const markerWidth = height / 2.5;
  if (vertical) {
    [width, height] = [height, width];
  }
  const marginRight = useMemo(() => (!isMobile && vertical ? '10px' : ''), [isMobile, vertical]);
  const marginTop = useMemo(() => {
    if (!vertical) {
      return isMobile ? '5px' : '10px';
    }
    return '';
  }, [isMobile, vertical]);
  return (
    <div
      style={
        vertical
          ? { display: 'flex', alignItems: 'center' }
          : { width, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        style={{ overflow: 'visible', marginTop, marginRight }}
      >
        <defs>
          <marker
            id="arrowhead"
            markerWidth={markerWidth}
            markerHeight={markerWidth}
            refX={markerWidth}
            refY={markerWidth / 2}
            orient="auto"
          >
            <line x1={0} y1="0" x2={markerWidth} y2={markerWidth / 2} stroke={color} strokeWidth="1" />
            <line x1={0} y1={markerWidth} x2={markerWidth} y2={markerWidth / 2} stroke={color} strokeWidth="1" />
          </marker>
          <marker
            id="arrowstart"
            markerWidth={markerWidth}
            markerHeight={markerWidth}
            refX="0"
            refY={markerWidth / 2}
            orient="auto"
          >
            <line x1={markerWidth} y1="0" x2="0" y2={markerWidth / 2} stroke={color} strokeWidth="1" />
            <line x1={markerWidth} y1={markerWidth} x2="0" y2={markerWidth / 2} stroke={color} strokeWidth="1" />
          </marker>
        </defs>
        {!isMobile ? (
          <line
            x1={vertical ? width * (3 / 4) : 0}
            y1={vertical ? 0 : height / 4}
            x2={vertical ? width * (3 / 4) : width}
            y2={vertical ? height : height / 4}
            stroke={color}
            strokeWidth="1"
            markerStart="url(#arrowstart)"
            markerEnd="url(#arrowhead)"
          />
        ) : null}
        <text
          x="50%"
          y="50%"
          dominantBaseline={vertical ? 'middle' : 'hanging'}
          textAnchor={vertical ? 'end' : 'middle'}
          fontSize={isMobile ? '0.6em' : '0.9em'}
          fill={color}
        >
          {text}
        </text>
      </svg>
    </div>
  );
}, fastDeepEqual);

export default ArrowMemo;
