import React, { useMemo } from 'react';
import { find } from 'lodash';
import Select from 'react-select';
import { dateFormats } from 'constants/index';
import useIsMobile from 'hooks/useIsMobile';

const SelectFormatDate = ({ value, onChange }) => {
  const isMobile = useIsMobile();
  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);
  return (
    <Select
      defaultValue={find(dateFormats, { value })}
      menuPlacement={menuPlacement}
      value={find(dateFormats, { value })}
      onChange={({ value }) => onChange(value)}
      options={dateFormats}
      classNamePrefix="day-format-select"
    />
  );
};

export default SelectFormatDate;
