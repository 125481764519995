import React from 'react';
import { faAlignLeft, faAlignRight } from '@fortawesome/pro-regular-svg-icons';
import { faAlignCenter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomAlignLabel = (props) => {
  const { optionValue } = props;
  let icon = faAlignLeft;
  if (optionValue === 'center') icon = faAlignCenter;
  if (optionValue === 'right') icon = faAlignRight;
  return <FontAwesomeIcon.Memo icon={icon} style={{ height: '1.22em', width: '1.22em', marginRight: '0px' }} />;
};

export default CustomAlignLabel;
