import PixaPrints from 'api';
import { modalTypes } from 'constants/index';
import store from 'store';
import { updatePageId, updateViewAllPages } from 'actions/scene';
import { openModal } from './utils';
import SceneHelper from './SceneHelper';

export const handleChangeStartPhotoBook = async ({ dismissable = true } = {}) => {
  const storeState = store.getState();
  const currentPageId = SceneHelper.currentPageId(storeState);
  const onConfirm = async () => {
    openModal({ type: 'loading', modalProps: { success: true, message: 'Successfully saved' } });
    await PixaPrints.saveState({ autosave: true });
    await store.dispatch(updatePageId({ id: currentPageId }));
  };
  await store.dispatch(updateViewAllPages(true));
  openModal({
    type: modalTypes.configurationPhotoBook,
    dismissable,
    modalProps: { onConfirm },
  });
};
