import React, { useMemo } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import { find } from 'lodash';
import Select from 'react-select';
import optionsFontFamily from 'constants/fonts';
import c from './FontFamilySelect.module.scss';

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps} className={c.option}>
      <img
        height="30px"
        alt={data.value}
        src={`https://res.cloudinary.com/dt6b7ywnt/image/upload/f_auto/assets/editors/font-images/${data.value}`}
      />
    </div>
  );
};

export const FontFamilySelect = ({ onChange: _onChange, initialValue }) => {
  const isMobile = useIsMobile();

  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);

  return (
    <Select
      defaultValue={find(optionsFontFamily, { label: initialValue })}
      menuPlacement={menuPlacement}
      components={{ Option: CustomOption }}
      onChange={(select) => _onChange(select.label)}
      options={optionsFontFamily}
      classNamePrefix="font-family-input"
      isSearchable={false}
    />
  );
};

export default FontFamilySelect;
