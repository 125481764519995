import React from 'react';
import { useRemoveLayout, useAdmin } from 'hooks';
import Button from 'components/common/Button';

const ClearLayouts = ({ className }) => {
  const toggleEditArea = useRemoveLayout();
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <Button type="button" size="lg" textSize="md" rounded color="azure" className={className} onClick={toggleEditArea}>
      Remove layout from page
    </Button>
  );
};

export default ClearLayouts;
