import React from 'react';
import { faUndo, faExpandArrowsAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDefaultPropsTransformer } from './constants';

const Circle = ({ id, cx, cy, rotate, type = '', hide = false }) => {
  const { strokeWidth, radius, stroke, hitRadius } = useDefaultPropsTransformer();
  return (
    <g visibility={hide ? 'hidden' : ''}>
      {rotate ? (
        <defs>
          <pattern id={`rotate-icon-${id}`} height="100%" width="100%" viewBox="0 0 100 100">
            <rect width="100" height="100" fill="white" />
            <svg height="50" width="50" x="25" y="25" fill="white">
              <FontAwesomeIcon.Memo icon={faUndo} color={stroke} />
            </svg>
          </pattern>
        </defs>
      ) : null}
      {type === 'drag' ? (
        <defs>
          <pattern id={`rotate-icon-${id}`} height="100%" width="100%" viewBox="0 0 100 100">
            <rect width="100" height="100" fill="white" />
            <g transform="rotate(45, 50, 50)">
              <svg height="50" width="50" transform="rotate(45)" x="25" y="25" fill="white">
                <FontAwesomeIcon.Memo icon={faExpandArrowsAlt} color={stroke} />
              </svg>
            </g>
          </pattern>
        </defs>
      ) : null}
      <circle
        className={id}
        cx={cx}
        cy={cy}
        r={radius * (rotate || type ? 2 : 1)}
        stroke={stroke}
        strokeWidth={`${strokeWidth}em`}
        fill={rotate || type ? `url(#rotate-icon-${id})` : 'white'}
      />
      <circle id={id} className={id} cx={cx} cy={cy} r={hitRadius * (rotate || type ? 2 : 1)} fill="rgba(0, 0, 0, 0)" />
    </g>
  );
};

export default Circle;
