import React from 'react';
import Button from 'components/common/Button';

/**
 * @typedef {Object} ConfirmModalProps
 * @prop {function} onConfirm
 * @prop {function} onClose
 * @prop {string} header
 * @prop {string} confirmText
 * @prop {string} cancelText
 * @prop {string} text
 * @prop {boolean} hideCancelButton
/**
 * A custom select component.
 *
 * @param {ConfirmModalProps} props
 *
 * @returns {JSX.Element}
 */
const ConfirmModal = ({
  onConfirm,
  onClose,
  header = '',
  confirmText = 'yes',
  cancelText = 'no',
  text = '',
  hideCancelButton = false,
}) => (
  <>
    <div className="modal-header">
      <div className="modal-header-text">{header}</div>
    </div>
    <div className="modal-body">
      <div className="modal-text">{text}</div>
    </div>
    <div className="action-buttons-vertical">
      <Button color="azure" rounded size="lg" onClick={onConfirm}>
        {confirmText}
      </Button>
      {!hideCancelButton ? (
        <Button outline rounded size="lg" onClick={onClose}>
          {cancelText}
        </Button>
      ) : null}
    </div>
  </>
);

export default ConfirmModal;
