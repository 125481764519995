import { useCallback } from 'react';
import ReactGA from 'react-ga';
import { crop } from 'constants/GA';
import { useSelector, shallowEqual } from 'react-redux';
import useUpdateConfig from 'hooks/useUpdateConfig';

const useIsCrop = () => {
  const updateConfig = useUpdateConfig();
  const isCrop = useSelector(({ scene }) => scene.config.isCrop, shallowEqual);
  const setIsCrop = useCallback(
    (newValue) => {
      updateConfig({ isCrop: newValue });
      if (newValue) {
        ReactGA.event(crop);
      }
    },
    [updateConfig],
  );
  const toggleIsCrop = useCallback(() => {
    setIsCrop(!isCrop);
  }, [isCrop, setIsCrop]);
  return [isCrop, toggleIsCrop, setIsCrop];
};

export default useIsCrop;
