import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import SceneHelper from 'utils/SceneHelper';
import { usePageConfigContext } from 'context/pageConfig';
import { modes } from 'constants/index';

export const useDimensions = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getDimensionsSync(state, pageConfig?.pageId), fastDeepEqual);
};

export const useSizeSVG = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getSizeSVG(state, pageConfig?.pageId), fastDeepEqual);
};

export const useClippingMask = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getClippingMask(state, pageConfig?.pageId), fastDeepEqual);
};

export const useOverlay = () => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (state) => [
      SceneHelper.getOverlayUrl(state, pageConfig?.pageId),
      SceneHelper.getScaleOverlay(state, pageConfig?.pageId),
    ],
    fastDeepEqual,
  );
};

export const useOverlayUrl = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getOverlayUrl(state, pageConfig?.pageId), fastDeepEqual);
};

export const useClippingMaskUrl = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getClippingMaskUrlByPageId(state, pageConfig?.pageId), fastDeepEqual);
};

export const useScaleOverlay = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getScaleOverlay(state, pageConfig?.pageId), fastDeepEqual);
};

/**
 * @returns {string}
 */
export const useUnderlay = () => useSelector((state) => SceneHelper.getUnderlay(state), fastDeepEqual);

export const useScaleUnderlay = () => useSelector((state) => SceneHelper.getScaleUnderlay(state), fastDeepEqual);

export const useIsRotateMask = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((state) => SceneHelper.getIsRotateMask(state, pageConfig?.pageId), fastDeepEqual);
};

export const useQualityEditedShape = () => useSelector((state) => SceneHelper.qualityEditedShape(state), fastDeepEqual);

export const useEditedShape = () => useSelector((state) => cloneDeep(SceneHelper.editedShape(state)), fastDeepEqual);

export const useEditedShapeOnCurrentPage = () => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (state) => cloneDeep(SceneHelper.editedShapeOnCurrentPage(state, pageConfig?.pageId)),
    fastDeepEqual,
  );
};

export const useShapeById = (id) => useSelector((state) => cloneDeep(SceneHelper.shapeById(state, id)), fastDeepEqual);

export const useEditorSizePx = (pageId) =>
  useSelector((storeState) => SceneHelper.getEditorSizePx(storeState, pageId), fastDeepEqual);

export const useAllFonts = () => useSelector((storeState) => SceneHelper.getAllFonts(storeState), fastDeepEqual);

export const useIsEditing = () => useSelector((storeState) => SceneHelper.isEditing(storeState), fastDeepEqual);

export const useSelectedShapeId = () =>
  useSelector((storeState) => SceneHelper.getSelectedShapeId(storeState), fastDeepEqual);

export const useQualityImageByShapeId = (id) =>
  useSelector((storeState) => SceneHelper.getQualityImageByShapeId(storeState, id), fastDeepEqual);

export const useCurrentPageShapeIds = () =>
  useSelector((storeState) => SceneHelper.getCurrentPageShapeIds(storeState), fastDeepEqual);

export const useCurrentPageLabel = () =>
  useSelector((storeState) => SceneHelper.getCurrentPageLabel(storeState), fastDeepEqual);

export const usePageLabel = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getPageLabelByPageId(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const usePageLabelByPageId = (pageId) =>
  useSelector((storeState) => SceneHelper.getPageLabelByPageId(storeState, pageId), fastDeepEqual);

export const useNextPage = () => useSelector((storeState) => SceneHelper.getNextPage(storeState), fastDeepEqual);

export const usePreviousPage = () =>
  useSelector((storeState) => SceneHelper.getPreviousPage(storeState), fastDeepEqual);

export const useShapeIdsByPageId = (types) => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (storeState) => SceneHelper.getShapeIdsByPageId(storeState, pageConfig?.pageId, types),
    fastDeepEqual,
  );
};

export const usePageShapeById = (id) => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getPageShapeById(storeState, id, pageConfig?.pageId), fastDeepEqual);
};

export const useSafeMargin = () => {
  return useSelector((storeState) => SceneHelper.getSafeMargin(storeState), fastDeepEqual);
};

export const usePageIds = () =>
  useSelector((storeState) => {
    const allPageIds = SceneHelper.getAllPageIds(storeState);
    const viewAllPages = SceneHelper.getViewAllPages(storeState);
    const isSortPages = SceneHelper.getIsSortPages(storeState);
    const mode = SceneHelper.getMode(storeState);
    if (mode === modes.photoBook && viewAllPages && !isSortPages) {
      const newPages = [];
      let tempPages = [''];
      // eslint-disable-next-line no-restricted-syntax
      allPageIds.forEach((page, index) => {
        if (index === 1 && tempPages.length === 0) {
          tempPages.push('');
        }
        if (tempPages.length < 2) {
          tempPages.push(page);
        }
        if (tempPages.length === 2) {
          newPages.push(tempPages);
          tempPages = [];
        }
      });
      if (tempPages.length) {
        if (tempPages.length === 1) {
          tempPages.push('');
        }
        newPages.push(tempPages);
      }
      return newPages;
    }
    return allPageIds;
  }, fastDeepEqual);

export const useQuantityByPageId = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getQuantityByPageId(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useViewAllPages = () =>
  useSelector((storeState) => SceneHelper.getViewAllPages(storeState), fastDeepEqual);

export const useIsSortPages = () => useSelector((storeState) => SceneHelper.getIsSortPages(storeState), fastDeepEqual);

export const useShowSwitchView3d = () =>
  useSelector((storeState) => SceneHelper.getShowSwitchView3d(storeState), fastDeepEqual);

export const useSrcByShapeId = (id, originalSrc) =>
  useSelector((storeState) => SceneHelper.getSrcByShapeId(storeState, id, originalSrc), fastDeepEqual);

export const usePhotoByShapeId = (id) =>
  useSelector((storeState) => SceneHelper.getPhotoByShapeId(storeState, id), fastDeepEqual);
export const useCurrentPageId = () => useSelector((storeState) => SceneHelper.currentPageId(storeState), fastDeepEqual);

export const useIsEditArea = () => useSelector((storeState) => SceneHelper.getIsEditArea(storeState), fastDeepEqual);

export const useLayoutArea = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getLayoutArea(storeState, pageConfig?.pageId, true), fastDeepEqual);
};

export const useEditedText = () =>
  useSelector((storeState) => SceneHelper.getEditedTextShape(storeState), fastDeepEqual);

export const useEditedTextInInput = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getEditedTextInInput(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useCenterEditedText = () =>
  useSelector((storeState) => SceneHelper.getCenterEditedText(storeState), fastDeepEqual);

export const useLayoutId = () => useSelector((storeState) => SceneHelper.getLayoutId(storeState), fastDeepEqual);

export const useIsDefaultLayout = () =>
  useSelector((storeState) => SceneHelper.getIsDefaultLayout(storeState), fastDeepEqual);

export const useLayoutGutterType = () =>
  useSelector((storeState) => SceneHelper.getLayoutGutterType(storeState), fastDeepEqual);

export const useCoverOption = () => useSelector((storeState) => SceneHelper.getCoverOption(storeState), fastDeepEqual);

export const useBackgroundColor = () => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (storeState) => SceneHelper.getBackgroundColorByPageId(storeState, pageConfig?.pageId),
    fastDeepEqual,
  );
};

export const useBackgroundOption = () =>
  useSelector((storeState) => SceneHelper.getBackgroundOption(storeState), fastDeepEqual);

export const useIsShowOrientationSwitch = () =>
  useSelector((storeState) => SceneHelper.isShowOrientationSwitch(storeState), fastDeepEqual);

export const useIsDisableAutosave = () =>
  useSelector((storeState) => SceneHelper.isDisableAutosave(storeState), fastDeepEqual);

export const useMode = () => useSelector((storeState) => SceneHelper.getMode(storeState), fastDeepEqual);

export const useCountPagesInOneLetter = () =>
  useSelector((storeState) => SceneHelper.getCountPagesInOneLetter(storeState), fastDeepEqual);

export const useEdgeWrap = () => useSelector((storeState) => SceneHelper.getEdgeWrap(storeState), fastDeepEqual);

export const useAdmin = () => useSelector((storeState) => SceneHelper.getAdmin(storeState), fastDeepEqual);

export const usePageDesignId = () =>
  useSelector((storeState) => SceneHelper.getPageDesignId(storeState), fastDeepEqual);

export const useCountLowQualityImage = () =>
  useSelector((storeState) => SceneHelper.countLowQualityImage(storeState), fastDeepEqual);

export const useCountEmptyImages = () =>
  useSelector((storeState) => SceneHelper.countEmptyImages(storeState), fastDeepEqual);

export const useCountNotEmptyImages = () =>
  useSelector((storeState) => SceneHelper.countNotEmptyImages(storeState), fastDeepEqual);

export const useCountPages = () => useSelector((storeState) => SceneHelper.getCountPages(storeState), fastDeepEqual);

export const usePrintLimitMax = () =>
  useSelector((storeState) => SceneHelper.getPrintLimitMax(storeState), fastDeepEqual);

export const useCountPageWithNoImages = () =>
  useSelector((storeState) => SceneHelper.countPageWithNoImages(storeState), fastDeepEqual);

export const useShowArrow = () => useSelector((storeState) => SceneHelper.isShowArrow(storeState), fastDeepEqual);

export const useActivePhotoId = () =>
  useSelector((storeState) => SceneHelper.getAllImageIds(storeState), fastDeepEqual);

export const useCurrentBackgroundImageId = () =>
  useSelector((storeState) => SceneHelper.getCurrentBackgroundImageId(storeState), fastDeepEqual);

export const useIsOneImageLoaded = () =>
  useSelector((storeState) => SceneHelper.getIsOneImageLoaded(storeState), fastDeepEqual);

export const useIsOneImageLoading = () =>
  useSelector((storeState) => SceneHelper.getIsOneImageLoading(storeState), fastDeepEqual);

export const useCountPagesWithoutImages = () =>
  useSelector((storeState) => SceneHelper.getCountPagesWithoutImages(storeState), fastDeepEqual);

export const useActiveEventListByDate = (date) =>
  useSelector((storeState) => SceneHelper.getActiveEventListByDate(storeState, date), fastDeepEqual);

export const useEventsByMonth = () =>
  useSelector((storeState) => SceneHelper.getEventsByMonth(storeState), fastDeepEqual);

export const useEventById = (id) =>
  useSelector((storeState) => SceneHelper.getEventsById(storeState, id), fastDeepEqual);

export const useCurrentPageDate = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getDateByPageId(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const usePlaceholdersObject = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getPlaceholdersObject(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useListYears = () => {
  return useSelector((storeState) => SceneHelper.getListYears(storeState), fastDeepEqual);
};

export const useCurrentEventList = () => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (storeState) => SceneHelper.getActiveEventListByPageId(storeState, pageConfig?.pageId),
    fastDeepEqual,
  );
};

export const useAxisOptions = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getAxisOptions(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useCurrentPageWeekStarting = () => {
  const pageConfig = usePageConfigContext();
  return useSelector(
    (storeState) => SceneHelper.getWeekStartingByPageId(storeState, pageConfig?.pageId),
    fastDeepEqual,
  );
};

export const useCurrentPageFonts = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getFontsByPageId(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useDisableLayouts = () => {
  const pageConfig = usePageConfigContext();
  return useSelector((storeState) => SceneHelper.getDisableLayouts(storeState, pageConfig?.pageId), fastDeepEqual);
};

export const useHideEventsInGrid = () => {
  return useSelector((storeState) => SceneHelper.getHideEventsInGrid(storeState), fastDeepEqual);
};

export const useDisableEventsTab = () => {
  return useSelector((storeState) => SceneHelper.getDisableEventsTab(storeState), fastDeepEqual);
};

export const useLoadingPage = (pageId) => {
  return useSelector((storeState) => SceneHelper.getLoadingByPageId(storeState, pageId), fastDeepEqual);
};

/**
 * @returns {boolean}
 */
export const useIsLayoutChanged = () => {
  return useSelector((storeState) => SceneHelper.getIsLayoutChanged(storeState), fastDeepEqual);
};

/**
 * a hook that checks if we can add / edit an event
 * @param {Object} param
 * @param {Date} param.date - event date
 * @param {string} param.title - event title
 * @param {string} [param.id] - event id if edit
 * @returns {[boolean, [string]]} - the first argument is whether you can add an event. the second argument is a list of errors
 */
export const useCanAddEvent = ({ date, title, id }) => {
  return useSelector((storeState) => SceneHelper.canAddEvent(storeState, { date, title, id }), fastDeepEqual);
};
