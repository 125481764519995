import React from 'react';
import Loading from 'assets/Loading';
import Lottie from 'react-lottie';
import Button from 'components/common/Button';
import { success as successAnimationData, error as errorAnimationData } from '../LottieAnimations';

const successAnimationOptions = {
  loop: false,
  autoplay: true,
  animationData: successAnimationData,
  // rendererSettings: {
  //   viewBoxSize: '345 345 110 110', // 348 364 101 71
  // },
};

const errorAnimationOptions = {
  loop: false,
  autoplay: true,
  animationData: errorAnimationData,
  // rendererSettings: {
  //   viewBoxSize: '345 345 110 110', // 348 364 101 71
  // },
};

const checkIsLoading = (success) => typeof success !== 'boolean';

const LoadingModal = ({ onClose, success, message, closeButtonText = 'Close', cancellable }) => {
  const isClosing = React.useRef(false);
  const isLoading = checkIsLoading(success);

  let t1 = 'Hang in there';
  let t2 = 'Uploading your design...';

  if (!isLoading) {
    t1 = message;
    t2 = '';
  }

  React.useEffect(() => {
    if (!isClosing.current && !isLoading && success) {
      isClosing.current = true;
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  }, [onClose, isLoading, success]);

  const lottieSize = 100;

  return (
    <div className="modal processing">
      <div className="modal-header">{t1}</div>
      <div className="modal-text">{t2}</div>
      <div>
        {isLoading ? (
          <div className="loading-container">
            <Loading />
          </div>
        ) : null}
        {success === true ? (
          <Lottie
            options={successAnimationOptions}
            height={lottieSize}
            width={lottieSize}
            isStopped={false}
            isPaused={false}
          />
        ) : null}
        {success === false ? (
          <>
            <Lottie
              options={errorAnimationOptions}
              height={lottieSize}
              width={lottieSize}
              isStopped={false}
              isPaused={false}
            />
            {cancellable ? (
              <Button
                outline
                color="azure"
                rounded
                size="lg"
                onClick={onClose}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                {closeButtonText}
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LoadingModal;
