import React from 'react';
// import ReactDatePicker from 'react-datepicker';
import { format, isValid, getDaysInMonth, setDate, setMonth } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarDay } from '@fortawesome/pro-light-svg-icons';
// import 'react-datepicker/dist/react-datepicker.css';
import c from './DayMonthPicker.module.scss';
import Select from './Select';

const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const monthOptions = months.map((month) => {
  return {
    label: format(new Date(2011, 0, 1).setMonth(month), 'MMMM'), // 2021, 0, 1 - (this is a random date) fix when now is the 31st of the month
    value: month,
  };
});

export default function DayMonthPicker({ value, onChange }) {
  const date = isValid(new Date(value)) ? new Date(value) : new Date();

  const month = date.getMonth();
  const day = date.getDate();

  const dayOptions = [...new Array(getDaysInMonth(date))].map((_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  return (
    <div className={c.container}>
      <Select
        options={monthOptions}
        value={month}
        onChange={(v) => {
          onChange(setMonth(date, parseInt(v, 10)));
        }}
        icon={<FontAwesomeIcon.Memo icon={faCalendar} />}
      />
      <Select
        icon={<FontAwesomeIcon.Memo icon={faCalendarDay} />}
        options={dayOptions}
        value={day}
        onChange={(v) => {
          onChange(setDate(date, parseInt(v, 10)));
        }}
      />
    </div>
  );
}
