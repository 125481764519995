import React from 'react';
import c from './TestProducts.module.scss';

const testModes = [
  {
    modeName: 'Photo-book',
    products: [
      {
        name: 'Test',
        link: '?category=photo-book',
        prodLink: '',
      },
      {
        name: '20cm Square Photo book',
        link: '?mode=photo-book&category=110&product=520',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-books/20cm-square',
      },
    ],
  },
  {
    modeName: 'Calendar',
    products: [
      {
        name: 'Personalised desk calendar',
        link: '?mode=calendar&category=114&product=531&design=47',
        prodLink: 'https://www.pixaprints.co.uk/buy/personalised-calendars/desk-calendar',
      },
    ],
  },
  {
    modeName: 'Canvas',
    products: [
      {
        name: 'Classic Canvas Prints',
        link: '?mode=canvas&category=7&product=614',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/cheap-canvas-prints',
      },
      {
        name: 'Framed Canvas Prints',
        link: '?mode=canvas&category=178&product=628&productoption=928',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/framed-canvas-prints',
      },
      {
        name: 'Collage Canvas Prints',
        link: '?mode=canvas&category=97&product=491',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/collage-canvas-prints',
      },
      {
        name: 'Panoramic Canvas Prints',
        link: '?mode=canvas&category=166&product=618',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/panoramic-canvas-prints',
      },
      {
        name: 'Square Canvas Prints',
        link: '?mode=canvas&category=177&product=614',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/square-canvas-prints',
      },
      {
        name: 'Premium Canvas Prints',
        link: '?mode=canvas&category=8&product=25',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/premium-canvas-prints',
      },
      {
        name: 'Large Canvas Prints',
        link: '?mode=canvas&category=181&product=7',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/large-canvas-printing',
      },
      {
        name: 'Small Canvas Prints',
        link: '?mode=canvas&category=182&product=614',
        prodLink: 'https://www.pixaprints.co.uk/buy/canvas-prints/small-canvas-prints',
      },
    ],
  },
  {
    modeName: 'Poster',
    products: [
      {
        name: 'Personalised Photo Jigsaw Puzzle',
        link: '?mode=poster&category=46&product=616',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-jigsaw',
      },
      {
        name: 'Personalised Photo Block',
        link: '?mode=poster&category=118&product=533',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-photo-block',
      },
      {
        name: 'Personalised Mouse Mat',
        link: '?mode=poster&category=11&product=3',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-mouse-mat',
      },
      {
        name: 'Personalised Photo Keyrings',
        link: '?mode=poster&category=22&product=84',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-keyrings',
      },
      {
        name: 'Personalised Coasters',
        link: '?mode=poster&category=16&product=62',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-coasters',
      },
      {
        name: 'Personalised Rectangle Photo Placemat',
        link: '?mode=poster&category=169&product=61',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/personalised-placemats/rectangle-photo-placemats',
      },
    ],
  },
  {
    modeName: 'Mask',
    products: [
      {
        name: 'Photo slate',
        link: '?mode=mask&category=160&product=615',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/photo-slate',
      },
    ],
  },
  {
    modeName: 'PhotoPrint',
    products: [
      {
        name: 'Standard Photo Prints',
        link: '?mode=photo-print&category=157&product=598&productoption=936',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-prints/standard-photo-prints',
      },
      {
        name: 'Retro photo prints',
        link: '?mode=photo-print&category=156&product=597',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-prints/retro-photo-prints',
      },
      {
        name: 'Frameless Photo Tiles',
        link: '?mode=photo-print&category=175&product=626',
        prodLink: 'https://www.pixaprints.co.uk/buy/photo-gifts/photo-tiles/frameless',
      },
    ],
  },
];

const TestProducts = () => {
  return (
    <div>
      {testModes.map(({ modeName, products }) => (
        <div className={c.modeContainer}>
          <h3>{modeName}</h3>
          <div>
            {products.map(({ name, link, prodLink }) => (
              <div className={c.productContainer}>
                <div style={{ width: '40%' }}>{name}</div>{' '}
                <div style={{ width: '30%' }}>
                  <a href={link}>Link</a>
                </div>
                <div style={{ width: '30%' }}>
                  <a href={prodLink}>Prod link</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TestProducts;
