import ImageOverlayUnderlay from 'components/editors/NewEditor/components/ImageOverlay';
import React from 'react';
import { modes } from 'constants/index';
import { useClippingMaskUrl, useIsRotateMask, useMode, useOverlay } from 'hooks';
import { usePageConfigContext } from 'context/pageConfig';

/**
 * Renders a mask crop photo component.
 *
 * @param {Object} props
 * @param {Object} props.object
 * @param {boolean} props.maskOverflowVisible - Whether or not the mask should overflow the bounds of the svg.
 * @param {number} props.editorWidth
 * @param {number} props.editorHeight
 * @param {number} props.viewWidth
 * @param {number} props.viewHeight
 * @returns {JSX.Element} - A JSX element representing the rendered mask crop photo component.
 */
const MaskCropPhoto = ({ object, maskOverflowVisible, editorWidth, editorHeight, viewWidth, viewHeight }) => {
  const pageConfig = usePageConfigContext();
  const mode = useMode();
  const ClippingMask = useClippingMaskUrl();
  const [overlayUrl, scaleOverlay] = useOverlay();
  const isRotateMask = useIsRotateMask();
  return (
    <g>
      {ClippingMask ? (
        <defs>
          <mask id="mask-clipping-mask">
            <image
              xlinkHref={ClippingMask}
              x={isRotateMask ? -object.y : -object.x}
              y={isRotateMask ? object.x : -object.y}
              width={isRotateMask ? editorHeight : editorWidth}
              height={isRotateMask ? editorWidth : editorHeight}
              transform={isRotateMask ? `translate(${editorWidth}, 0) rotate(${90}, ${0}, ${0})` : null}
            />
          </mask>
          <mask id={`mask-clip-path${pageConfig?.pageId}`}>
            <rect width="100%" height="100%" fill="white" />
            <rect
              x={-object.x}
              y={-object.y}
              width={editorWidth}
              height={editorHeight}
              transform={`rotate(${-object.rotation || 0})`}
              fill="black"
              mask="url(#mask-clipping-mask)"
            />
          </mask>
          {maskOverflowVisible ? (
            <mask id="mask-overlay">
              <rect width="100%" height="100%" fill="white" />
            </mask>
          ) : null}
        </defs>
      ) : null}
      <path
        d={`M${-(viewWidth - object.width) / 2} ${
          -(viewHeight - object.height) / 2
        } h${viewWidth} v${viewHeight} h${-viewWidth} z
              M0 0 h${object.width} v${object.height} h${-object.width} z`}
        fill="#fff5"
        style={{ pointerEvents: 'none' }}
        fillRule="evenodd"
      />
      {mode === modes.mask ? (
        <>
          <rect
            x="0"
            y="0"
            width={object.width}
            height={object.height}
            fill="#fff5"
            mask={`url(#mask-clip-path${pageConfig?.pageId})`}
            style={{ pointerEvents: 'none' }}
          />
          <ImageOverlayUnderlay
            url={overlayUrl}
            scaleOverlay={scaleOverlay}
            isRotateMask={isRotateMask}
            shape={object}
            mask={maskOverflowVisible ? 'url(#mask-overlay)' : null}
            style={{ opacity: 0.5 }}
          />
        </>
      ) : null}
    </g>
  );
};

export default MaskCropPhoto;
