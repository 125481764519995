import React from 'react';
import { useToggleIsEditArea, useIsEditArea, useAdmin } from 'hooks';
import Switch from 'components/common/Switch';

const SwitchEditLayout = ({ className }) => {
  const isEditArea = useIsEditArea();
  const toggleEditArea = useToggleIsEditArea();
  const admin = useAdmin();
  if (!admin) return null;
  return <Switch label="Edit layout area" checked={isEditArea} className={className} onChange={toggleEditArea} />;
};

export default SwitchEditLayout;
