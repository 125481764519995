import React from 'react';
import { useQualityImageByShapeId } from 'hooks';
import ImageWithDropZone from './ImageWithDropZone';

const DropZone = ({ object, onClick }) => {
  const quality = useQualityImageByShapeId(object?.id);
  return <ImageWithDropZone object={object} onClick={onClick} quality={quality} />;
};

export default DropZone;
