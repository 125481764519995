import React, { useMemo } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import Select from 'react-select';
import BringForward from '../BringForward';
import BringBackward from '../BringBackward';
import BringToFront from '../BringToFront';
import BringToBack from '../BringToBack';
import CustomOption from './components/CustomOption';
import SingleValue from './components/SingleValue';
import useOrderObjectsDropdownVisibility from './hooks/useOrderObjectsDropdownVisibility';
import './OrderObjects.scss';

const options = [
  {
    value: 'bringToFront',
    Component: BringToFront,
  },
  {
    value: 'BringToBack',
    Component: BringToBack,
  },
  {
    value: 'bringForward',
    Component: BringBackward,
  },
  {
    value: 'bringBackward',
    Component: BringForward,
  },
];

const OrderObjects = () => {
  const isMobile = useIsMobile();
  const isShowOrderObject = useOrderObjectsDropdownVisibility();
  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);

  if (!isShowOrderObject) return null;
  return (
    <Select
      classNamePrefix="order-shapes"
      defaultValue={options[0]}
      menuPlacement={menuPlacement}
      components={{ Option: CustomOption, SingleValue }}
      options={options}
      isSearchable={false}
    />
  );
};

export default OrderObjects;
