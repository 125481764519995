const isProduction = process.env.NODE_ENV === 'production';
const config = {
  isProduction,
  isDevelopment: !isProduction,
  showDevButtons: process.env.REACT_APP_SHOW_DEV_TOOLS
    ? JSON.parse(process.env.REACT_APP_SHOW_DEV_TOOLS)
    : !isProduction,
  apiUrl: process.env.REACT_APP_API_URL,
  withCredentials: process.env.REACT_APP_WITH_CREDENTIALS ?? true,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
  shouldOrderNewWindow: !isProduction,
  disableAutosave: !!process.env.REACT_APP_DISABLE_AUTOSAVE,
  isPreventLeave: process.env.REACT_APP_DISABLE_PREVENT_LEAVE, // turn off confirmation before leaving the page
};

if (!config.sentryDsn) {
  console.warn('No Sentry DSN configured. Please set REACT_APP_SENTRY_DSN variable');
}
if (!config.apiUrl) {
  console.warn('No apiUrl configured. Please set REACT_APP_API_URL variable');
}

if (!config.gaTrackingId) {
  console.warn('No ga tracking ID configured. Please set REACT_APP_GA_TRACKING_ID variable');
}

export default config;
