import React, { useMemo } from 'react';
import { useAddNewPageToPhotoBook, useCountPages, useCountPagesInOneLetter, useMode, useOnClickSortPage } from 'hooks';
import WarningText from 'components/common/WarningText';
import { modes } from 'constants/index';
import pluralize from 'pluralize';

const WarningCountPage = ({ className }) => {
  const numberOfPages = useCountPages();
  const mode = useMode();
  const countPagesInOneLetter = useCountPagesInOneLetter();
  const onClickSortPages = useOnClickSortPage();
  const addNewPageToPhotoBook = useAddNewPageToPhotoBook(
    countPagesInOneLetter - ((numberOfPages - 1) % countPagesInOneLetter),
  );
  const text = useMemo(
    () => (
      <div>
        Please note that the total number of pages must be a multiple of {countPagesInOneLetter}. You can either{' '}
        <div style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }} onClick={onClickSortPages}>
          Go to sorting mode
        </div>{' '}
        to delete 3 more pages or
        <div
          style={{ textDecoration: 'underline', cursor: 'pointer', display: 'inline' }}
          onClick={addNewPageToPhotoBook}
        >
          Add {pluralize('page', countPagesInOneLetter - ((numberOfPages - 1) % countPagesInOneLetter), true)}
        </div>{' '}
        to maintain the correct page count.
      </div>
    ),
    [addNewPageToPhotoBook, countPagesInOneLetter, numberOfPages, onClickSortPages],
  );

  if (!((numberOfPages - 1) % countPagesInOneLetter) || mode !== modes.photoBook) return null;
  return <WarningText text={text} className={className} type={numberOfPages ? 'danger' : 'warning'} />;
};

export default WarningCountPage;
