import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import useToolbar from 'hooks/useToolbar';
import { motion } from 'framer-motion';
import { useToolbarItems } from 'components/toolbar/Toolbar/ToolbarContainer';
import SceneHelper from 'utils/SceneHelper';
import { modes } from 'constants/index';
import c from './MobileFooter.module.scss';

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 100 },
};

// const panelStates = {
//   open: { opacity: 1, y: 0 },
//   closed: { opacity: 1, y: 200 },
// };
const backdropStates = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 0 },
};

// const Header = ({ item, handleClose, style, animate }) =>
//   item ? (
//     <motion.div
//       className={`toolbar-view-header ${item.id}`}
//       initial="closed"
//       style={style}
//       variants={variants}
//       animate={animate}
//     >
//       <span>{item.title}</span>
//       <img role="presentation" alt="Close" src={CloseIcon} onClick={handleClose} />
//     </motion.div>
//   ) : null;

const useIsDragging = () => {
  const [{ canDrop }] = useDrop({
    accept: 'image',
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
    }),
  });
  return canDrop;
};

const ContentPanel = ({ children, title, isActive }) => {
  const panelStates = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 1, y: window.innerHeight * 0.7 },
  };
  return (
    <motion.div
      variants={panelStates}
      className={c.panel}
      animate={isActive ? 'open' : 'closed'}
      // transition={{ type: 'spring', bounce: 0.25 }}
      transition={{ type: 'tween', duration: 0.3, ease: 'easeInOut' }}
    >
      <div className={classnames(c.panelContent)}>
        <h2>{title}</h2>
        {children}
      </div>
    </motion.div>
  );
};

const useIsToolbarVisible = () => {
  const isDragging = useIsDragging();
  const isToolbarVisible = useSelector((storeState) => {
    const isEditing = SceneHelper.isEditing(storeState);
    const isEditLayoutArea = SceneHelper.getIsEditArea(storeState);
    const viewAllPages = SceneHelper.getViewAllPages(storeState);
    const mode = SceneHelper.getMode(storeState);
    return !isEditing && !isEditLayoutArea && (!viewAllPages || mode === modes.photoPrint);
  });
  return !isDragging && isToolbarVisible;
};

const MobileToolbar = () => {
  const items = useToolbarItems();
  // const scene = useSelector((state) => state.scene);
  const { openedPageFooter: selectedItem, openFooter, closeFooter } = useToolbar();

  const [activeItem, setActiveItem] = React.useState(selectedItem);
  useEffect(() => {
    setActiveItem(selectedItem);
  }, [selectedItem]);
  const handleCloseFooter = () => {
    setActiveItem(null);
    setTimeout(closeFooter, 300);
  };

  const isToolbarVisible = useIsToolbarVisible();
  const isBackgropVisible = activeItem && isToolbarVisible;
  return (
    <div className={c.container} style={{ pointerEvents: !isToolbarVisible ? 'none' : '' }}>
      <motion.div
        className={c.backdrop}
        onClick={handleCloseFooter}
        variants={backdropStates}
        animate={isBackgropVisible ? 'open' : 'closed'}
        style={{ pointerEvents: !isBackgropVisible ? 'none' : undefined }}
      />
      {items.map(({ id, component: C, title, ...rest }) => (
        <ContentPanel key={id} {...{ title, isActive: isToolbarVisible && activeItem === id }}>
          <C key={id} id={id} isActive={selectedItem === id} {...rest} />
        </ContentPanel>
      ))}
      <motion.div className={c.toolbar} variants={variants} animate={isToolbarVisible ? 'open' : 'closed'}>
        <ul>
          {items.map(({ id, title, ...rest }) => (
            <ToolbarItem
              key={id}
              {...rest}
              isActive={activeItem === id}
              onClick={() => (activeItem === id ? handleCloseFooter() : openFooter(id))}
            >
              {title}
            </ToolbarItem>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

const ToolbarItem = ({ children, onClick, isActive, icon: Icon }) => (
  <li className={classnames({ [c.active]: isActive })}>
    <button type="button" onClick={onClick}>
      <Icon className={c.buttonIcon} />
      {children}
    </button>
  </li>
);

export default MobileToolbar;
