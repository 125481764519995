import React, { useEffect, memo, useMemo } from 'react';
import * as d3 from 'd3';
import SVGFilter from 'utils/svg-export/components/SVGFilter';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import { useSrcByShapeId } from 'hooks';
import ImageQualityIcon from './ImageQualityIcon';

const calculatePosition = (e, image, allowArea) => {
  let { x, y } = e;
  const { width, height } = image;

  const maxX = x + width;
  const maxY = y + height;

  if (x > allowArea.x) {
    x -= x - allowArea.x;
  }
  if (y > allowArea.y) {
    y -= y - allowArea.y;
  }
  if (maxX < allowArea.width) {
    x += allowArea.width - maxX;
  }
  if (maxY < allowArea.height) {
    y += allowArea.height - maxY;
  }
  return { x, y };
};

const useImageFromObject = (object) => {
  const image = useMemo(() => {
    let { image } = object;
    if (image) {
      image = CalculationShapeHelper.transformImageFromPercent(object);
    }
    return image;
  }, [object]);
  return image;
};

export const Image = ({ className, object, offClipPath, quality, originalSrc, showEmptyPhoto }) => {
  const image = useImageFromObject(object);
  const src = useSrcByShapeId(object?.id, originalSrc);
  if (!image) {
    if (showEmptyPhoto) {
      return (
        <rect
          x={object?.x}
          y={object?.y}
          width={object?.width}
          height={object?.height}
          opacity="0.8"
          strokeWidth="0.1"
          stroke="#fff"
          fill="rgb(153, 153, 153)"
        />
      );
    }
    return null;
  }
  return (
    <>
      {!offClipPath ? (
        <defs>
          <clipPath id={`canvas-clip${object.id}`}>
            <rect x={0} y={0} width={object.width} height={object.height} />
          </clipPath>
        </defs>
      ) : null}
      <SVGFilter
        id={`filter_${object.id}`}
        contrast={image?.contrast}
        brightness={image?.brightness}
        saturation={image?.saturation}
      />
      <image
        className={className}
        clipPath={offClipPath ? null : `url(#canvas-clip${object.id})`}
        transform={`translate(${object.x} ${object.y}) rotate(${object.rotation || 0})`}
        preserveAspectRatio="xMinYMin slice"
        x={image?.x}
        y={image?.y}
        width={image?.width}
        height={image?.height}
        xlinkHref={src}
        filter={`url(#filter_${object.id})`}
      />
      <ImageQualityIcon shape={object} quality={quality} />
    </>
  );
};

const ImageWithAction = ({ object, originalSrc, onClick, offClipPath, draggable, onDragEnd, onDragStart, quality }) => {
  const image = useImageFromObject(object);
  useEffect(() => {
    d3.select(`.image${object.id}`).on('click', (e) => {
      document.body.style.cursor = 'default';
      onClick();
      e.stopPropagation();
    });
    if (draggable) {
      const handleDragRect = d3
        .drag()
        .subject(function subject() {
          const me = d3.select(this);
          return { x: me.attr('x'), y: me.attr('y') };
        })
        .on('start', () => {
          document.body.style.cursor = 'grabbing';
          onDragStart && onDragStart();
        })
        .on('drag', function drag(e) {
          const { x, y } = calculatePosition(e, image, { x: 0, y: 0, width: object.width, height: object.height });
          const me = d3.select(this);
          me.attr('x', x);
          me.attr('y', y);
        })
        .on('end', (e) => {
          document.body.style.cursor = 'grab';
          const { x, y } = calculatePosition(e, image, { x: 0, y: 0, width: object.width, height: object.height });
          onDragEnd && onDragEnd({ x, y });
        });
      handleDragRect(d3.select(`.image${object.id}`));
      d3.select(`.image${object.id}`)
        .on('mouseover', () => {
          if (document.body.style.cursor === 'default') document.body.style.cursor = 'grab';
        })
        .on('mouseleave', () => {
          document.body.style.cursor = 'default';
        });
    }
  }, [draggable, image, object.height, object.id, object.width, onClick, onDragEnd, onDragStart]);
  return (
    <Image
      className={`image${object.id}`}
      object={object}
      offClipPath={offClipPath}
      quality={quality}
      originalSrc={originalSrc}
    />
  );
};

export default memo(ImageWithAction, fastDeepEqual);
