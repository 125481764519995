import React, { memo } from 'react';
import { usePageShapeById, useSetIsEditingAndCloseFooter, useIsEditArea } from 'hooks';
import { shapeTypes } from 'constants/index';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import Background from './Background';
import Layout from './Layout';
import DropZone from './DropZone';
import Clipart from './Clipart';
import Text from './Text';
import Calendar from './Calendar';

const ObjectContainer = memo(function ObjectContainer({ id, scaleText }) {
  const shape = usePageShapeById(id);
  const isEditLayoutArea = useIsEditArea();
  const onClick = useSetIsEditingAndCloseFooter(id);
  if (shape.type === shapeTypes.background) {
    return <Background object={shape} />;
  }
  if (shape.type === shapeTypes.layout && !isEditLayoutArea) {
    return <Layout object={shape} onClick={onClick} />;
  }
  if (shape.type === shapeTypes.dropZone) {
    return <DropZone object={shape} onClick={onClick} />;
  }
  if (shape.type === shapeTypes.clipart) {
    return <Clipart object={shape} onClick={onClick} />;
  }
  if (shape.type === shapeTypes.text) {
    return <Text key={shape.id} object={shape} onClick={onClick} scaleText={scaleText} />;
  }
  if (shape.type === shapeTypes.calendar) {
    return <Calendar shape={shape} onClick={onClick} />;
  }
  return null;
}, fastDeepEqual);

export default ObjectContainer;
