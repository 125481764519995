import React, { forwardRef, useRef } from 'react';
import classnames from 'classnames';
import { useClickAway } from 'react-use';
import { useToolbar } from 'hooks';
import DoneButton from './DoneButton';
import './ActionsContainer.scss';
import DivFullPage from './DivFullPage';

export const ActionsContainer = forwardRef(({ hide, children, maxWidth, onClickDone }, ref) => {
  const { openedPageToolbar } = useToolbar();
  return (
    <div ref={ref} className={classnames('action-container', openedPageToolbar && 'toolbar-opened')}>
      <div className={classnames('action', { hide })} style={{ maxWidth }}>
        {children}
        <DoneButton onClick={onClickDone} />
      </div>
    </div>
  );
});

export const SubActionsContainer = ({ show, children, clickAway, className }) => {
  const colorPickerRef = useRef(null);
  useClickAway(colorPickerRef, () => {
    if (show) clickAway();
  });

  return (
    <>
      {show ? <DivFullPage /> : null}
      <div ref={colorPickerRef} className={classnames(className, 'sub-action-container', { hide: !show })}>
        <div className="action">{children}</div>
      </div>
    </>
  );
};

export default ActionsContainer;
