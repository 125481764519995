import React, { useState, useCallback } from 'react';
import CropFooter from 'components/actionBar/CropFooter';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import { useDimensions, useQualityEditedShape, useUpdateShape } from 'hooks';
import ActionBarTeleporter from 'components/actionBar/ActionBarTeleporter';
import ImageWithAction from '../Image';
import ZoomSlider from './components/ZoomSlider';
import MaskCropPhoto from './components/MaskCropPhoto';
import EdgesCrop from './components/EdgesCrop';
import MoveIndicator from './components/MoveIndicator';
import c from './CropPhoto.module.scss';

const CropPhotoComponent = ({
  objectId,
  object,
  editorHeightPx,
  editorWidthPx,
  toggleIsCrop,
  viewWidth,
  viewHeight,
}) => {
  const [showMoveIndicator, setShowMoveIndicator] = useState(true);
  const quality = useQualityEditedShape();
  const updateEditorObject = useUpdateShape(objectId);
  const dimensions = useDimensions();
  const onDragStart = useCallback(() => {
    setShowMoveIndicator(false);
  }, []);
  const onDragEnd = useCallback(
    ({ x, y }) => {
      const image = object?.image;
      const centerX = (object.width / 2 - x) / CalculationShapeHelper.pctToPx(object.width, image.width);
      const centerY = (object.height / 2 - y) / CalculationShapeHelper.pctToPx(object.width, image.height);
      updateEditorObject({
        image: {
          centerX,
          centerY,
          x: CalculationShapeHelper.pxToPct(object.width, x),
          y: CalculationShapeHelper.pxToPct(object.width, y),
        },
      });
    },
    [object?.image, object.width, object.height, updateEditorObject],
  );
  return (
    <>
      <ActionBarTeleporter>
        <CropFooter quality={quality} toggleIsCrop={toggleIsCrop} />
      </ActionBarTeleporter>
      <div className={c.container}>
        <svg
          width={`${editorWidthPx}px`}
          height={`${editorHeightPx}px`}
          viewBox={`0 0 ${object.width} ${object.height}`}
          className={c.svg}
        >
          <ImageWithAction
            object={{ id: object.id, image: object.image, width: object.width, height: object.height }}
            onClick={toggleIsCrop}
            offClipPath
            draggable
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          />
          <MaskCropPhoto
            object={object}
            maskOverflowVisible
            editorWidth={dimensions.width}
            editorHeight={dimensions.height}
            viewWidth={(viewWidth / editorWidthPx) * object.width}
            viewHeight={(viewHeight / editorHeightPx) * object.height}
          />
          <EdgesCrop object={object} />
          <MoveIndicator width={object.width} height={object.height} hide={!showMoveIndicator} />
        </svg>
        <div className={c.zoomContainer}>
          <ZoomSlider
            flat
            objectId={objectId}
            image={object.image}
            objectWidth={object.width}
            objectHeight={object.height}
            editorHeightPx={editorHeightPx}
          />
        </div>
      </div>
    </>
  );
};

export default CropPhotoComponent;
