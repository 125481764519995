import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import './Modal.scss';
import { close as closeModal } from 'actions/modal';
import { useDispatch } from 'react-redux';

const Modal = ({ isOpen, children, className, dismissable }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  return (
    <ReactModal
      onRequestClose={() => {
        if (dismissable) dispatch(closeModal());
      }}
      closeTimeoutMS={300}
      shouldReturnFocusAfterClose={false} // after closing modal, will not select last used element
      className={classnames('react-modal', { mobile: isMobile }, className)}
      isOpen={!!isOpen}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
