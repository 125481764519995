import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { borderTypeOptions } from 'constants/editor';
import VerticalSelect from 'components/common/VerticalSelect';
import './Borders.scss';
import { useUpdateBordersType } from 'hooks';

const Borders = () => {
  const updateConfig = useUpdateBordersType();
  const borderType = useSelector(
    ({
      scene: {
        config: { borderType },
      },
    }) => borderType,
  );
  const setBorder = useCallback((borderType) => updateConfig(borderType), [updateConfig]);
  return (
    <div className="borders-container">
      <div className="border-button">
        <VerticalSelect
          options={borderTypeOptions}
          onChange={setBorder}
          value={borderType}
          onlyInnerDelimiters
          preservePaddingForCheck
        />
      </div>
    </div>
  );
};

export default Borders;
