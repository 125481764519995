import React from 'react';
import { useIsRotateMask, useOverlay } from 'hooks';
import ImageOverlayUnderlay from './ImageOverlay';
import SvgContainer from './SvgContainer';

const Overlay = () => {
  const [overlay, scaleOverlay] = useOverlay();
  const isRotateMask = useIsRotateMask();
  if (!overlay) return null;
  return (
    <SvgContainer key={overlay} style={{ pointerEvents: 'none' }}>
      <ImageOverlayUnderlay url={overlay} scaleOverlay={scaleOverlay} isRotateMask={isRotateMask} />
    </SvgContainer>
  );
};

export default Overlay;
