export default {
  height: 1,
  rotation: 0,
  fontFamily: 'Raleway',
  text: '',
  type: 'text',
  fill: '#000000',
  textArr: [],
  textAlign: 'center',
};
