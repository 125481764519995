import React, { useMemo } from 'react';
import find from 'lodash/find';
import Select from 'react-select';
import useIsMobile from 'hooks/useIsMobile';

/**
 * A custom select component.
 *
 * @param {Object} props The props for the component.
 * @param {number | string} props.value The selected option value.
 * @param {{ value: number | string, label: string }[]} props.options The available options.
 * @param {function} props.onChange The handler for option changes.
 * @param {number | string} props.defaultValue The default option value.
 * @param {string} props.classNamePrefix The CSS class prefix.
 *
 * @returns {JSX.Element}
 */
const CustomSelect = ({ value, options, onChange, defaultValue, classNamePrefix }) => {
  const isMobile = useIsMobile();
  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);
  return (
    <Select
      defaultValue={defaultValue || find(options, { value })}
      menuPlacement={menuPlacement}
      value={find(options, { value })}
      onChange={({ value }) => onChange(value)}
      options={options}
      classNamePrefix={classNamePrefix}
    />
  );
};

export default CustomSelect;
