import React, { memo } from 'react';
import { usePageShapeById, useAdmin } from 'hooks';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import SceneHelper from 'utils/SceneHelper';
import TextInSvg from 'utils/svg-export/components/SVGPage/components/TextInSvg';
import { Image } from 'components/editors/NewEditor/components/Image';
import Calendar from './Calendar';

const ObjectContainerSvg = memo(function ObjectContainer({ id, showEmptyPhoto, originalSrc = true }) {
  const shape = usePageShapeById(id);
  const admin = useAdmin();
  if (SceneHelper.isText(shape) && (!SceneHelper.isEmptyShape(shape) || admin)) return <TextInSvg shape={shape} />;
  if (SceneHelper.isCalendar(shape)) return <Calendar shape={shape} />;
  if (
    SceneHelper.isLayout(shape) ||
    SceneHelper.isDropZone(shape) ||
    SceneHelper.isClipart(shape) ||
    SceneHelper.isBackground(shape)
  ) {
    return <Image object={shape} originalSrc={originalSrc} showEmptyPhoto={showEmptyPhoto} />;
  }
  return null;
}, fastDeepEqual);

export default ObjectContainerSvg;
