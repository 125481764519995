import React from 'react';
import { useAdmin } from 'hooks';

const DaySize = ({ calendarSettings }) => {
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <rect
      x={calendarSettings.DayShape.margin.left}
      y={calendarSettings.DayShape.margin.top}
      width={
        calendarSettings.DayShape.width - calendarSettings.DayShape.margin.left - calendarSettings.DayShape.margin.right
      }
      height={
        calendarSettings.DayShape.height -
        calendarSettings.DayShape.margin.top -
        calendarSettings.DayShape.margin.bottom
      }
      fillOpacity="0"
      stroke="grey"
      strokeOpacity="0.3"
      strokeWidth="1"
      strokeDasharray="3,3"
    />
  );
};

export default DaySize;
