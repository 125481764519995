import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSendBack } from '@fortawesome/pro-regular-svg-icons';
import { useBringToBack } from 'hooks';

const BringToBack = () => {
  const bringToBack = useBringToBack();
  return (
    <div className="icon-button-container order-option" onClick={bringToBack}>
      <FontAwesomeIcon.Memo className="icon-button" icon={faSendBack} />{' '}
      <span className="order-option-label">Send to back</span>
    </div>
  );
};

export default BringToBack;
