import { applyMiddleware, createStore } from 'redux';
import reducer from 'actions/reducer';
// import logger from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';
import config from 'config';
import LogQueue from 'utils/LogQueue';

export const ReduxActionsLogQueue = new LogQueue(30);

const blacklistedActionTypes = [
  'PHOTOS_SET_BACKGROUNDS',
  'PHOTOS_SET',
  'PHOTOS_SET_FOLDERS',
  'CONFIG_SIZES',
  'PHOTOS_SET_CLIPARTS',
];
if (typeof window !== 'undefined') {
  window.ReduxActionsLogQueue = ReduxActionsLogQueue;
}
const logger = (/* store */) => (next) => (action) => {
  if (!blacklistedActionTypes.includes(action?.type)) ReduxActionsLogQueue.enqueue(action);

  const result = next(action);
  return result;
};

let enhancer;
config.showDevButtons && console.log('config.isDevelopment:', config.showDevButtons);
if (config.showDevButtons) enhancer = composeWithDevTools(applyMiddleware(logger));
else enhancer = applyMiddleware(logger);

const store = createStore(reducer, enhancer);

export default store;
