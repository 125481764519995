import React from 'react';
import { useDisableEventsTab, useToggleDisableEventsTab } from 'hooks';
import Switch from 'components/common/Switch';
import { useAdmin } from 'hooks/sceneHooks';

const SwitchDisableEventsTab = ({ className }) => {
  const disableEventsTab = useDisableEventsTab();
  const toggleDisableEventsTab = useToggleDisableEventsTab();
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <Switch
      checked={disableEventsTab}
      className={className}
      onChange={toggleDisableEventsTab}
      label="Disable events tab"
    />
  );
};

export default SwitchDisableEventsTab;
