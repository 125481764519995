import React from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faFileArchive } from '@fortawesome/pro-solid-svg-icons';
import { downloadSvg } from 'api/utils';
import store from 'store';
import DevModalButton from 'components/modal/DevModalButton';
import { addTextArrToTextObjects } from 'actions/scene';
import './Dev.scss';

const DownloadSVGButton = ({ download, onlyCurrentPage }) => {
  const handleSave = React.useCallback(() => {
    store.dispatch(addTextArrToTextObjects());
    downloadSvg(store.getState().scene, download, { renderMask: true, onlyCurrentPage });
  }, [download, onlyCurrentPage]);
  return (
    <Button onClick={handleSave} className="download-button" data-for="bottomTooltip" data-tip="[DEV] Download SVGs">
      <FontAwesomeIcon.Memo icon={onlyCurrentPage ? faFileDownload : faFileArchive} />
    </Button>
  );
};

const Dev = ({ desktop, showTitleOnDesktop, title }) => (
  <div className="dev-tab">
    {desktop && showTitleOnDesktop ? <h2>{title}</h2> : null}
    <DownloadSVGButton download onlyCurrentPage />
    <DevModalButton />
    <DownloadSVGButton download />
  </div>
);

export default Dev;
