import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

const DeleteModal = ({ onClose, onConfirm, title, confirmText = 'Delete', closeButtonText = 'Cancel' }) => (
  <>
    <div className="modal-header">
      <div className="modal-header-text">Are you sure you want delete "{title}"?</div>
    </div>
    <div className="modal-body">
      <div className="modal-text">If you delete it will not be possible to restore</div>
    </div>
    <div className="action-buttons-vertical">
      <Button color="azure" rounded size="lg" onClick={onConfirm}>
        <FontAwesomeIcon.Memo icon={faTrash} style={{ height: '1em', width: '1em', marginRight: '0.8em' }} />
        {confirmText}
      </Button>
      <Button outline rounded size="lg" onClick={onClose}>
        {closeButtonText}
      </Button>
    </div>
  </>
);

export default DeleteModal;
