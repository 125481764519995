import React, { useMemo, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { usePasteObject, useScrollToTop, useToolbar } from 'hooks';
import { motion } from 'framer-motion';
import PixaPrints from 'api';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import './Toolbar.scss';

export const TOOLBAR_WIDTH = 255;

const ToolbarItem = ({ children, onClick, isActive, icon: Icon }) => (
  <li>
    <button type="button" onClick={onClick} className={classnames({ active: isActive })}>
      <div className="toolbar-icon">
        <Icon />
      </div>
      {children}
    </button>
  </li>
);

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { x: '-100%' },
};

const ToolbarView = ({ selectedItem, items, isVisible }) => {
  const [toolbarScrollRef, scrollToTopToolbar] = useScrollToTop();
  return (
    <motion.div
      ref={toolbarScrollRef}
      variants={variants}
      transition={{ duration: 0.15 }}
      animate={isVisible ? 'open' : 'closed'}
      className={classnames('toolbar-view')}
    >
      <div className="toolbar-view-component-container">
        {items.map(({ id, component: C, ...rest }) => (
          <C key={id} id={id} isActive={selectedItem === id} scrollToTop={scrollToTopToolbar} desktop {...rest} />
        ))}
      </div>
    </motion.div>
  );
};
const ToolbarViewMemo = React.memo(ToolbarView);

const toolbarVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
};
const Toolbar = ({ items }) => {
  const { openedPageToolbar: selectedItem, openToolbar, closeToolbar, openPreviousToolbar } = useToolbar();
  const isVisible = useMemo(() => selectedItem !== null, [selectedItem]);
  useEffect(() => {
    const interval = setInterval(async () => {
      await PixaPrints.saveState({ autosave: true });
    }, 60 * 1000); // every 1 minutes
    return () => clearInterval(interval);
  }, []);
  const pasteObject = usePasteObject();
  const toggleToolbar = useCallback(
    () => (isVisible ? closeToolbar() : openPreviousToolbar()),
    [closeToolbar, isVisible, openPreviousToolbar],
  );
  return (
    <motion.div
      className="toolbar"
      variants={toolbarVariants}
      animate={items.length ? 'open' : 'closed'}
      initial="closed"
      transition={{ duration: 0.3 }}
    >
      <div className="toolbar-inner">
        <ul>
          {items.map(({ id, title, ...rest }) => (
            <ToolbarItem
              key={id}
              {...rest}
              isActive={selectedItem === id}
              onClick={() => (selectedItem === id ? closeToolbar() : openToolbar(id))}
            >
              {title}
            </ToolbarItem>
          ))}
        </ul>
        <ToolbarViewMemo selectedItem={selectedItem} items={items} isVisible={isVisible} />
      </div>
      <KeyboardEventHandler
        handleKeys={['ctrl+b', 'meta+b', 'ctrl+v', 'meta+v', 'ctrl+s', 'meta+s']}
        onKeyEvent={async (key, event) => {
          event.preventDefault();
          if (key === 'ctrl+b' || key === 'meta+b') {
            toggleToolbar();
          }
          if (key === 'ctrl+v' || key === 'meta+v') {
            pasteObject();
          }
          if (key === 'ctrl+s' || key === 'meta+s') {
            await PixaPrints.saveState();
          }
        }}
      />
    </motion.div>
  );
};

export default Toolbar;
