// @ts-check
import { useSelector } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import SceneHelper from 'utils/SceneHelper';

/**
 * @param {string} type - type of shape
 * @param {string} pageId
 */
export const useAllowArea = (type, pageId) =>
  useSelector(
    /** @param {import('actions/scene.types').StoreState} scene */ (scene) =>
      SceneHelper.calcAllowAreaSync(scene, type, pageId),
    fastDeepEqual,
  );

export default useAllowArea;
