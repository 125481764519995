import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import GridSelect from 'components/common/GridSelect';
import { find } from 'lodash';
import { smartSetLayoutId } from 'api/utils';
import classnames from 'classnames';
import c from './Layouts.module.scss';
import SwitchEditLayout from './components/SwitchEditLayoutArea';
import SwitchDisableLayout from './components/SwitchDisableLayout';
import ClearLayouts from './components/ClearLayouts';
import LayoutGutterTypeSelect from './components/LayoutGutterTypeSelect';

const OptionComponent = ({ value: layoutId, selected, layouts, className }) => {
  const { layout } = find(layouts, { id: layoutId }) || {};
  return (
    <div className={className}>
      <svg width="100%" height="100%">
        {layout.map(({ x, y, height, width }) => (
          <rect
            key={`${x};${y};${width};${height}`}
            x={`${x}%`}
            y={`${y}%`}
            width={`${width}%`}
            height={`${height}%`}
            style={{
              strokeWidth: `${selected ? 7 : 5}`,
              fillOpacity: '0',
              strokeOpacity: '1',
            }}
          />
        ))}
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          style={{
            strokeWidth: `${selected ? 15 : 10}`,
            fillOpacity: '0',
            strokeOpacity: '1',
          }}
        />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
  );
};

const Layouts = () => {
  const { layouts, showLayoutGutterSwitch } = useSelector(
    ({
      scene: {
        config: { layouts, showLayoutGutterSwitch },
      },
    }) => ({ layouts, showLayoutGutterSwitch }),
  );
  const layoutsOptionById = useMemo(() => layouts.map(({ id, ...rest }) => ({ ...rest, value: id })), [layouts]);
  // const layoutId = useLayoutId();
  return (
    <div className={classnames(c.layoutsContainer, { 'no-layout-switch': !showLayoutGutterSwitch })}>
      <SwitchEditLayout className={c.switch} />
      <SwitchDisableLayout className={c.switch} />
      <LayoutGutterTypeSelect />
      <ClearLayouts />
      <GridSelect
        options={layoutsOptionById}
        optionsClassName={c.layoutOptionItem}
        OptionComponent={(props) => (
          <OptionComponent layouts={layouts} className={c.layoutOptionContainer} {...props} />
        )}
        onChange={smartSetLayoutId}
        // value={layoutId}
      />
    </div>
  );
};

export default Layouts;
