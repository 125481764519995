import axios from 'axios';
import config from 'config';
import * as Sentry from '@sentry/browser';
import ImageUploader from '../image-uploader';

let auth;
let uploadEndpoint;
if (config.isProduction) {
  auth = true;
  uploadEndpoint = () => `${config.apiUrl}/uploader/upload/1`;
} else {
  uploadEndpoint = () => 'https://test.pixaprints.co.uk/uploader/upload/1';
  auth = {
    username: 'test',
    password: 'ppuk',
  };
}
const testWithPPUK = true;
if (testWithPPUK) {
  auth = true;
  uploadEndpoint = (folderId) => `${config.apiUrl}/uploader/upload/${folderId}`;
}

class PixaPrintsImageUploader extends ImageUploader {
  debug = false;

  upload = (file) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('name', file.name);
      formData.append('file', file);
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this._emitProgress(file.id, { progress: percentCompleted, file });
          this.debug && console.log(`[PixaPrintsImageUploader] uploading ${file.name}: ${percentCompleted}%`);
        },
        withCredentials: !!auth,
        ...(auth ? { auth } : {}),
        // headers: {
        //   Authorization: 'Basic dGVzdDpwcHVr',
        // },
        // headers: { Authorization: `Basic dGVzdDpwcHVr` },
      };
      axios
        .post(uploadEndpoint(file.folderId), formData, config)
        .then((res) => {
          const {
            data: { result },
          } = res;
          const url = result.images.large;
          this._emitComplete(file.id, {
            id: result.photo_id,
            url,
            original: result.images.original,
            thumbnail: result.images.small,
            file,
            data: res.data.result,
          });
          resolve(url);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          this._emitError(file.id, { error, file });
          reject(error);
        });
    });
}

export default PixaPrintsImageUploader;
