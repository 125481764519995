import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import Select, { components } from 'react-select';
import { useUpdateShape } from 'hooks';
import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';

const BlackAndWhite = ({ shape, isColorful }) => {
  const { image, id } = shape;
  const onChange = useUpdateShape(id);
  const isImageBlackAndWhite = useMemo(() => {
    return image?.saturation === -100;
  }, [image?.saturation]);
  return (
    <div
      className={classNames(
        { checked: isColorful ? !isImageBlackAndWhite : isImageBlackAndWhite },
        'icon-button-container order-option',
      )}
      onClick={() => {
        onChange({ image: { saturation: isColorful ? 0 : -100 } });
      }}
    >
      <FontAwesomeIcon.Memo className="icon-button" icon={faImage} /> <p>{isColorful ? 'Colour' : 'Black and white'}</p>
    </div>
  );
};

const useOptionsEffects = (shape) => {
  const options = useMemo(
    () => [
      {
        value: 'blackAndWhite',
        Component: () => <BlackAndWhite shape={shape} />,
      },
      {
        value: 'colorful',
        Component: () => <BlackAndWhite shape={shape} isColorful />,
      },
    ],
    [shape],
  );
  return options;
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div ref={innerRef} {...innerProps}>
      {data.Component ? <data.Component /> : null}
    </div>
  );
};

const SingleValue = ({ children, ...props }) => {
  const isMobile = useIsMobile();
  return (
    <components.SingleValue {...props}>
      <div className="icon-button-container">
        <FontAwesomeIcon.Memo className="icon-button" icon={faImage} />{' '}
        <span> {isMobile ? 'B&W' : 'Black and white'}</span>
      </div>
    </components.SingleValue>
  );
};

export const EffectsSelect = ({ shape }) => {
  const isMobile = useIsMobile();
  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);
  const optionsEffects = useOptionsEffects(shape);
  return (
    <Select
      classNamePrefix="effects"
      menuPlacement={menuPlacement}
      isSearchable={false}
      defaultValue={optionsEffects[0]}
      components={{ Option: CustomOption, SingleValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      options={optionsEffects}
    />
  );
};

export default EffectsSelect;
