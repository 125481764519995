import React, { useMemo } from 'react';
import { useCountLowQualityImage } from 'hooks';
import WarningText from 'components/common/WarningText';

const WarningLowQualityImage = ({ className }) => {
  const countLowQualityImage = useCountLowQualityImage();
  const text = useMemo(() => `We found ${countLowQualityImage} low resolutions photos`, [countLowQualityImage]);
  if (!countLowQualityImage) return null;
  return <WarningText text={text} className={className} />;
};

export default WarningLowQualityImage;
