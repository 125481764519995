import React, { useRef } from 'react';
import { faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import { useAdmin, useUpdateShape } from 'hooks';
import ToolBarColorPicker from '../components/ToolbarColorPicker';
import ActionsContainer from '../components/ActionsContainer';
import VerticalDivider from '../components/VerticalDivider';
import './ActionBarCalendar.scss';
import DayStyle from './components/DayStyle';
import EventStyle from './components/EventStyle';
import CustomSelect from '../components/CustomSelect';
import DayLabelStyle from './components/DayLabelStyle';
import Position from './components/Position';
import DayTopBorderStyle from './components/DayTopBorder';
import CalendarSizesStyle from './components/CalendarSIzes';

export const CalendarFooter = ({ hide, shape }) => {
  const onChange = useUpdateShape(shape.id);
  console.log('shape:', shape);
  const admin = useAdmin();
  const toolbarRef = useRef();

  return (
    <ActionsContainer hide={hide} maxWidth={admin ? 740 : undefined}>
      <DayStyle object={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <EventStyle object={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <DayLabelStyle shape={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <Position shape={shape} />

      <DayTopBorderStyle shape={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <CalendarSizesStyle shape={shape} toolbarRef={toolbarRef} />
      <VerticalDivider />

      <CustomSelect
        options={[
          {
            value: 1,
            label: '1',
          },
          {
            value: 2,
            label: '2',
          },
        ]}
        value={shape.calendarType}
        onChange={(calendarType) => {
          onChange({ calendarType });
        }}
      />

      <ToolBarColorPicker
        initialValue={shape.backgroundColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ backgroundColor: value })}
        icon={faFillDrip}
        disableAlpha={false}
        disableIconColorMatching
      />
    </ActionsContainer>
  );
};

export default CalendarFooter;
