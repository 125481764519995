import React from 'react';

const linesColor = '#535353';
const sRadius = 1.5; // small radius
const radius = 25;
const lengthLine = 6;
const strokeWidth = 0.7;

const Arrow = ({ length, stroke, strokeWidth, ...props }) => (
  <g {...props}>
    <line x1={0} y1={0} x2={length} y2={0} stroke={stroke} strokeWidth={strokeWidth} />
    <line
      x1={length * 0.3}
      y1={0}
      x2={length}
      y2={0}
      stroke={stroke}
      strokeWidth={strokeWidth}
      transform={`translate(${strokeWidth / 2}, ${strokeWidth / 2 / Math.sqrt(2)}) rotate(45, ${length}, 0)`}
    />
    <line
      x1={length * 0.3}
      y1={0}
      x2={length}
      y2={0}
      stroke={stroke}
      strokeWidth={strokeWidth}
      transform={`translate(${strokeWidth / 2}, ${-(strokeWidth / 2) / Math.sqrt(2)}) rotate(-45, ${length}, 0)`}
    />
  </g>
);

const MoveIndicator = ({ hide }) => {
  if (hide) {
    return null;
  }
  return (
    <g>
      <svg width="100%" height="100%" viewBox="0 0 100 100" style={{ pointerEvents: 'none' }}>
        <circle cx="50%" cy="50%" r={radius} fill="rgba(255,255,255,0.3)" />
        <circle cx="50%" cy="50%" r={sRadius} stroke={linesColor} strokeWidth={strokeWidth} fill="#fff0" />
        <Arrow
          length={lengthLine}
          stroke={linesColor}
          strokeWidth={strokeWidth}
          transform={`translate(${50 + sRadius}, 50)`}
        />
        <Arrow
          length={lengthLine}
          stroke={linesColor}
          strokeWidth={strokeWidth}
          transform={`rotate(90, 50, 50) translate(${50 + sRadius}, 50)`}
        />
        <Arrow
          length={lengthLine}
          stroke={linesColor}
          strokeWidth={strokeWidth}
          transform={`rotate(180, 50, 50) translate(${50 + sRadius}, 50)`}
        />
        <Arrow
          length={lengthLine}
          stroke={linesColor}
          strokeWidth={strokeWidth}
          transform={`rotate(270, 50, 50) translate(${50 + sRadius}, 50)`}
        />
      </svg>
    </g>
  );
};

export default MoveIndicator;
