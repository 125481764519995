// @ts-check
import { useMemo } from 'react';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';
import startOfWeek from 'date-fns/startOfWeek';
import { useCurrentPageDate, useCurrentPageWeekStarting } from 'hooks';
import { weekStartingConst } from 'constants/index';
import { endOfMonth } from 'date-fns';
import { getCalendarSettings } from 'constants/calendarSettings';

/**
 * @param {import('actions/scene.types').CalendarType} type - type of shape
 * @returns {Date[]}
 */
const useRangeDays = (type = 1) => {
  const calendarSettings = getCalendarSettings();
  const currentDate = useCurrentPageDate();
  const weekStarting = useCurrentPageWeekStarting();
  const rangeDays = useMemo(() => {
    let startDate = new Date();
    let endDate = new Date();
    if (type === 1) {
      startDate = startOfWeek(new Date(currentDate?.year, currentDate?.month), {
        weekStartsOn: weekStartingConst.sunday === weekStarting ? 0 : 1,
      });
      endDate = addDays(startDate, 6 * calendarSettings.daysOfWeek - 1);
    }
    if (type === 2) {
      startDate = new Date(currentDate?.year, currentDate?.month);
      endDate = endOfMonth(startDate);
    }
    return eachDayOfInterval({
      start: startDate,
      end: endDate,
    });
  }, [calendarSettings.daysOfWeek, currentDate?.month, currentDate?.year, type, weekStarting]);
  return rangeDays;
};

export default useRangeDays;
