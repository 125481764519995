import { maxBy, cloneDeep, find } from 'lodash';
import { v4 as uuid } from 'uuid';
import { modes, shapeTypes } from 'constants/index';
import { EDGE_WRAP } from 'constants/editor';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import SceneHelper from 'utils/SceneHelper';
import { initImage } from 'actions/utils';

class Migrations {
  #migrations = [
    {
      version: 1,
      up: (scene) => {
        const newScene = cloneDeep(scene);
        newScene.photos = newScene.photos || [];
        const { layouts, layoutId, mode, edgeWrap, dimensions } = newScene.config;
        const currentLayout = find(layouts, { id: layoutId });
        newScene.objects = currentLayout.layout.map((layout, index) => {
          const shape = {
            x: layout.x / 100,
            y: layout.y / 100,
            width: layout.width / 100,
            height: layout.height / 100,
            type: shapeTypes.layout,
            id: uuid(),
          };
          if (scene.objects[index]) {
            const image = scene.objects[index];
            const { id, uploaded, thumbnailSrc, src, originalSrc, naturalWidth, naturalHeight, width, height } = image;
            if (mode === modes.canvas && edgeWrap === EDGE_WRAP) {
              const convert = (x) =>
                CalculationShapeHelper.pxToPct(
                  dimensions.width + 2 * dimensions.frame,
                  CalculationShapeHelper.pctToPx(dimensions.width, x),
                );
              image.width = convert(image.width);
              image.height = convert(image.height);
              image.x = convert(image.x);
              image.y = convert(image.y);
            }
            shape.image = image;
            newScene.photos.push({
              id,
              uploaded,
              thumbnailSrc,
              src,
              originalSrc,
              naturalWidth,
              naturalHeight,
              width,
              height,
            });
          }
          return shape;
        });
        return newScene;
      },
    },
    {
      version: 2,
      up: (scene) => {
        const newScene = cloneDeep(scene);
        let shapes = newScene.objects;
        newScene.objects = [];
        const dimension = cloneDeep(newScene.config.dimensions);
        dimension.width += 2 * dimension.halfBleed;
        dimension.height += 2 * dimension.halfBleed;

        if (newScene.config.mode === 'photo-print') {
          newScene.pages = [];
          newScene.config.viewAllPages = true;
          shapes = shapes.map(({ imageId, rotate, quantity, ...image }) => {
            const position = {
              x: 0,
              y: 0,
              width: rotate ? dimension.height : dimension.width,
              height: rotate ? dimension.width : dimension.height,
            };
            if (newScene.config.borderType === 'border') {
              position.x += dimension.border + dimension.halfBleed;
              position.y += dimension.border + dimension.halfBleed;
              position.width -= 2 * dimension.border + dimension.halfBleed;
              position.height -= 2 * dimension.border + dimension.halfBleed;
            }
            if (newScene.config.borderType === 'retro') {
              position.x += dimension.retroBorder + dimension.halfBleed;
              position.y += dimension.retroBorder + dimension.halfBleed;
              position.width -= 2 * dimension.retroBorder + dimension.halfBleed;
              position.height -= dimension.retroBorder + dimension.retroBottom + dimension.halfBleed;
            }
            return initImage(
              {
                id: uuid(),
                image: { ...image, id: imageId },
                quantity,
                type: 'drop-zone',
                originalAperture: { x: 0, y: 0, width: 0, height: 0 },
                ...position,
              },
              newScene.config,
            );
          });
          const pages = shapes.map(({ quantity, ...shape }) => {
            return {
              id: uuid(),
              shapes: [shape],
              quantity,
              config: {
                orientation: shape.width < shape.height ? 'portrait' : 'landscape',
                pageMode: 'layout',
                layoutId: 'default',
                layoutArea: { x: 0, y: 0, width: 1, height: 1 },
                designId: '',
              },
            };
          });
          newScene.pages = pages;
          return newScene;
        }

        if (newScene.config.orientation === 'portrait') {
          [dimension.width, dimension.height] = [dimension.height, dimension.width];
        }
        dimension.frame = newScene.config.mode === 'canvas' ? dimension.frame : 0;

        if (newScene.config.mode === 'canvas' || newScene.config.mode === 'mask' || newScene.config.mode === 'poster') {
          if (newScene.config.edgeWrap === 'nowrap') {
            shapes = shapes.map((shape) => {
              if (
                shape.type === shapeTypes.dropZone ||
                shape.type === shapeTypes.text ||
                shape.type === shapeTypes.clipart
              ) {
                return { ...shape, x: shape.x + dimension.frame, y: shape.y + dimension.frame };
              }
              if (shape.type === shapeTypes.layout) {
                const allowArea = {
                  x: dimension.frame,
                  y: dimension.frame,
                  width: dimension.width,
                  height: dimension.height,
                };
                return {
                  ...SceneHelper.transformShapeFromPercent({
                    shape,
                    storeState: { scene },
                    customArea: allowArea,
                  }),
                  type: shapeTypes.dropZone,
                };
              }
              return shape;
            });
          } else {
            shapes = shapes.map((shape) => {
              if (shape.type === 'layout') {
                const allowArea = {
                  x: 0,
                  y: 0,
                  width: dimension.width + 2 * dimension.frame,
                  height: dimension.height + 2 * dimension.frame,
                };
                return {
                  ...SceneHelper.transformShapeFromPercent({
                    shape,
                    storeState: { scene },
                    customArea: allowArea,
                  }),
                  type: 'drop-zone',
                };
              }
              return shape;
            });
          }
        }
        newScene.pages = [{ shapes }];
        return newScene;
      },
    },
    {
      version: 3,
      up: (scene) => {
        const newScene = cloneDeep(scene);
        if (!newScene?.assets) {
          newScene.assets = {};
        }
        if (!newScene?.assets?.photos?.length) {
          newScene.assets.photos = [];
        }
        if (newScene?.photos?.length) {
          newScene.assets.photos?.push(...newScene.photos);
          newScene.photos = undefined;
        }
        return newScene;
      },
    },
  ];

  get lastVersion() {
    return maxBy(this.#migrations, 'version').version;
  }

  migrate(scene) {
    const version = scene?.config?.version ?? 0;
    const migrations = this.#migrations.filter((migration) => migration.version > version);
    scene.config.version = this.lastVersion;
    const newScene = migrations.reduce((scene, migration) => migration.up(scene), scene);
    return newScene;
  }
}

export default new Migrations();
