import React, { useCallback } from 'react';
import { useAxisOptions, useCurrentPageId, useEditorSizePx, useIsEditing, useShowArrow } from 'hooks';
import { PageConfigContextProvider } from 'context/pageConfig';
import useUpdateIsEditing from 'hooks/useUpdateIsEditing';
import ArrowMemo from '../Arrow';
import Editor from '../Editor';
import c from './EditorOnePage.module.scss';

const EditorOnePage = () => {
  const currentPageId = useCurrentPageId();
  const [width, height] = useEditorSizePx(currentPageId);
  const isEditing = useIsEditing();
  const setIsEditing = useUpdateIsEditing();
  const showArrow = useShowArrow();
  const { xLabel, yLabel, axisColor } = useAxisOptions();

  const deselectShape = useCallback(() => {
    if (isEditing) {
      setIsEditing(false);
    }
  }, [isEditing, setIsEditing]);
  return (
    <div className={c.containerNewEditor} onClick={deselectShape}>
      <div className={c.newEditor}>
        <div style={{ display: 'flex' }}>
          {showArrow ? <ArrowMemo vertical width={height / 3} text={yLabel} color={axisColor} /> : null}
          <PageConfigContextProvider value={{ pageId: currentPageId, editing: true }}>
            <Editor />
          </PageConfigContextProvider>
        </div>
        {showArrow ? <ArrowMemo width={width / 3} text={xLabel} color={axisColor} /> : null}
      </div>
    </div>
  );
};

export default EditorOnePage;
