import { useEffect, useState } from 'react';

export const useScroll = (gridRef) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);
  useEffect(() => {
    if (gridRef.current) setScrollLeft(gridRef.current.scrollLeft);
    if (gridRef.current)
      setScrollRight(gridRef.current.scrollWidth - gridRef.current.scrollLeft - gridRef.current.clientWidth);
  }, [gridRef]);
  useEffect(
    () =>
      gridRef.current.addEventListener(
        'scroll',
        () => {
          if (gridRef.current) setScrollLeft(gridRef.current.scrollLeft);
          if (gridRef.current)
            setScrollRight(gridRef.current.scrollWidth - gridRef.current.scrollLeft - gridRef.current.clientWidth);
        },
        {
          capture: false,
          passive: true,
        },
      ),
    [gridRef],
  );
  return { scrollLeft: scrollLeft > 1, scrollRight: scrollRight > 1 };
};
export default useScroll;
