import { useState, useEffect } from 'react';

const useStateWithLocalStorage = (localStorageKey, defaultValue) => {
  const cachedHits = JSON.parse(localStorage.getItem(localStorageKey));
  const [value, setValue] = useState(cachedHits || defaultValue);
  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value]);
  return [value, setValue];
};

export default useStateWithLocalStorage;
