import React from 'react';
import { isEqual } from 'lodash';
import Grid from 'components/common/Grid';
import classnames from 'classnames';
import c from './GridSelect.module.scss';

const Select = ({
  options,
  value,
  onChange,
  disabled,
  preserveDescriptionHeight,
  preservePaddingForCheck,
  OptionComponent,
}) => (
  <Grid size="50%">
    {options.map((option) => (
      <Grid.Item
        key={JSON.stringify(option.value)}
        innerClassName={classnames({ checked: isEqual(option.value, value) })}
      >
        <Option
          {...option}
          key={JSON.stringify(option.value)}
          selected={isEqual(option.value, value)}
          onChange={onChange}
          disabled={disabled}
          preserveDescriptionHeight={preserveDescriptionHeight}
          preservePaddingForCheck={preservePaddingForCheck}
          OptionComponent={OptionComponent}
        />
      </Grid.Item>
    ))}
  </Grid>
);

const Option = ({
  value,
  label,
  image,
  selected,
  preserveDescriptionHeight,
  description = preserveDescriptionHeight && ' ',
  onChange,
  preservePaddingForCheck,
  disabled,
  OptionComponent,
}) => (
  <div
    onClick={() => {
      !disabled && onChange(value);
    }}
    className={c.optionItem}
  >
    {image && <img alt="" src={image} />}
    {OptionComponent ? (
      <OptionComponent value={value} selected={selected} />
    ) : (
      <div>
        <label>{label}</label>
        <div className={classnames('small', { 'preserve-padding-for-check': preservePaddingForCheck })}>
          <small>{description}</small>
        </div>
      </div>
    )}
  </div>
);

export default Select;
