import React from 'react';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAdmin, useDeleteEditedShape, useEditedShape } from 'hooks';

export const ShapeDelete = () => {
  const admin = useAdmin();
  const objectEdit = useEditedShape();
  const deleteShape = useDeleteEditedShape();
  if (!admin && objectEdit.lock) return null;
  return (
    <div className="icon-button-container" onClick={deleteShape}>
      <FontAwesomeIcon.Memo icon={faTrashAlt} className="icon-button" />
    </div>
  );
};

export default ShapeDelete;
