import axios from 'axios';
import { get, last } from 'lodash';
import store from 'store';
import config from 'config';
import * as photoActions from 'actions/photos';
import { setDefaultEvents } from 'actions/scene';
import SceneHelper from 'utils/SceneHelper';
import GalleryExplorerHelper from './GalleryExplorerHelper';

const mockPhoto = config.isDevelopment
  ? [
      {
        photo_id: 4052087,
        photo_data_width: 640,
        photo_data_height: 360,
        images: {
          small: 'https://placekitten.com/640/360',
          large: 'https://placekitten.com/640/360',
          original: 'https://placekitten.com/640/360',
        },
      },
      {
        photo_id: 4051937,
        photo_data_width: 640,
        photo_data_height: 360,
        images: {
          small: 'https://placekitten.com/640/360',
          large: 'https://placekitten.com/640/360',
          original: 'https://placekitten.com/640/360',
        },
      },
      {
        photo_id: 4052082,
        photo_data_width: 640,
        photo_data_height: 360,
        images: {
          small: 'https://loremflickr.com/640/360',
          large: 'https://loremflickr.com/640/360',
          original: 'https://loremflickr.com/640/360',
        },
      },
      {
        photo_id: 4052083,
        photo_data_width: 640,
        photo_data_height: 360,
        images: {
          small: 'https://baconmockup.com/640/360',
          large: 'https://baconmockup.com/640/360',
          original: 'https://baconmockup.com/640/360',
        },
      },
      {
        photo_id: 4052085,
        photo_data_width: 640,
        photo_data_height: 360,
        images: {
          small: 'https://placebear.com/640/360',
          large: 'https://placebear.com/640/360',
          original: 'https://placebear.com/640/360',
        },
      },
    ]
  : [];

export class GalleryExplorer extends GalleryExplorerHelper {
  constructor() {
    super();
    this.getData();
    this.getBackground();
    this.getClipart();
  }

  getCurrentFolder = (getStack) => {
    const storeState = store.getState();
    return getStack ? SceneHelper.getStackFolders(storeState) : SceneHelper.getCurrentFolder(storeState);
  }; // currentFolder or stack of folders history

  getData = async (page = this.getCurrentFolder()) => {
    store.dispatch(photoActions.setLoading(true));
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/files/listphotos/${page}/json`, {
      withCredentials: true,
    });

    const folders = get(response, 'data.data.folders', []).map(this.normalizeFolder);
    const photos = get(response, 'data.data.photos', mockPhoto).map(this.normalizePhoto);
    photos.reverse();
    store.dispatch(photoActions.set(photos));
    store.dispatch(photoActions.setFolders(folders));
    store.dispatch(photoActions.setLoading(false));
    return { folders, photos };
  };

  getBackground = () =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/assets?theme_id=1312&type=background`, { withCredentials: true })
      .then((response) => {
        const photos = get(response, 'data', mockPhoto).map(this.normalizeBackground);
        photos.reverse();
        store.dispatch(photoActions.setBackgrounds(photos));
        return { photos };
      });

  getClipart = () =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/assets?theme_id=1312&type=clipart`, { withCredentials: true })
      .then((response) => {
        const photos = get(response, 'data', mockPhoto).map(this.normalizeClipart);
        photos.reverse();
        store.dispatch(photoActions.setCliparts(photos));
        return { photos };
      });

  getEvents = (year) => {
    if (!year) year = new Date().getFullYear();
    return axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/calendar-default-event?year=${year}`, { withCredentials: true })
      .then((response) => {
        const events = get(response, 'data', []);
        events.reverse();
        store.dispatch(setDefaultEvents(events));
        return { events };
      });
  };

  go = async (page) => {
    await this.getData(page);
    store.dispatch(photoActions.setFoldersHistory([...this.getCurrentFolder(true), page]));
  };

  goBack = async () => {
    const prevFolder = [...this.getCurrentFolder(true)];
    prevFolder.pop();
    await this.getData(last(prevFolder) || 0);
    store.dispatch(photoActions.setFoldersHistory(prevFolder));
  };
}

const explorer = new GalleryExplorer();

export default explorer;
