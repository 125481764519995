import React, { useState } from 'react';
import './ActionBarLayout.scss';
import { faSlidersH, faCrop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsCrop from 'components/editors/NewEditor/hooks/useIsCrop';
import ActionsContainer, { SubActionsContainer } from '../components/ActionsContainer';
import SliderStyle from '../components/SliderStyle';

export const ActionBarLayout = ({ hide, shape }) => {
  const { image } = shape;
  const [, toggleIsCrop] = useIsCrop();

  const [showAdjust, setShowAdjust] = useState(false);
  const toggleShowAdjust = () => setShowAdjust(!showAdjust);

  return (
    <>
      <ActionsContainer hide={hide}>
        {image ? (
          <>
            <div className="icon-button-container" onClick={toggleShowAdjust}>
              <FontAwesomeIcon.Memo className="icon-button" icon={faSlidersH} style={{ height: '1em', width: '1em' }} />
              Adjust
            </div>
            <div className="icon-button-container" onClick={toggleIsCrop}>
              <FontAwesomeIcon.Memo className="icon-button" icon={faCrop} style={{ height: '1em', width: '1em' }} />
              Crop
            </div>
          </>
        ) : null}
      </ActionsContainer>
      {image ? (
        <SubActionsContainer show={showAdjust}>
          <div className="edit-contrast">
            <SliderStyle
              key="sliderContrast"
              objectId={shape.id}
              image={image}
              label="Contrast"
              parameter="contrast"
              min={-50}
              max={50}
            />
            <SliderStyle
              key="sliderBrightness"
              objectId={shape.id}
              image={image}
              label="Brightness"
              parameter="brightness"
              min={-50}
              max={50}
            />
            <SliderStyle
              key="sliderSaturation"
              objectId={shape.id}
              image={image}
              label="Saturation"
              parameter="saturation"
              min={-100}
              max={50}
            />
          </div>
        </SubActionsContainer>
      ) : null}
    </>
  );
};

export default ActionBarLayout;
