import React, { useState } from 'react';
import { faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import s from './Collapse.module.scss';

export const Collapse = ({ text, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={s.collapse}>
        <div onClick={() => setIsOpen((isOpen) => !isOpen)} className={s.collapseTitleContainer}>
          <div className={s.collapseTitle}>{text}</div>{' '}
          <FontAwesomeIcon.Memo icon={isOpen ? faCircleArrowLeft : faCircleArrowRight} className={s.iconArrow} />
        </div>
        {isOpen ? children : null}
      </div>
    </>
  );
};

export default Collapse;
