import React from 'react';
import { useDimensions, useBackgroundColor } from 'hooks';

const BackgroundColor = ({ offsetX, offsetY }) => {
  const dimensions = useDimensions();
  const frameColor = useBackgroundColor();
  return (
    <rect
      id="backgroundColor"
      x={offsetX || -(dimensions.originalFrame - dimensions.frame)}
      y={offsetY || -(dimensions.originalFrame - dimensions.frame)}
      width="100%"
      height="100%"
      fill={frameColor}
    />
  );
};

export default BackgroundColor;
