import { min } from 'lodash';
import store from 'store';
import SceneHelper from 'utils/SceneHelper';
import { getCorners, pointProjection, distanceBetweenPoints, checkIsDraw } from './utils';

export const wrapDragFunction =
  (type) =>
  ({ getStartEnd, getNewShape }) =>
  ({ shape, e }) => {
    const allowArea = SceneHelper.calcAllowAreaSync(store.getState(), type);
    const corners = getCorners(shape);
    const { startPoint, endPoint } = getStartEnd(corners);
    const projection = pointProjection(startPoint, endPoint, e);

    const oldDistance = distanceBetweenPoints(startPoint, endPoint);
    let distance = distanceBetweenPoints(startPoint, projection);
    let scaleDistance = distance / oldDistance;
    const newShape = getNewShape({ projection, distance, oldDistance, shape, scaleDistance });

    // if the shape has decreased then return the new shape and do not make additional transformations
    if (scaleDistance <= 1) return newShape;

    // if you can draw this shape, return it
    const [isDraw, outside2] = checkIsDraw({ shape: newShape, allowArea });
    if (isDraw) return newShape;

    let minScale = 1;
    const { topRightPos, bottomLeftPos, topLeftPos, bottomRightPos } = corners;
    const [, outside] = checkIsDraw({ topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, allowArea });
    Object.keys(outside2).forEach((name) => {
      if (outside2[name] > 0) {
        minScale = min([minScale, outside[name] / (outside[name] - outside2[name])]);
      }
    });
    const newProjection = {
      x: endPoint.x + (projection.x - endPoint.x) * minScale,
      y: endPoint.y + (projection.y - endPoint.y) * minScale,
    };
    distance = distanceBetweenPoints(startPoint, newProjection);
    scaleDistance = distance / oldDistance;
    return getNewShape({ projection: newProjection, distance, oldDistance, shape, scaleDistance });
  };
