/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { useScrollToTop, useScroll } from 'hooks';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import c from './Grid.module.scss';

const containerMotionVariants = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const gridItemMotionVariants = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const Grid = forwardRef(({ children, disableItemsTransition, width, height, wrapOnMobile }, ref) => {
  const [gridScrollRef, scrollToTopGrid] = useScrollToTop();

  useImperativeHandle(
    ref,
    () => ({
      resetScroll: () => {
        if (typeof scrollToTopGrid === 'function') scrollToTopGrid();
      },
    }),
    [scrollToTopGrid],
  );

  return (
    <div className={c.container} ref={ref}>
      <motion.div
        initial="hidden"
        variants={containerMotionVariants}
        animate="visible"
        ref={gridScrollRef}
        className={classNames(c.grid, wrapOnMobile && c.gridWrapMobile, 'global-grid')}
      >
        {React.Children.toArray(children).map((child) => {
          const C = disableItemsTransition ? 'div' : motion.div;
          return (
            <C
              key={child.key}
              className={c.gridItem}
              style={{ flexBasis: width, height }}
              variants={disableItemsTransition ? undefined : gridItemMotionVariants}
            >
              {child}
            </C>
          );
        })}
        <ScrollIndicators gridScrollRef={gridScrollRef} />
      </motion.div>
    </div>
  );
});

const scrollIndicatorFontAwesomeIconStyle = { height: '1em', width: '1em' };
const ScrollIndicators = ({ gridScrollRef }) => {
  const { scrollLeft, scrollRight } = useScroll(gridScrollRef);
  return (
    <div className={c.scrollIndicatorContainer}>
      <div className={c.scrollStartIndicator} style={{ display: scrollLeft ? '' : 'none' }}>
        <FontAwesomeIcon.Memo icon={faChevronLeft} style={scrollIndicatorFontAwesomeIconStyle} />
      </div>
      <div className={c.scrollEndIndicator} style={{ display: scrollRight ? '' : 'none' }}>
        <FontAwesomeIcon.Memo icon={faChevronLeft} style={scrollIndicatorFontAwesomeIconStyle} />
      </div>
    </div>
  );
};

const GridItem = ({ children, className, onClick }) => (
  <div className={classNames(c.gridItemInner, className)} onClick={onClick}>
    {children}
  </div>
);

GridItem.variants = gridItemMotionVariants;

Grid.Item = GridItem;

export default Grid;
