import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateTextEditing } from 'actions/scene';

const useUpdateTextEditing = () => {
  const dispatch = useDispatch();
  return useCallback((modifier) => dispatch(updateTextEditing({ textEditing: modifier })), [dispatch]);
};

export default useUpdateTextEditing;
