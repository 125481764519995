import React from 'react';
import Button from 'components/common/Button';
import Lottie from 'react-lottie';
import { warning as warningAnimationData } from '../LottieAnimations';

const warningAnimationOption = (isEdge) => ({
  loop: false,
  autoplay: !isEdge,
  animationData: warningAnimationData,
  rendererSettings: {
    viewBoxSize: '75 75 350 350',
  },
});

const LoadingModal = ({ onClose, title = ' Oops...', message, closeButtonText = 'OK' }) => (
  <div className="processing">
    <div className="modal-header">
      <div className="modal-header-icon">
        <Lottie options={warningAnimationOption()} height={50} width={50} isStopped={false} isPaused={false} />
      </div>
      <div className="modal-header-text">{title}</div>
    </div>
    <div className="modal-text">{message}</div>
    <Button color="azure" rounded size="lg" onClick={onClose}>
      {closeButtonText}
    </Button>
  </div>
);

export default LoadingModal;
