import React from 'react';
import { useViewAllPages } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';

const Logo = () => {
  const viewAllPages = useViewAllPages();
  const isMobile = useIsMobile();
  if (viewAllPages && isMobile) return null;
  return (
    <div
      // href={`${process.env.REACT_APP_SITE_URL}`}
      // title="Pixa Prints"
      // target="_blank"
      // rel="noreferrer"
      className="logo-link"
    >
      <img className="logo" alt={`${process.env.REACT_APP_WEBSITE_NAME}`} src="/icon.svg" />
    </div>
  );
};

export default Logo;
