import React, { useMemo, useState, useEffect, useLayoutEffect } from 'react';
import useResize from 'use-resize';
import { useDispatch } from 'react-redux';
import { updateState } from 'actions/scene';
import NewEditor from 'components/editors/NewEditor';
import useIsMobile from 'hooks/useIsMobile';
import { debounce, get } from 'lodash';
import { useToolbar } from 'hooks';
// import PhotoPrint from 'components/editors/PhotoPrint';
import SwitchView3d from 'components/editors/NewEditor/components/SwitchView3d';
// import { modes } from 'constants/index';
import classNames from 'classnames';
import c from './Scene.module.scss';
import { getSceneSize } from './utils';

// write scene width to the store
const useSceneWidthEffect = (sceneWidthPx, sceneHeightPx, sceneContainerWidthPx, sceneContainerHeightPx) => {
  const dispatch = useDispatch();
  const debouncedUpdateState = React.useMemo(
    () =>
      debounce((params) => {
        dispatch(updateState(params));
      }, 100),
    [dispatch],
  );
  useEffect(() => {
    debouncedUpdateState({
      sceneWidthPx,
      sceneHeightPx,
      sceneContainerWidthPx,
      sceneContainerHeightPx,
    });
  }, [sceneWidthPx, sceneHeightPx, sceneContainerWidthPx, sceneContainerHeightPx, dispatch, debouncedUpdateState]);
};

export const useViewSize = () => {
  const ref = React.useRef();
  const { width: viewportWidth, height: viewportHeight } = useResize();
  const [viewWidth, setViewWidth] = useState(viewportWidth);
  const [viewHeight, setViewHeight] = useState(viewportHeight);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) return;
    const w = ref.current.clientWidth;
    const h = ref.current.clientHeight;
    if (w && w !== viewportWidth) {
      setViewWidth(w);
    }
    if (h && h !== viewportHeight) {
      setViewHeight(h);
    }
  });
  return [ref, viewWidth, viewHeight];
};

const Scene = () => {
  const [containerRef, viewWidth, viewHeight] = useViewSize();
  const isMobile = useIsMobile();
  const [width, height] = useMemo(() => {
    const toolbarWidth = isMobile ? 0 : get(document.querySelector('.toolbar-view'), 'offsetWidth');
    const switchButtonSize = 50;
    const identFromEditor = 50;
    const buttonWidth = isMobile ? 0 : 2 * (identFromEditor + switchButtonSize);
    return getSceneSize({ width: viewWidth - toolbarWidth - buttonWidth, height: viewHeight, isMobile });
  }, [viewWidth, viewHeight, isMobile]);
  useSceneWidthEffect(width, height, viewWidth, viewHeight);
  const { openedPageToolbar } = useToolbar();
  // const centerX = useMemo(() => viewWidth / 2, [viewWidth]);
  // const centerY = useMemo(() => viewHeight / 2, [viewHeight]);
  // const mode = useSelector(
  //   ({
  //     scene: {
  //       config: { mode },
  //     },
  //   }) => mode,
  // );

  return (
    <div ref={containerRef} className={c.container}>
      <SwitchView3d />
      <div className={classNames(c.scene, openedPageToolbar && c.sceneToolbarOpened)}>
        {/* {mode === modes.photoPrint ? (
          <PhotoPrint viewWidth={viewWidth} viewHeight={viewHeight} centerX={centerX} centerY={centerY} />
        ) : null} */}

        <NewEditor />
      </div>
    </div>
  );
};

export default Scene;
