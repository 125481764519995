class DomHelper {
  // Namespaces
  svgNamespace = 'http://www.w3.org/2000/svg';

  xlinkNamespace = 'http://www.w3.org/1999/xlink';

  xhtmlNamespace = 'http://www.w3.org/1999/xhtml';

  // DOM

  isElement = (node) => node.nodeType === Node.ELEMENT_NODE;

  isTextNode = (node) => node.nodeType === Node.TEXT_NODE;

  isCommentNode = (node) => node.nodeType === Node.COMMENT_NODE;

  // SVG

  isSVGElement = (element) => element.namespaceURI === this.svgNamespace;

  isSVGSVGElement = (element) => this.isSVGElement(element) && element.tagName === 'svg';

  isSVGGraphicsElement = (element) => this.isSVGElement(element) && 'getCTM' in element && 'getScreenCTM' in element;

  isSVGGroupElement = (element) => this.isSVGElement(element) && element.tagName === 'g';

  isSVGAnchorElement = (element) => this.isSVGElement(element) && element.tagName === 'a';

  isSVGTextContentElement = (element) => this.isSVGElement(element) && 'textLength' in element;

  isSVGImageElement = (element) => element.tagName === 'image' && this.isSVGElement(element);

  isSVGStyleElement = (element) => element.tagName === 'style' && this.isSVGElement(element);

  // HTML

  isHTMLElement = (element) => element.namespaceURI === this.xhtmlNamespace;

  isHTMLAnchorElement = (element) => element.tagName === 'A' && this.isHTMLElement(element);

  isHTMLLabelElement = (element) => element.tagName === 'LABEL' && this.isHTMLElement(element);

  isHTMLImageElement = (element) => element.tagName === 'IMG' && this.isHTMLElement(element);

  isHTMLInputElement = (element) => element.tagName === 'INPUT' && this.isHTMLElement(element);

  handleTextNode(textNode, lines) {
    if (!textNode.ownerDocument.defaultView) throw new Error("Element's ownerDocument has no defaultView");
    const window = textNode.ownerDocument.defaultView;
    const selection = window.getSelection();

    // save the selected Range to then restore
    const oldRange = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (!selection)
      throw new Error(
        'Could not obtain selection from window. Selection is needed for detecting whitespace collapsing in text.',
      );

    const currentLines = [];
    const lineRange = textNode.ownerDocument.createRange();
    lineRange.setStart(textNode, 0);
    lineRange.setEnd(textNode, 0);
    const addTextSpanForLineRange = () => {
      if (lineRange.collapsed) {
        return;
      }
      let text = '';
      text = lineRange.toString();
      currentLines.push(text);
    };
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        lineRange.setEnd(textNode, lineRange.endOffset + 1);
      } catch (error) {
        if (error.code === DOMException.INDEX_SIZE_ERR) {
          // Reached the end
          addTextSpanForLineRange();
          break;
        }
        throw error;
      }
      // getClientRects() returns one rectangle for each line of a text node.
      const lineRectangles = lineRange.getClientRects();
      // If no lines
      if (!lineRectangles[0]) {
        // Pure whitespace text nodes are collapsed and not rendered.
        return;
      }
      // If two (unique) lines
      // For some reason, Chrome returns 2 identical DOMRects for text with text-overflow: ellipsis.
      if (
        lineRectangles[1] &&
        lineRectangles[0].top !== lineRectangles[1].top &&
        lineRange.endOffset - lineRange.startOffset > 1
      ) {
        // Crossed a line break.
        // Go back one character to select exactly the previous line.
        lineRange.setEnd(textNode, lineRange.endOffset - 1);
        const newLineRectangles = lineRange.getClientRects();
        if (newLineRectangles.length !== lineRectangles.length) {
          // Add <tspan> for exactly that line
          addTextSpanForLineRange();
        }
        // Start on the next line.
        lineRange.setStart(textNode, lineRange.endOffset);
      }
    }
    if (currentLines.length) lines.push(currentLines);
    // restore range
    selection.removeAllRanges();
    oldRange && selection.addRange(oldRange);
  }

  getTextLinesFromDomElement(node) {
    const lines = [];
    if (!node) return [];
    try {
      if (this.isElement(node)) {
        node.childNodes.forEach((child) => {
          lines.push(this.getTextLinesFromDomElement(child));
        });
      } else if (this.isTextNode(node)) {
        this.handleTextNode(node, lines);
      }
    } catch (e) {
      console.error('error:', e.message);
    }
    return lines;
  }
}

export default new DomHelper();
