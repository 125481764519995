import React from 'react';
import { faFont, faBold, faItalic } from '@fortawesome/pro-regular-svg-icons';
import { faBold as faBoldSelect, faItalic as faItalicSelect } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateShape } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectFormatDate from 'components/actionBar/components/SelectFormatDate';
import MultiOptionButton from 'components/common/MultiOptionButton';
import textAligns from 'constants/textAligns';
import CustomAlignLabel from 'components/common/CustomAlignLabel';
import ToolBarColorPicker from '../../components/ToolbarColorPicker';
import FontFamilySelect from '../../components/FontFamilySelect';
import SelectFontSize from '../../components/SelectFontSize';
import Collapse from './Collapse';

export const DayLabelStyle = ({ shape, toolbarRef }) => {
  const onChange = useUpdateShape(shape.id);

  return (
    <Collapse text="Day label">
      <SelectFormatDate
        value={shape.dayFormat}
        onChange={(dayFormat) => {
          onChange({ dayFormat });
        }}
      />
      <div className="text-align-buttons">
        <MultiOptionButton
          options={textAligns}
          onChange={(textAlign) => onChange({ weekdayNameTextAlign: textAlign })}
          value={shape.weekdayNameTextAlign || 'right'}
          LabelComponent={CustomAlignLabel}
        />
      </div>
      <SelectFontSize value={shape.headerFontSize} onChange={(value) => onChange({ headerFontSize: value })} />
      <FontFamilySelect
        initialValue={shape.headerFontFamily}
        onChange={(value) => onChange({ headerFontFamily: value })}
      />
      <div className="icon-button-container" onClick={() => onChange({ headerIsBold: !shape.headerIsBold })}>
        <FontAwesomeIcon.Memo icon={shape.headerIsBold ? faBoldSelect : faBold} className="icon-button" />
      </div>
      <div className="icon-button-container" onClick={() => onChange({ headerIsItalic: !shape.headerIsItalic })}>
        <FontAwesomeIcon.Memo icon={shape.headerIsItalic ? faItalicSelect : faItalic} className="icon-button" />
      </div>
      <ToolBarColorPicker
        initialValue={shape.headerColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ headerColor: value })}
        icon={faFont}
      />
    </Collapse>
  );
};

export default DayLabelStyle;
