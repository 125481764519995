import React from 'react';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getQualityFromQuality } from 'utils/utils';

const ImageQualityToolbar = ({ quality }) => {
  const [qualityText, , isGoodDPI] = getQualityFromQuality(quality);
  return (
    <>
      {!isGoodDPI ? (
        <div>
          <FontAwesomeIcon.Memo
            icon={isGoodDPI ? faCheckCircle : faExclamationTriangle}
            style={{ height: '1em', width: '1em' }}
            color={isGoodDPI ? 'green' : 'red'}
          />
          {` ${qualityText} `}quality
        </div>
      ) : null}
    </>
  );
};

export default ImageQualityToolbar;
