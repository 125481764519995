import React, { useMemo, useRef, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import useIsMobile from 'hooks/useIsMobile';
import { ChromePicker } from 'react-color';
import { ReactComponent as TextColorIcon } from 'assets/text-color-icon.svg';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import c from './ToolbarColorPicker.module.scss';
import DivFullPage from './DivFullPage';

const ToolBarColorPicker = ({
  initialValue,
  onChange: _onChange = () => {},
  onOpen: _onOpen = () => {},
  toolbarRef,
  icon,
  disableAlpha = true,
  disableIconColorMatching = false,
}) => {
  const isMobile = useIsMobile();
  const variantsTab = useMemo(
    () => ({
      open: { opacity: 1, y: 0, height: 'auto', pointerEvents: 'auto' },
      closed: { opacity: 0, display: 'none', pointerEvents: 'none', y: isMobile ? '100%' : '-100%', height: 0 },
    }),
    [isMobile],
  );
  const [value, setValue] = useState(initialValue);
  const onChange = useCallback(
    (e) => {
      const value = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`;
      setValue(value);
      _onChange(value);
    },
    [_onChange],
  );
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(
    (v) => {
      setIsOpen((o) => {
        const newIsOpen = typeof v === 'boolean' ? v : !o;

        if (typeof _onOpen === 'function' && newIsOpen !== o) _onOpen(newIsOpen);
        return newIsOpen;
      });
    },
    [_onOpen],
  );

  const colorPickerRef = useRef(null);
  const closeColorPicker = useCallback(
    (e) => {
      if (isOpen) {
        e.stopPropagation();
        setTimeout(() => toggleOpen(false), 100);
      }
    },
    [isOpen, toggleOpen],
  );
  const picker = (
    <>
      {isOpen ? <DivFullPage onClick={closeColorPicker} /> : null}
      <motion.div
        className={c.colorPickerContainer}
        initial="closed"
        variants={variantsTab}
        animate={isOpen ? 'open' : 'closed'}
        transition={{ duration: 0.15 }}
        ref={colorPickerRef}
      >
        <ChromePicker color={value} onChange={onChange} disableAlpha={disableAlpha} renderers={false} />
      </motion.div>
    </>
  );
  return (
    <div className={c.container}>
      <div className="icon-button-container" onClick={() => toggleOpen()}>
        {icon ? (
          <FontAwesomeIcon.Memo
            icon={icon}
            className="icon-button"
            style={{ color: !disableIconColorMatching ? value : undefined }}
          />
        ) : (
          <TextColorIcon className="icon-button" style={{ color: !disableIconColorMatching ? value : undefined }} />
        )}
      </div>
      {toolbarRef?.current ? createPortal(picker, toolbarRef.current) : picker}
    </div>
  );
};

export default ToolBarColorPicker;
