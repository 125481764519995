import React from 'react';
import c from './ProgressBar.module.scss';

const ProgressBar = ({ value, uploaded }) => {
  if (uploaded) return null;
  if (value >= 99) {
    return <div className="progress-text">Saving...</div>;
  }
  return (
    <div className={c.progress}>
      <span style={{ width: `${value}%` }} />
    </div>
  );
};

const ProgressBarContainer = ({ value, uploaded, alwaysShowWhiteRectangle }) => {
  if (uploaded && !alwaysShowWhiteRectangle) return null;
  return (
    <div className={c.progressBarContainer}>
      <ProgressBar value={value} uploaded={uploaded} />
    </div>
  );
};

export default ProgressBarContainer;
