// @ts-check
import { emptyShape } from 'actions/scene.types';
import { useDimensions } from 'hooks';
import React, { useMemo } from 'react';

/**
 * Renders an image overlay/underlay component.
 *
 * @param {Object} props - The component's props object.
 * @param {string} props.url - The URL of the image to display.
 * @param {number} props.scaleOverlay - The scale factor to apply to the image overlay.
 * @param {boolean} props.isRotateMask - Whether or not to rotate the mask based on the object's orientation.
 * @param {string} props.mask
 * @param {import('actions/scene.types').Shape} [props.shape = {}]
 * @param {Object} [props.style = {}] - An object containing CSS styles to apply to the component.
 *
 * @returns {JSX.Element} - A JSX element representing the rendered image overlay/underlay component.
 */
const ImageOverlayUnderlay = ({ url, scaleOverlay, isRotateMask, mask, shape = emptyShape, style = {} }) => {
  const dimensions = useDimensions();
  const objectX = -(shape.x || 0);
  const objectY = -(shape.y || 0);
  const [x, y, width, height] = useMemo(() => {
    let x = -(dimensions.width * scaleOverlay - dimensions.width) / 2;
    let y = -(dimensions.height * scaleOverlay - dimensions.height) / 2;
    let width = dimensions.width * scaleOverlay;
    let height = dimensions.height * scaleOverlay;
    if (isRotateMask) {
      [x, y] = [y, x];
      [width, height] = [height, width];
    }
    return [x, y, width, height];
  }, [dimensions.height, dimensions.width, isRotateMask, scaleOverlay]);
  const transform = useMemo(
    () =>
      isRotateMask
        ? `rotate(${-shape.rotation || 0}) translate(${objectX} ${objectY}) translate(${
            dimensions.width
          }, 0) rotate(${90}, ${0}, ${0}) `
        : `rotate(${-shape.rotation || 0})  translate(${objectX} ${objectY})`,
    [dimensions.width, isRotateMask, shape.rotation, objectX, objectY],
  );
  return (
    <g mask={mask}>
      <image
        style={{ pointerEvents: 'none', ...style }}
        xlinkHref={url}
        x={x}
        y={y}
        width={width}
        height={height}
        transform={transform}
      />
    </g>
  );
};

export default ImageOverlayUnderlay;
