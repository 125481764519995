import React from 'react';
import { faMaximize } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUpdateFit from 'hooks/useUpdateFit';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import { useSelector } from 'react-redux';
import SceneHelper from 'utils/SceneHelper';
import { modes } from 'constants/index';

const FitImage = ({ shape }) => {
  const showFit = useSelector((storeState) => {
    const dimensions = SceneHelper.getDimensionsSync(storeState);
    const isFullscreen = CalculationShapeHelper.isShapeFullScreen(shape, dimensions);
    const mode = SceneHelper.getMode(storeState);
    return !isFullscreen && mode !== modes.photoPrint;
  });
  const updateFit = useUpdateFit();
  if (!showFit) return null;
  return (
    <div className="icon-button-container" onClick={updateFit}>
      <FontAwesomeIcon.Memo className="icon-button" icon={faMaximize} />
      <p>Fit</p>
    </div>
  );
};

export default FitImage;
