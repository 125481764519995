import { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import useUpdateConfig from 'hooks/useUpdateConfig';
import { modes } from 'constants/index';

const useView3d = () => {
  const updateConfig = useUpdateConfig('view3d');
  const view3d = useSelector(
    ({
      scene: {
        config: { view3d },
      },
    }) => view3d,
    shallowEqual,
  );
  const showSwitchView3d = useSelector(
    ({
      scene: {
        config: { mode, overlay3dUrl },
      },
    }) => (mode === modes.mask || mode === modes.calendar) && overlay3dUrl,
  );
  const setView3d = useCallback(
    (newValue) => {
      updateConfig(newValue);
    },
    [updateConfig],
  );
  const toggleView3d = useCallback(() => {
    setView3d(!view3d);
  }, [view3d, setView3d]);

  useEffect(() => {
    if (!showSwitchView3d && view3d) {
      setView3d(false);
    }
  }, [setView3d, showSwitchView3d, view3d]);
  return [view3d, toggleView3d, setView3d];
};

export default useView3d;
