import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faExpand, faSquare, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import { isEqual, includes, values } from 'lodash';
import PhotoLibrary from 'components/toolbar/PhotoLibrary';
import Customize from 'components/toolbar/Colors';
import Borders from 'components/toolbar/Borders';
import Edges from 'components/toolbar/Edges';
import Sizes from 'components/toolbar/Sizes';
import Events from 'components/toolbar/Events';
import Layouts from 'components/toolbar/Layouts';
import Dev from 'components/toolbar/Dev';
import Backgrounds from 'components/toolbar/Backgrounds';
import { ReactComponent as CustomizeIcon } from 'assets/customize-icon.svg';
import { ReactComponent as DesignsIcon } from 'assets/designs-icon.svg';
import { ReactComponent as PhotosIcon } from 'assets/photos-icon.svg';
import { ReactComponent as EventsIcon } from 'assets/events-icon.svg';
// import TempPages from 'components/toolbar/TempPages';
import { modes } from 'constants/index';
import SceneHelper from 'utils/SceneHelper';
import { faTools } from '@fortawesome/pro-light-svg-icons';
import config from 'config';
import useIsMobile from 'hooks/useIsMobile';
import Toolbar from './Toolbar';
import Designs from '../Designs';
import BlackAndWhite from '../BlackAndWhite';
// import BlackAndWhite from '../BlackAndWhite';

const RenderIfActive = ({ isActive, children }) => (isActive ? children : null);
const withRenderIfActive =
  (C) =>
  ({ isActive, ...props }) =>
    (
      <RenderIfActive isActive={isActive}>
        <C {...props} />
      </RenderIfActive>
    );

export const toolbarItems = ({
  admin,
  mode,
  showLayouts,
  showBackgroundPicker,
  showDesignsTab,
  viewAllPages,
  showSizes,
  isShowEventsTab,
  isMobile,
}) =>
  [
    {
      id: 'photos',
      title: 'Photos',
      icon: PhotosIcon,
      component: withRenderIfActive(PhotoLibrary),
      show: !viewAllPages || mode === modes.photoPrint,
    },
    {
      id: 'events',
      title: 'Events',
      icon: EventsIcon,
      showTitleOnDesktop: true,
      component: withRenderIfActive(Events),
      modsWhenShow: [modes.calendar],
      show: isShowEventsTab,
    },
    {
      id: 'designs',
      title: 'Designs',
      icon: DesignsIcon,
      component: withRenderIfActive(Designs),
      modesWhenShow: [modes.mask, modes.canvas, modes.poster, modes.calendar],
      show: showDesignsTab && !viewAllPages,
    },
    {
      id: 'layouts',
      title: 'Layouts',
      icon: (props) => <FontAwesomeIcon.Memo icon={faColumns} {...props} />,
      component: withRenderIfActive(Layouts),
      modesWhenShow: [modes.mask, modes.canvas, modes.poster, modes.calendar, modes.photoPrint, modes.photoBook],
      show: !viewAllPages && showLayouts,
    },
    {
      id: 'sizes',
      title: 'Sizes',
      icon: (props) => <FontAwesomeIcon.Memo icon={faExpand} {...props} />,
      component: withRenderIfActive(Sizes),
      show: showSizes,
    },
    // {
    //   id: 'pages',
    //   title: 'Pages',
    //   icon: (props) => <FontAwesomeIcon.Memo icon={faWindowRestore} {...props} />,
    //   component: withRenderIfActive(TempPages),
    // },
    {
      id: 'customize',
      title: 'Customise',
      icon: CustomizeIcon,
      component: withRenderIfActive(Customize),
      modesWhenShow: [modes.mask, modes.canvas, modes.poster, modes.calendar, modes.photoBook],
      show: !viewAllPages,
    },
    {
      id: 'backgrounds',
      title: 'Background',
      icon: (props) => <FontAwesomeIcon.Memo icon={faLayerPlus} {...props} />,
      component: withRenderIfActive(Backgrounds),
      modesWhenShow: [modes.photoPrint, modes.photoBook],
      show: (admin || showBackgroundPicker) && (mode === modes.photoBook ? !viewAllPages : viewAllPages),
    },
    {
      id: 'blackAndWhite',
      title: isMobile ? 'B%W' : 'Black and White',
      icon: (props) => <FontAwesomeIcon.Memo icon={faLayerPlus} {...props} />,
      component: withRenderIfActive(BlackAndWhite),
      modesWhenShow: [],
      show: viewAllPages,
    },
    {
      id: 'borders',
      title: 'Borders',
      icon: (props) => <FontAwesomeIcon.Memo icon={faSquare} {...props} />,
      component: withRenderIfActive(Borders),
      modesWhenShow: [modes.photoPrint],
    },
    {
      id: 'edges',
      title: 'Frame',
      icon: (props) => <FontAwesomeIcon.Memo icon={faSquare} {...props} />,
      component: withRenderIfActive(Edges),
      modesWhenShow: [modes.canvas],
    },
    {
      id: 'dev',
      title: 'Developer tools',
      showTitleOnDesktop: true,
      icon: (props) => <FontAwesomeIcon.Memo icon={faTools} {...props} />,
      component: withRenderIfActive(Dev),
      show: mode === modes.photoBook ? !viewAllPages && config.showDevButtons : config.showDevButtons,
    },
  ]
    .map(({ show = true, modesWhenShow = values(modes), ...item }) =>
      includes(modesWhenShow, mode) && show ? item : null,
    )
    .filter(Boolean);

export const useToolbarItems = () => {
  const isMobile = useIsMobile();
  const items = useSelector(
    (storeState) => {
      const {
        config: { designs },
        scene: {
          config: { layouts, mode, showBackgroundPicker, admin, productId },
        },
      } = storeState;
      const viewAllPages = SceneHelper.getViewAllPages(storeState);
      const showSizes = SceneHelper.showSizeTab(storeState);
      const disableLayouts = SceneHelper.getDisableLayouts(storeState);
      const showLayouts = (Array.isArray(layouts) && layouts.length > 1 && !disableLayouts) || admin;
      const isCalendarOnPage = SceneHelper.isCalendarOnPage(storeState);
      const disableEventsTab = SceneHelper.getDisableEventsTab(storeState);
      const isShowEventsTab = isCalendarOnPage && ((!viewAllPages && !disableEventsTab) || admin);
      const showDesignsTab = designs?.filter((design) => design.product_id === productId).length || false;
      return toolbarItems({
        admin,
        mode,
        showLayouts,
        showBackgroundPicker,
        showDesignsTab,
        viewAllPages,
        showSizes,
        isShowEventsTab,
        isMobile,
      });
    },
    (a, b) =>
      isEqual(
        a.map(({ id }) => id),
        b.map(({ id }) => id),
      ),
  );
  return items;
};

export const ToolbarContainer = (props) => {
  const items = useToolbarItems();
  return <Toolbar items={items} {...props} />;
};

export default ToolbarContainer;
