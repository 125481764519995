import React, { useMemo } from 'react';
import { find } from 'lodash';
import Select from 'react-select';
import optionsFontSize from 'constants/fontSizes';
import useIsMobile from 'hooks/useIsMobile';

const SelectFontSize = ({ value, onChange }) => {
  const isMobile = useIsMobile();
  const menuPlacement = useMemo(() => (isMobile ? 'top' : 'bottom'), [isMobile]);
  return (
    <Select
      defaultValue={find(optionsFontSize, { value })}
      menuPlacement={menuPlacement}
      value={find(optionsFontSize, { value })}
      onChange={({ value }) => onChange(value)}
      options={optionsFontSize}
      isSearchable={false}
      classNamePrefix="number-input"
    />
  );
};

export default SelectFontSize;
