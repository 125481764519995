import { createAction, handleActions } from 'redux-actions';

/**
 * @param completed - if true, then we skip onboarding
 * @param step - holds the current step index
 */
const defaultState = {
  completed: true,
  step: 0,
};

const prefix = 'ONBOARDING_';

export const complete = createAction(`${prefix}COMPLETE`);
export const show = createAction(`${prefix}SHOW`);
export const next = createAction(`${prefix}NEXT`);
export const back = createAction(`${prefix}BACK`);

const reducer = handleActions(
  {
    [complete]: (state) => ({ ...state, completed: true, step: 0 }),
    [show]: (state) => ({ ...state, completed: false, step: 0 }),
    [next]: (state) => ({ ...state, step: state.step + 1 }),
  },
  defaultState,
);

export default reducer;
