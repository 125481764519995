import { inchesToMm } from 'utils/number';

export const dimensions = {
  halfBleed: 3, // mm
  frame: 18, // mm
  border: 3, // mm
  retroBorder: 5, // mm
  retroBottom: 15, // mm
  width: inchesToMm(16),
  height: inchesToMm(12),
};
