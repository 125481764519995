export default [
  {
    value: 'EEEEE',
    label: 'M, T...',
  },
  {
    value: 'EEEEEE',
    label: 'Mo, Tu...',
  },
  {
    value: 'EEEE',
    label: 'Monday, Tuesday...',
  },
];
