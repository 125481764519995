import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useAdmin } from 'hooks';
import { get } from 'lodash';

const DesignId = () => {
  const isAdmin = useAdmin();
  const designId = useSelector((state) => get(state, 'scene.config.designId'), shallowEqual);
  if (!isAdmin || !designId) return null;
  return <div className="design-id">Design id: {designId}</div>;
};

export default React.memo(DesignId);
