import React from 'react';
import Button from 'components/common/Button';
import classNames from 'classnames';
import PhotoLibrary from 'components/common/PhotoLibrary';
import c from './SetConfigurationPhotoBook.module.scss';

const SetConfigurationCalendars = ({ onConfirm, confirmText = 'Confirm & Save' }) => {
  return (
    <>
      <div className={classNames('modal-header', c.headerContainer)}>
        <div className="modal-header-text">Select your photo's</div>
      </div>
      <div className={classNames('modal-body', c.bodyContainer)}>
        <PhotoLibrary itemWidth={100} itemHeight={100} maxLine="2" />
        <div className="action-buttons-vertical">
          <Button color="azure" style={{ zIndex: 100 }} rounded size="lg" onClick={() => onConfirm()}>
            {confirmText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SetConfigurationCalendars;
