/* eslint-disable max-classes-per-file */
/* eslint-disable camelcase */
import store from 'store';
import axios from 'axios';
import * as configActions from 'actions/config';
import * as appActions from 'actions/app';
import * as toolbarActions from 'actions/toolbar';
import {
  setScene,
  setDesign,
  initializedFromApi,
  addTextArrToTextObjects,
  addImagemagickToImages,
} from 'actions/scene';
import * as onboardingActions from 'actions/onboarding';
import { find, first, includes, get, cloneDeep, merge, every } from 'lodash';
import qs from 'qs';
import config from 'config';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import { saveState as saveStateGAEvent, order as orderGAEvent } from 'constants/GA';
import { addPrevPhotos } from 'actions/photos';
import LogQueue from 'utils/LogQueue';
import { modes } from 'constants/index';
import { handleChangeStartDate } from 'utils/calendarUtils';
import SceneHelper from 'utils/SceneHelper';
import { handleChangeStartPhotoBook } from 'utils/photoBookUtils';
import { disablePrevent } from '../utils/preventLeave';
import { processSizes, downloadSvg } from './utils';
import Migrations from './migrations';

const throwError = (error, fakeMessage) => {
  error && console.error(error);
  error.fakeMessage = fakeMessage === true ? error.message : fakeMessage;
  throw error;
};
const throwInitError = (error) => throwError(error, 'Error while initializing');
const throwSaveError = (error) => throwError(error, 'Error while saving');
const throwOrderError = (error) => throwError(error, 'Error while ordering');
// const nonCancellable = (error) => { error.cancellable = false; return error; };
const dismissible = (error, onDismiss) => {
  error.dismissible = true;
  error.onDismiss = onDismiss;
  return error;
};

export const getURLParams = () => qs.parse(window.location.search, { ignoreQueryPrefix: true }); // get object with url params
const stringifyURLParams = (params = {}) => qs.stringify(params, { addQueryPrefix: true }); // input: object {key:value}, output: '?key=value'
// eslint-disable-next-line no-restricted-globals
const setURLParams = (params = {}) => history.replaceState('', '', `/${stringifyURLParams(params)}`); // set object {key:value} to url
export const updateURLParams = (params = {}) => setURLParams({ ...getURLParams(), ...params }); // update url params

export const ApiCallsLogQueue = new LogQueue();

class PixaPrints {
  baseUrl = `${config.apiUrl}/api/v2/editor`;

  _call = async (props) => {
    ApiCallsLogQueue.enqueue(props);
    const {
      method = 'get',
      path,
      headers = {},
      params,
      data,
      withCredentials = config.withCredentials,
      ...rest
    } = props;
    !config.isProduction && console.log(`[PixaPrints] Calling ${this.baseUrl}${path}`, { headers, params, data, rest });
    try {
      const res = await axios.request({
        url: `${this.baseUrl}${path}`,
        method,
        headers,
        params,
        data,
        withCredentials,
        timeout: 30000,
        ...rest,
      });
      !config.isProduction && console.log(`[PixaPrints] Success ${this.baseUrl}${path}`, { res });
      return res.data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  };

  /**
   * Get canvas ID param from store, if not exist then from url.
   * @returns {Number} if called with true
   * @returns {String} `/${id}`
   */
  getCanvasId = (returnRawNumber) => {
    const { app } = store.getState();
    const parsedURLQuery = getURLParams(); // http://localhost:3000/?id=14
    const canvasId = app.canvasId || parsedURLQuery.id;
    if (returnRawNumber) return canvasId;
    return canvasId ? `/${canvasId}` : '';
  };

  /**
   * Set canvas id to store
   * @param {Number} id Canvas ID
   * @returns undefined
   */
  setCanvasId = (id) => {
    store.dispatch(appActions.setCanvasId(id));
    updateURLParams({ id });
  };

  /**
   * 1. get category param from url
   * 2. fetch initial data with category param
   * (initial data includes sizes array)
   * 3. push initial data to store
   * 4. set default size
   * @returns undefined
   */
  fetchEditorConfig = async () => {
    // TODO: refactor
    const parsedURLQuery = getURLParams(); // http://localhost:3000/?category=97&mode=canvas
    const { category, product, admin: adminFromQuery = false } = parsedURLQuery;
    const { scene } = store.getState();
    const product_id = get(scene, 'config.productId') || product; // id size
    const category_id = get(scene, 'config.categoryId') || category;

    ReactGA.set({ category_id });

    try {
      if (adminFromQuery) {
        await this._call({ path: '/admin' });
      }

      const params = { category: category_id, product: product_id };
      const isUseLocalApi = JSON.parse(localStorage.getItem('useLocalApi'));
      let data = await this._call({ path: '/initiate', params });
      if (isUseLocalApi) {
        const localApi = JSON.parse(localStorage.getItem('localApi'));
        data = { ...data, ...localApi };
      }
      if (!data.products) throw new Error('Products are empty');
      const { products, enableMultiLayout, layoutMode, defaultBackground, backgroundOptions, designs } = data;

      const modeFromApi = data?.mode || scene?.config?.mode || parsedURLQuery?.mode || modes.canvas;

      const sizes = processSizes(
        products,
        enableMultiLayout,
        layoutMode,
        defaultBackground,
        backgroundOptions,
        modeFromApi,
      ); // some transformation

      store.dispatch(configActions.setSizes(sizes)); // write sizes to config
      store.dispatch(configActions.setDesigns(designs));

      const initializedSceneData = cloneDeep(data);
      initializedSceneData.URLQuery = parsedURLQuery;
      initializedSceneData.designs = store.getState().config.designs;
      const defaultProduct = find(sizes, { default: true }) || first(sizes) || {}; // size marked as default
      const prevProductId = get(store.getState(), 'scene.config.productId'); // size id that was loaded from server
      if (defaultProduct && !prevProductId) {
        // if size was not selected before, then select default size
        initializedSceneData.product = defaultProduct;
        ReactGA.set({ product_id: defaultProduct.id });
      }
      const canvasId = SceneHelper.getCanvasId(store.getState());
      initializedSceneData.canvasId = canvasId;
      store.dispatch(initializedFromApi(initializedSceneData));
      store.dispatch(onboardingActions.complete());
      const storeState = store.getState();
      const mode = SceneHelper.getMode(storeState);
      const admin = SceneHelper.getAdmin(storeState);
      if (mode === modes.calendar && !admin && !canvasId) handleChangeStartDate({ dismissable: false });
      if (mode === modes.photoBook && !admin && !canvasId) handleChangeStartPhotoBook({ dismissable: false });
    } catch (exception) {
      Sentry.captureException(exception);
      throwInitError(exception);
    }
  };

  /**
   * fetch saved scene and push it to store
   * turn on onboarding if param `id` is undefined
   * @param {Number} id canvas id to fetch. if undefined, then it will get canvas id using function
   * @returns server response data or null if param `id` is undefined
   * @throws `Cannot find saved canvas` if server's response is 401
   */
  fetchSavedScene = async (id = this.getCanvasId()) => {
    try {
      if (id) {
        const data = await this._call({ path: `/design${id}` });
        const { data: responseData } = data;
        responseData.id && store.dispatch(appActions.setCanvasId(responseData.id));

        const { assets, ...newScene } = Migrations.migrate(responseData.scene);
        assets?.photos && store.dispatch(addPrevPhotos(assets?.photos));
        responseData.scene && store.dispatch(setScene(newScene));
        // responseData.category_id && store.dispatch(setScene(responseData.scene));
        // responseData.product_id && store.dispatch(setScene(responseData.scene));
        return responseData;
      }
      store.dispatch(onboardingActions.show());
      return null;
    } catch (exception) {
      if (exception && exception.response && exception.response.status === 401) {
        // if id is not found or user does not have rights to see that canvas
        store.dispatch(onboardingActions.show());
        const error = new Error('Cannot find saved design');
        throwError(
          dismissible(error, () => {
            updateURLParams({ id: undefined });
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }),
          true,
        );
      }
      throw exception;
    }
  };

  filterImages(images, imageIds) {
    return images
      .filter((photo) => includes(imageIds, photo.id))
      .map(({ id, src, originalSrc }) => ({ id, src, originalSrc }));
  }

  /**
   * Save scene to Server
   * @returns {Object} - server response
   */
  saveState = async ({ autosave = false } = {}) => {
    try {
      ReactGA.event(saveStateGAEvent);
      await store.dispatch(addTextArrToTextObjects());
      await store.dispatch(addImagemagickToImages());
      const storeState = store.getState();
      const disableAutosave = SceneHelper.isDisableAutosave(storeState);
      if (autosave && disableAutosave) return null;
      const isAllPhotosLoaded = !!every(store.getState().photos.photos, 'uploaded');
      if (!isAllPhotosLoaded) return null;
      const {
        scene,
        photos: { photos: _photos, backgrounds: _backgrounds, clipart: _clipart },
      } = storeState;
      const admin = get(scene, 'config.admin');
      if (admin) {
        const result = await this.saveDesign({ scene }, true);
        return result;
      }
      const id = this.getCanvasId();
      const product_id = get(scene, 'config.productId');
      const productoption_id = get(scene, 'config.productOptionId');
      const category_id = get(scene, 'config.categoryId');
      const imageIds = SceneHelper.getAllImageIds(storeState);
      const photos = this.filterImages(_photos, imageIds);
      const backgrounds = this.filterImages(_backgrounds, imageIds);
      const clipart = this.filterImages(_clipart, imageIds);
      const assets = { photos, backgrounds, clipart };
      const mode = SceneHelper.getMode(storeState);
      const svgs = downloadSvg(scene, 'string');
      const edgeWrap = SceneHelper.getEdgeWrap({ scene });
      const data = {
        scene: { ...merge({}, scene, { config: { edgeWrap } }), assets },
        ...(mode === modes.canvas ? { svg: svgs[0] } : {}),
        product_id,
        category_id,
        productoption_id,
        version: scene.config.version,
      };
      const responseData = await this._call({ method: 'post', path: `/design${id}`, data });
      const { id: newId } = responseData;
      await store.dispatch(toolbarActions.updateLastSaveDate());
      this.setCanvasId(newId);
      return responseData;
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  /**
   * save scene and open URL to order
   */
  orderItem = async () => {
    try {
      ReactGA.event(orderGAEvent);
      const response = await this.saveState();
      if (!response) {
        const error = new Error('failed to save');
        Sentry.captureException(error);
        throwOrderError(error);
      } else if (!response.id) {
        const error = new Error('failed to order');
        Sentry.captureException(error);
        throwOrderError(error);
      } else {
        const { id } = response;
        const url = `${config.apiUrl}/api/v2/canvas/order/${id}`;
        if (!config.shouldOrderNewWindow) {
          disablePrevent();
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwOrderError(dismissible(exception));
    }
  };

  // #region Admin

  /**
   * Save scene to Server
   * @returns {Object} - server response
   */
  saveDesign = async ({ title, description, _scene }, isSave) => {
    try {
      ReactGA.event(saveStateGAEvent);
      await store.dispatch(addTextArrToTextObjects());
      const { scene } = _scene ? { scene: _scene } : store.getState();
      const admin = get(scene, 'config.admin');
      let svg = null;
      if (admin) {
        const product_id = get(scene, 'config.productId');
        const designId = get(scene, 'config.designId');
        const productoption_id = get(scene, 'config.productOptionId');
        const orientation = get(scene, 'config.orientation');
        svg = downloadSvg(scene, 'string');
        const data = {
          objects: { pages: scene.pages },
          product_id,
          productoption_id,
          svg,
          currentBackground: scene.config.currentBackground,
          orientation,
          version: scene.config.version,
        };
        if (title) data.title = title;
        if (description) data.description = description;
        if (scene.config.mode === modes.mask) {
          const maskedSvg = downloadSvg(scene, 'string', true);
          data.maskedSvg = maskedSvg;
        }
        if (designId && isSave) {
          data.id = designId;
        }
        const responseData = await this._call({ method: 'post', path: '/designs', data });
        responseData.pages = responseData.objects?.pages;
        store.dispatch(configActions.addDesign(responseData));
        store.dispatch(setDesign({ ...responseData }));
        return responseData;
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  deleteDesign = async (designId) => {
    try {
      ReactGA.event(saveStateGAEvent);
      const { scene } = store.getState();
      const admin = get(scene, 'config.admin');
      if (admin) {
        const responseData = await this._call({ method: 'post', path: '/designs/delete', data: { designId } });
        store.dispatch(configActions.deleteDesign(designId));
        return responseData;
      }
    } catch (exception) {
      Sentry.captureException(exception);
      throwSaveError(dismissible(exception));
    }
    return null;
  };

  // #endregion Admin
}

export default new PixaPrints();
