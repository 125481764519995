import React from 'react';
import { values, upperCase, upperFirst } from 'lodash';
import { weekStartingConst } from 'constants/index';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import MultiOptionButton from 'components/common/NewMultiOptionButton';
import c from './WeekStartingInput.module.scss';

const WeekStartingLabelComponent = ({ label }) => (
  <div className={c.container}>
    <div className={c.dayIcon}>
      <p>{upperCase(label.slice(0, 3))}</p>
      {/* {upperCase(label.slice(0, 3))} */}
    </div>
    <div>{upperFirst(label)}</div>
  </div>
);

const weekStartingOptions = CalculationShapeHelper.generateOptionsFromConstants(values(weekStartingConst));

const WeekStartingInput = ({ value, onChange }) => (
  <MultiOptionButton
    options={weekStartingOptions}
    value={value}
    onChange={(value) => onChange(value)}
    LabelComponent={WeekStartingLabelComponent}
  />
);

export default WeekStartingInput;
