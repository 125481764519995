import React from 'react';
import { useDimensions, useSizeSVG } from 'hooks';

const SvgContainer = ({ children, style, onClick }) => {
  const dimensions = useDimensions();
  const { width: widthSVG, height: heightSVG } = useSizeSVG();
  return (
    <svg
      width="100%"
      height="100%"
      onClick={onClick}
      style={{ overflow: 'visible', position: 'absolute', top: '0px', left: '0px', ...style }}
      viewBox={`${-(dimensions.originalFrame - dimensions.frame - dimensions.originalFrame / 2)} ${-(
        dimensions.originalFrame - dimensions.frame
      )} ${widthSVG} ${heightSVG}`}
    >
      {children}
    </svg>
  );
};

export default SvgContainer;
