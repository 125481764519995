import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalTypes } from 'constants/index';
import classnames from 'classnames';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import { close as closeModal } from 'actions/modal';
import Modal from '../Modal';
import LoadingModal from './LoadingModal';
import OrderModal from './OrderModal';
import WarningModal from './WarningModal';
import SaveModal from './SaveModal';
import DeleteModal from './DeleteModal';
import SetConfigurationCalendars from './SetConfigurationCalendars';
import ConfirmModal from './ConfirmModal';
import EventModal from './EventModal';
import SetConfigurationPhotoBook from './SetConfigurationPhotoBook';

export const ModalContainer = () => {
  const dispatch = useDispatch();
  const { isOpen, type, modalProps, dismissable } = useSelector(({ modal }) => modal || {});
  return (
    <Modal
      isOpen={isOpen}
      className={classnames({
        'configuration-calendar': type === modalTypes.configurationCalendar,
        configuration_photoBook: type === modalTypes.configurationPhotoBook,
      })}
      dismissable={dismissable}
    >
      {dismissable ? (
        <Button outline rounded className="modal-cancel-button" onClick={() => dispatch(closeModal())}>
          <FontAwesomeIcon.Memo icon={faTimesCircle} className="icon-button" />
        </Button>
      ) : null}
      {type === modalTypes.loading ? <LoadingModal {...modalProps} /> : null}
      {type === modalTypes.order ? <OrderModal {...modalProps} /> : null}
      {type === modalTypes.confirm ? <ConfirmModal {...modalProps} /> : null}
      {type === modalTypes.warning ? <WarningModal {...modalProps} /> : null}
      {type === modalTypes.saveDesign ? <SaveModal {...modalProps} /> : null}
      {type === modalTypes.deleteDesign ? <DeleteModal {...modalProps} /> : null}
      {type === modalTypes.configurationCalendar ? <SetConfigurationCalendars {...modalProps} /> : null}
      {type === modalTypes.configurationPhotoBook ? <SetConfigurationPhotoBook {...modalProps} /> : null}
      {type === modalTypes.event ? <EventModal {...modalProps} /> : null}
    </Modal>
  );
};

export default ModalContainer;
