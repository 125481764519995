import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as toolbarActions from 'actions/toolbar';

const useToolbar = () => {
  const dispatch = useDispatch();
  const open = useCallback(() => dispatch(toolbarActions.open()), [dispatch]);
  const openToolbar = useCallback((page) => dispatch(toolbarActions.openToolbar(page)), [dispatch]);
  const openFooter = useCallback((page) => dispatch(toolbarActions.openFooter(page)), [dispatch]);
  const close = useCallback(() => dispatch(toolbarActions.close()), [dispatch]);
  const closeToolbar = useCallback((page) => dispatch(toolbarActions.closeToolbar(page)), [dispatch]);
  const closeFooter = useCallback((page) => dispatch(toolbarActions.closeFooter(page)), [dispatch]);
  const openPreviousToolbar = useCallback((page) => dispatch(toolbarActions.openPreviousToolbar(page)), [dispatch]);
  const openedPageToolbar = useSelector(({ toolbar }) => toolbar.openedTabToolbar, shallowEqual);
  const openedPageFooter = useSelector(({ toolbar }) => toolbar.openedTabFooter, shallowEqual);
  return {
    close,
    open,
    openToolbar,
    openFooter,
    closeToolbar,
    closeFooter,
    openedPageFooter,
    openedPageToolbar,
    openPreviousToolbar,
  };
};

export default useToolbar;
