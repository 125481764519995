import fastDeepEqual from 'fast-deep-equal/es6/react';
import { modes } from 'constants/index';
import { useSelector } from 'react-redux';
import SceneHelper from 'utils/SceneHelper';

const useIsNextPageButtonDisabled = () =>
  useSelector((storeState) => {
    const mode = SceneHelper.getMode(storeState);
    const nextPage = SceneHelper.getNextPage(storeState);
    const numberOfPages = SceneHelper.getCountPages(storeState);
    const printLimitMax = SceneHelper.getPrintLimitMax(storeState);
    if (nextPage) {
      return false;
    }
    if (mode === modes.photoBook && printLimitMax > numberOfPages) {
      return false;
    }
    return true;
  }, fastDeepEqual);

export default useIsNextPageButtonDisabled;
