import React from 'react';
import { faFont, faBold, faItalic } from '@fortawesome/pro-regular-svg-icons';
import { faBold as faBoldSelect, faItalic as faItalicSelect } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateShape } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolBarColorPicker from '../../components/ToolbarColorPicker';
import FontFamilySelect from '../../components/FontFamilySelect';
import SelectFontSize from '../../components/SelectFontSize';
import Collapse from './Collapse';

export const EventStyle = ({ object, toolbarRef }) => {
  const onChange = useUpdateShape(object.id);
  return (
    <Collapse text="Event">
      <SelectFontSize
        value={object.eventStyle?.fontSize}
        onChange={(value) => onChange({ eventStyle: { fontSize: value } })}
      />
      <FontFamilySelect
        initialValue={object.fontFamily}
        onChange={(value) => onChange({ eventStyle: { fontFamily: value } })}
      />
      <div
        className="icon-button-container"
        onClick={() => onChange({ eventStyle: { isBold: !object.eventStyle?.isBold } })}
      >
        <FontAwesomeIcon.Memo icon={object.eventStyle?.isBold ? faBoldSelect : faBold} className="icon-button" />
      </div>
      <div
        className="icon-button-container"
        onClick={() => onChange({ eventStyle: { isItalic: !object.eventStyle.isItalic } })}
      >
        <FontAwesomeIcon.Memo icon={object.eventStyle?.isItalic ? faItalicSelect : faItalic} className="icon-button" />
      </div>
      <ToolBarColorPicker
        initialValue={object.mainColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ eventStyle: { fill: value } })}
        icon={faFont}
      />
    </Collapse>
  );
};

export default EventStyle;
