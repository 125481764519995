import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  openedTabFooter: 'photos',
  openedTabToolbar: 'photos',
  prevOpenedTabToolbar: 'photos',
};

const prefix = 'TOOLBAR_';

export const open = createAction(`${prefix}OPEN`);
export const openToolbar = createAction(`${prefix}OPEN_ONLY_TOOLBAR`);
export const openFooter = createAction(`${prefix}OPEN_ONLY_FOOTER`);
export const close = createAction(`${prefix}CLOSE`);
export const closeToolbar = createAction(`${prefix}CLOSE_ONLY_TOOLBAR`);
export const openPreviousToolbar = createAction(`${prefix}OPEN_PREVIOUS_TOOLBAR`);
export const closeFooter = createAction(`${prefix}CLOSE_ONLY_FOOTER`);
export const updateLastSaveDate = createAction(`${prefix}UPDATE_LAST_SAVE_DATE`);

const reducer = handleActions(
  {
    [open]: (state, action) => ({
      ...state,
      openedTabToolbar: action.payload,
      openedTabFooter: action.payload,
      prevOpenedTabToolbar: action.payload,
    }),
    [openToolbar]: (state, action) => ({
      ...state,
      openedTabToolbar: action.payload,
      prevOpenedTabToolbar: action.payload,
    }),
    [openFooter]: (state, action) => ({ ...state, openedTabFooter: action.payload }),
    [openPreviousToolbar]: (state) => ({ ...state, openedTabToolbar: state.prevOpenedTabToolbar }),
    [close]: (state) => ({ ...state, openedTabToolbar: null, openedTabFooter: null }),
    [closeToolbar]: (state) => ({ ...state, openedTabToolbar: null }),
    [closeFooter]: (state) => ({ ...state, openedTabFooter: null }),
    [updateLastSaveDate]: (state) => ({ ...state, lastSaveDate: new Date() }),
  },
  defaultState,
);

export default reducer;
