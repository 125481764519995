import React from 'react';
import { usePageConfigContext } from 'context/pageConfig';
import 'react-toggle/style.css';
import { useLoadingPage, useSizeSVG, useUpdatePageId } from 'hooks';
import Loading from '../../../../../../layout/MainLayout/Loading';
import SvgContainer from '../../../SvgContainer/SvgContainer';
import HelmetFonts from '../../../HelmetFonts';
import MaskClipPath from '../../../MaskClipPath';
import Overlay from '../../../Overlay';
import EditorContent from '../../../EditorContent';
import c from './EditorPage.module.scss';
import { SmallLabel } from '../../../PageNavigation';

const EditorPage = () => {
  const pageConfig = usePageConfigContext();
  const updatePageId = useUpdatePageId();
  const loading = useLoadingPage(pageConfig?.pageId);
  const { width: widthSVG, height: heightSVG } = useSizeSVG();
  return (
    <div className={c.editorPageContainer}>
      <SmallLabel />
      <SvgContainer
        onClick={() => updatePageId(pageConfig?.pageId)}
        style={{
          position: 'relative',
          width: `${(widthSVG < heightSVG ? widthSVG / heightSVG : 1) * 100}%`,
        }}
      >
        <HelmetFonts />
        <MaskClipPath />
        <g className="editorContainer">
          <g mask={`url(#mask-clip-path${pageConfig?.pageId})`}>
            <EditorContent onlySvg originalSrc={false} />
          </g>
        </g>
        <Overlay />
      </SvgContainer>
      {loading ? (
        <div
          style={{
            position: 'absolute',
            width: `${(widthSVG < heightSVG ? widthSVG / heightSVG : 1) * 100}%`,
            height: '100%',
          }}
        >
          <Loading loading />
        </div>
      ) : null}
    </div>
  );
};

export default EditorPage;
