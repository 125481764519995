import { createAction, handleActions } from 'redux-actions';

const defaultState = {
  canvasId: null,
};

const prefix = 'APP_';
export const setCanvasId = createAction(`${prefix}SET_CANVAS_ID`);

const reducer = handleActions(
  {
    [setCanvasId]: (state, action) => ({ ...state, canvasId: action.payload }),
  },
  defaultState,
);

export default reducer;
