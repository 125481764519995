import React, { useEffect, useMemo } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import useTextIsEditing from 'components/editors/NewEditor/hooks/useTextEditing';
import { TextForeign } from 'utils/utils';
import { useIsEditing } from 'hooks';

const Text = ({ object, onClick }) => {
  // const refG = useRef();
  const isEditing = useIsEditing();
  useEffect(() => {
    const font = new FontFaceObserver(object.fontFamily);
    font
      .load()
      .then(() => {
        // if (refG.current) {
        // const newTextArr = getTextArr(object);
        // if (!isEqual(newTextArr, textArr)) {
        // setTextArr(newTextArr);
        // updateObject({ id: object.id, textArr: newTextArr });
        // }
        // }
      })
      .catch((e) => console.error('fontLoadError:', e.message));
  }, [object, object.fontFamily, object.isBold, object.text, object.width]);
  const [textEditing] = useTextIsEditing();
  const visibility = useMemo(
    () => (isEditing === object.id && textEditing ? 'hidden' : 'visible'),
    [isEditing, object.id, textEditing],
  );
  // const { x, textAnchor } = alignTextSvg(object.textAlign, object.width);
  return (
    <>
      <TextForeign
        object={object}
        onClick={onClick}
        visibility={visibility}
        className={`text${object.id}`}
        defaultText
      />
    </>
  );
};

export default Text;
