import React, { useCallback, useMemo, useRef } from 'react';
import Slider from 'rc-slider';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useIsMobile from 'hooks/useIsMobile';
import 'rc-slider/assets/index.css';
import { useUpdateShape } from 'hooks';
import c from './ZoomSlider.module.scss';

const useZoomHandler = (objectId, image, objectWidth, objectHeight, ref) => {
  const updateEditorObject = useUpdateShape(objectId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateEditorObjectThrottle = useCallback(
    _.throttle((object) => updateEditorObject(object), 1000 / 40),
    [],
  );
  const newObject = useMemo(
    () => ({ width: 100, height: (objectHeight / objectWidth) * 100 }),
    [objectHeight, objectWidth],
  ); // width and height object to percent
  const callback = useCallback(
    (value) => {
      if (!image) return;
      const newWidth = parseFloat(value, 10);
      const newHeight = (newWidth / image.width) * image.height;
      let newX = newObject.width / 2 - newWidth * image.centerX;
      let newY = newObject.height / 2 - newHeight * image.centerY;

      newX = newX < 0 ? newX : 0;
      newY = newY < 0 ? newY : 0;
      if (newX + newWidth < newObject.width) {
        newX += newObject.width - (newX + newWidth);
      }
      if (newY + newHeight < newObject.height) {
        newY += newObject.height - (newY + newHeight);
      }

      updateEditorObjectThrottle({
        id: objectId,
        image: {
          width: newWidth,
          height: newHeight,
          x: newX,
          y: newY,
        },
      });
    },
    [image, newObject, updateEditorObjectThrottle, objectId],
  );
  ref.current = callback;
  return callback;
};

const calcMarginBottom = (isMobile, sceneContainerHeightPx, editorHeight) => {
  if (isMobile) {
    return 0;
  }
  const zoomSliderHeight = 45;
  const t = parseInt((sceneContainerHeightPx / 2 - editorHeight / 2) / 2 - zoomSliderHeight / 2, 10);
  return t > 0 ? t : 0;
};

const Component = ({ sceneContainerHeightPx, editorHeight, isMobile, sliderMin, value, zoomHandlerRef }) => (
  <div
    className={c.zoomContainer}
    style={{ marginBottom: calcMarginBottom(isMobile, sceneContainerHeightPx, editorHeight) }}
  >
    <div className={c.zoomText}>Zoom</div>
    <div className={c.zoomSliderContainer}>
      <Slider
        min={sliderMin}
        max={sliderMin * 2}
        trackStyle={{
          backgroundColor: isMobile ? 'var(--mobile-zoom-slider-color)' : 'var(--accent-light-color)',
          height: 8,
        }}
        handleStyle={{
          borderWidth: '0px',
          boxShadow: '0 0 5px grey',
          height: 32,
          width: 32,
          marginTop: -12,
          backgroundColor: 'white',
        }}
        railStyle={{ backgroundColor: 'var(--background-grey)', height: 7 }}
        value={value}
        onChange={(a, b, c) => zoomHandlerRef.current(a, b, c)}
      />
    </div>
  </div>
);
const Memoized = React.memo(
  Component,
  (prevProps, nextProps) =>
    prevProps.sceneContainerHeightPx === nextProps.sceneContainerHeightPx &&
    prevProps.editorHeight === nextProps.editorHeight &&
    prevProps.isMobile === nextProps.isMobile &&
    prevProps.sliderMin === nextProps.sliderMin &&
    prevProps.value === nextProps.value &&
    prevProps.zoomHandlerRef === nextProps.zoomHandlerRef,
);

const ZoomSlider = ({ objectId, flat, image, objectWidth, objectHeight, editorHeightPx }) => {
  const isMobile = useIsMobile();
  const zoomHandlerRef = useRef(() => {});
  useZoomHandler(objectId, image, objectWidth, objectHeight, zoomHandlerRef);
  const [, /* sceneContainerWidthPx */ sceneContainerHeightPx] = useSelector(({ scene }) => [
    scene.state.sceneContainerWidthPx,
    scene.state.sceneContainerHeightPx,
  ]);
  const sliderMin = useMemo(() => {
    if (!image || !flat) return null;
    return image.minWidth;
  }, [flat, image]);
  if (!image || !flat) return null;
  return (
    <Memoized
      sceneContainerHeightPx={sceneContainerHeightPx}
      editorHeight={editorHeightPx}
      isMobile={isMobile}
      sliderMin={sliderMin}
      value={image.width}
      zoomHandlerRef={zoomHandlerRef}
    />
  );
};

export default ZoomSlider;
