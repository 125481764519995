import { useContext, createContext } from 'react';

/**
 * @typedef {Object} PageConfigContextValues
 * @property {string} pageId
 * @property {number} pageIndex
 * @property {boolean} editing
 */

const PageConfigContext = createContext({});

export const PageConfigContextProvider = PageConfigContext.Provider;

export function usePageConfigContext() {
  /** @type {PageConfigContextValues} */
  const context = useContext(PageConfigContext);
  return context;
}

export default PageConfigContext;
