import React from 'react';
import { useAdmin, useUpdateShape } from 'hooks';
import VerticalDivider from 'components/actionBar/components/VerticalDivider';
import Collapse from './Collapse';

export const Position = ({ shape }) => {
  const onChange = useUpdateShape(shape.id);
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <Collapse text="Position">
      <div>
        x{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ x: parseFloat(e.target.value) || 0 })}
          value={shape.x}
        />
      </div>
      <div>
        y{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ y: parseFloat(e.target.value) || 0 })}
          value={shape.y}
        />
      </div>
      <div>
        H{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ height: parseFloat(e.target.value) })}
          value={shape.height}
        />
      </div>
      <div>
        W{' '}
        <input
          className="number-input"
          type="number"
          onChange={(e) => onChange({ width: parseFloat(e.target.value) })}
          value={shape.width}
        />
      </div>
      <VerticalDivider />
    </Collapse>
  );
};

export default Position;
