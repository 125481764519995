import React from 'react';
import { useEventsByMonth, useRemoveEventById, useToggleEventActive } from 'hooks';
import { openModal } from 'utils/utils';
import format from 'date-fns/format';
import Button from 'components/common/Button';
import { modalTypes } from 'constants/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt, faPencil } from '@fortawesome/pro-regular-svg-icons';
import c from './Events.module.scss';
import Checkbox from '../../common/Checkbox';
import SwitchEvensInGrid from './components/SwitchEventsInGrid';
import SwitchDisableEventsTab from './components/SwitchDisableEventsTab';

const EventComponent = ({ event }) => {
  const toggleEventActive = useToggleEventActive();
  const handleEditEvent = () => {
    openModal({ type: modalTypes.event, dismissable: true, modalProps: { event } });
  };
  const removeEventById = useRemoveEventById(event.id);
  return (
    <div className={c.eventContainer}>
      <Checkbox
        label={event?.data?.originalCaption || `${event.day} ${event.event}`}
        checked={event.isActive}
        onChange={() => toggleEventActive(event.id)}
      />
      {!event.defaultEvent ? (
        <div className={c.actionContainer}>
          <div className={c.actionButton} onClick={handleEditEvent}>
            <FontAwesomeIcon.Memo icon={faPencil} className="icon-button" />
          </div>
          <div className={c.actionButton} onClick={removeEventById}>
            <FontAwesomeIcon.Memo icon={faTrashAlt} className="icon-button" />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const EventMonthComponent = ({ eventsByMonth }) => {
  const monthName = format(new Date(2000, eventsByMonth.month, 1), 'MMMM');
  return (
    <div className={c.eventsByMonthContainer}>
      <div className={c.monthName}>{monthName}</div>
      <div>
        {eventsByMonth.events.map((event) => {
          return <EventComponent key={event.id} event={event} />;
        })}
      </div>
    </div>
  );
};

const Events = ({ desktop, showTitleOnDesktop, title }) => {
  const eventsByMonth = useEventsByMonth();
  const handleAddEvent = () => {
    openModal({ type: modalTypes.event, dismissable: true });
  };
  return (
    <div className={c.container}>
      {desktop && showTitleOnDesktop ? <h2 className={c.title}>{title}</h2> : null}
      <Button rounded className={c.addEvent} onClick={handleAddEvent}>
        Add <FontAwesomeIcon.Memo className={c.icon} icon={faPlus} />
      </Button>
      <SwitchEvensInGrid className={c.switch} />
      <SwitchDisableEventsTab className={c.switch} />
      <div className={c.eventsContainer}>
        {eventsByMonth.map((eventByMonth) => (
          <EventMonthComponent key={eventByMonth.month} eventsByMonth={eventByMonth} />
        ))}
      </div>
    </div>
  );
};

export default Events;
