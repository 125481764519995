import React from 'react';
import './MainLayout.scss';
import store from 'store';
import classnames from 'classnames';
import Toolbar from 'components/toolbar/Toolbar';
import MobileToolbar from 'components/toolbar/MobileToolbar';
import NavBar from 'components/layout/NavBar';
import { useOnboarding, useViewAllPages } from 'hooks';
import { getURLParams } from 'api/api';
import SceneHelper from 'utils/SceneHelper';
import SVGPageContainer from 'utils/svg-export/components/SVGPage';
import ActionBar from 'components/actionBar/ActionBar';
import { ActionBarTarget } from 'components/actionBar/ActionBarTeleporter';
import Loading from './Loading';

const MainLayout = ({ children, loading, isMobile }) => {
  const [, completed] = useOnboarding();
  const URLParams = getURLParams();
  const viewAllPages = useViewAllPages();
  if (URLParams?.renderSvg) {
    if (loading) return null;
    const storeState = store.getState();
    const pagesIds = SceneHelper.getAllPageIds(storeState) || [];
    return pagesIds.map((pageId) => {
      const pageCoordinates = SceneHelper.getPageCoordinates(storeState, pageId);
      return pageCoordinates.map((coordinates) => <SVGPageContainer options={{ pageId, coordinates }} />);
    });
  }
  return (
    <div className={classnames('main-layout', isMobile && 'mobile')}>
      <NavBar loading={loading} />
      <Loading loading={loading}>
        <ActionBar />
        <ActionBarTarget />
        <div className="content-wrapper">
          {!isMobile ? <Toolbar /> : null}
          <main
            className={`content ${completed && !isMobile ? 'width-without-toolbar' : ''} ${
              viewAllPages ? 'all-page' : ''
            }`}
          >
            {children}
          </main>
        </div>
        {isMobile ? <MobileToolbar /> : null}
      </Loading>
    </div>
  );
};
export default MainLayout;
