import React from 'react';
import { useUpdatePageId } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { usePageConfigContext } from 'context/pageConfig';
import c from './EditIconComponent.module.scss';

const EditIconComponent = () => {
  const updatePageId = useUpdatePageId();
  const pageConfig = usePageConfigContext();
  return (
    <div
      className={c.crop}
      onClick={() => {
        updatePageId(pageConfig.pageId);
      }}
    >
      <FontAwesomeIcon.Memo icon={faPen} className={c.editIcon} />
    </div>
  );
};

export default EditIconComponent;
