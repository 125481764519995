import React, { useCallback } from 'react';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { useAdmin, useSetViewAllPages, useViewAllPages, useToolbar, useMode } from 'hooks';
import { modes } from 'constants/index';

const PreviousStepButton = () => {
  const admin = useAdmin();
  const mode = useMode();
  const setViewAllPages = useSetViewAllPages();
  const { closeToolbar } = useToolbar();
  const viewAllPages = useViewAllPages();
  const onClick = useCallback(() => {
    closeToolbar();
    setViewAllPages(!viewAllPages);
  }, [closeToolbar, setViewAllPages, viewAllPages]);
  if (admin || !viewAllPages || mode === modes.photoPrint) return null;
  return (
    <Button onClick={onClick} className="nav-bar-button" type="button" size="lg" textSize="md" rounded outline>
      <FontAwesomeIcon.Memo icon={faArrowLeft} /> <span>Go back</span>
    </Button>
  );
};

export default PreviousStepButton;
