import React from 'react';
import { isEqual } from 'lodash';
import Grid from 'components/common/NewGrid';
import classnames from 'classnames';
import { TOOLBAR_WIDTH } from 'components/toolbar/Toolbar/Toolbar';
import c from './GridSelect.module.scss';

const GridSelect = ({
  options,
  value,
  onChange,
  disabled,
  OptionComponent,
  width = TOOLBAR_WIDTH / 2,
  height,
  preserveDescriptionHeight,
  wrapOnMobile,
  optionClassName,
}) => (
  <Grid width={width} height={height} wrapOnMobile={wrapOnMobile}>
    {options.map((option) => (
      <Grid.Item
        key={JSON.stringify(option.value)}
        innerClassName={classnames({ checked: isEqual(option.value, value) })}
      >
        <Option
          key={JSON.stringify(option.value)}
          {...option}
          selected={isEqual(option.value, value)}
          onChange={onChange}
          disabled={disabled}
          OptionComponent={OptionComponent}
          className={optionClassName}
          preserveDescriptionHeight={preserveDescriptionHeight}
        />
      </Grid.Item>
    ))}
  </Grid>
);

const Option = ({
  value,
  label,
  image,
  selected,
  description,
  onChange,
  disabled,
  OptionComponent,
  preserveDescriptionHeight,
  className,
}) => (
  <div
    className={classnames(c.gridSelectOption, className, selected && 'checked')}
    onClick={() => {
      !disabled && onChange(value);
    }}
  >
    {image && <img alt="" src={image} />}
    {OptionComponent ? (
      <OptionComponent value={value} selected={selected} />
    ) : (
      <>
        <h3>{label}</h3>
        {description || preserveDescriptionHeight ? <small>{description || ' '}</small> : null}
      </>
    )}
  </div>
);

export default GridSelect;
