import { useCallback } from 'react';
import PixaPrints from 'api';
import { useDispatch } from 'react-redux';
import { onClickNextPage } from 'actions/scene';

const useOnClickNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(async () => {
    dispatch(onClickNextPage());
    await PixaPrints.saveState({ autosave: true });
  }, [dispatch]);
};

export default useOnClickNextPage;
