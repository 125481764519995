import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import classnames from 'classnames';
import c from './Loading.module.scss';

export const Loading = ({ children, loading, className }) => (
  <LoadingOverlay
    active={loading}
    spinner
    text="Loading..."
    fadeSpeed={0}
    className={classnames(c.loadingWrapper, loading && c.loading, className)}
  >
    {' '}
    {children}
  </LoadingOverlay>
);
export default Loading;
