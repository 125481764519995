// @ts-check
/**
 * app.
 * @typedef {Object} AppState
 * @prop {string} canvasId
 */

/**
 * @typedef {Object} OnboardingState
 * @property {boolean} completed
 * @prop {number} step
 */

/**
 * @typedef {Object} ModalState
 * @property {boolean} isOpen
 * @property {any} modalProps
 * @property {boolean} dismissable
 * @prop {string} type
 */

/**
 * @typedef {Object} EventType
 * @property {number} id
 * @property {number} year
 * @property {number} day
 * @property {number} month
 * @property {string} event
 * @property {any} data
 * @property {boolean} defaultEvent
 * @property {boolean} isActive
 */

/**
 * @typedef {Object} DimensionsType
 * @property {number} halfBleed
 * @property {number} frame
 * @property {number} border
 * @property {number} retroBorder
 * @property {number} retroBottom
 * @property {number} width
 * @property {number} height
 * @property {number} [originalFrame]
 */

/**
 * @typedef {Object} LayoutItemType
 * @property {number} height
 * @property {number} width
 * @property {number} x
 * @property {number} y
 */

/**
 * @typedef {Object} LayoutType
 * @property {string} id
 * @property {string} label
 * @property {boolean} default
 * @property {number} margin
 * @property {LayoutItemType[]} layout
 */

/**
 * @typedef {Object} ShapeImage
 * @property {number} id - The unique identifier of the image.
 * @property {number} x - The x coordinate of the top-left corner of the image.
 * @property {number} y - The y coordinate of the top-left corner of the image.
 * @property {number} width - The width of the image.
 * @property {number} height - The height of the image.
 * @property {number} brightness - The brightness of the image.
 * @property {number} contrast - The contrast of the image.
 * @property {number} saturation - The saturation of the image.
 * @property {boolean} initialized - Whether the image has been initialized.
 * @property {number} naturalHeight - The natural height of the image.
 * @property {number} naturalWidth - The natural width of the image.
 * @property {number} centerX - The x coordinate of the center of the image.
 * @property {number} centerY - The y coordinate of the center of the image.
 * @property {number} minWidth - The minimum width of the image.
 * @property {number} minHeight - The minimum height of the image.
 * @property {number} offsetX - The x offset of the image.
 * @property {number} offsetY - The y offset of the image.
 */

/** @typedef {1|2} CalendarType */

/**
 * @typedef {Object} Shape
 * @property {string} [id] - The unique identifier of the shape.
 * @property {number} x - The x coordinate of the top-left corner of the shape.
 * @property {number} y - The y coordinate of the top-left corner of the shape.
 * @property {number} width - The width of the shape.
 * @property {number} height - The height of the shape.
 * @property {string} [type] - The type of the shape.
 * @property {(ShapeImage | null)} [image] - The image associated with the shape, or null if there is no image.
 * @property {number} [rotation] - The rotation angle of the shape in degrees.
 * @property {string} [text]
 * @property {number} [centerX]
 * @property {number} [centerY]
 * @property {number} [minWidth]
 * @property {number} [minHeight]
 * @property {number} [offsetX]
 * @property {number} [offsetY]
 * @property {string} [fontFamily]
 * @property {string} [mainColor]
 * @property {string} [secondaryColor]
 * @property {string} [borderColor]
 * @property {boolean} [isBold]
 * @property {boolean} [isItalic]
 * @property {Object[]} [days]
 * @property {number} [dayFontSize]
 * @property {string} [weekdayNameTextAlign]
 * @property {boolean} [borderTop]
 * @property {string} [dayFormat]
 * @property {number} [headerFontSize]
 * @property {boolean} [headerIsBold]
 * @property {boolean} [headerIsItalic]
 * @property {string} [headerFontFamily]
 * @property {string} [headerColor]
 * @property {CalendarType} [calendarType]
 */

/** @type {Shape} */
export const emptyShape = {
  id: '',
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotation: 0,
  type: '',
  image: undefined,
};

/**
 * @typedef {Object} ScenePage
 * @property {string} id - The unique identifier of the page configuration.
 * @property {number} [quantity]
 * @property {Shape[]} shapes - An array of Shape objects that define the shapes on the page.
 * @property {Object} config - The configuration settings for the page.
 */

/**
 * @typedef {Object} SceneConfigType
 * @property {string} axisColor
 * @property {boolean} addFixedFooterText
 * @property {string} currentPage
 * @property {boolean} viewAllPages
 * @property {boolean} isEditing
 * @prop {string} clippingMaskUrl
 * @prop {string} underlayUrl
 * @property {number} contentOffsetY
 * @property {DimensionsType} dimensions
 * @property {string} edgeWrap
 * @property {string} orientation
 * @property {boolean} initialized
 * @property {string} mode
 * @property {boolean} admin
 * @property {LayoutType[]} layouts
 * @property {string} safeMarginText
 * @property {boolean} isLayoutChanged
 */

/**
 * @typedef {Object} SceneState
 * @prop {(Shape | null)} clipboard
 * @property {SceneConfigType} config
 * @property {any} state
 * @property {ScenePage[]} pages
 * @property {EventType[]} events
 */

/**
 * Main store state.
 * @typedef {Object} StoreState
 * @property {AppState} app
 * @property {OnboardingState} onboarding
 * @property {SceneState} scene
 * @property {Object} photos
 * @property {Object} config
 * @property {Object} toolbar
 * @property {ModalState} modal
 */
