import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import c from './WarningText.module.scss';

const Warning = ({ className, text, type = 'warning' }) => {
  return (
    <div className={classNames(c.warning, className, { [c.danger]: type === 'danger' })}>
      {' '}
      <FontAwesomeIcon.Memo icon={faInfoCircle} /> <p>{text}</p>
    </div>
  );
};

export default Warning;
