import React from 'react';
import { useIsRotateMask, useScaleUnderlay, useUnderlay } from 'hooks';
import 'react-toggle/style.css';
import ImageOverlayUnderlay from './ImageOverlay';

const Underlay = () => {
  const underlayUrl = useUnderlay();
  const scaleUnderlay = useScaleUnderlay();
  const isRotateMask = useIsRotateMask();
  if (!underlayUrl) return null;
  return <ImageOverlayUnderlay url={underlayUrl} scaleOverlay={scaleUnderlay} isRotateMask={isRotateMask} />;
};

export default Underlay;
