import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateIsEditing } from 'actions/scene';

const useUpdateIsEditing = () => {
  const dispatch = useDispatch();
  return useCallback((modifier) => dispatch(updateIsEditing({ isEditing: modifier })), [dispatch]);
};

export default useUpdateIsEditing;
