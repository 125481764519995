import store from 'store';
import PixaPrints from 'api';
import { modalTypes } from 'constants/index';
import { setStartCalendar } from 'actions/scene';
import { openModal } from './utils';
import GalleryExplorer from './gallery-explorer';

export const handleChangeStartDate = ({ dismissable = true } = {}) => {
  const onConfirm = async ({ startFrom, weekStarting }) => {
    store.dispatch(setStartCalendar({ startFrom, weekStarting }));
    await GalleryExplorer.getEvents(startFrom?.startFrom);
    openModal({ type: 'loading', modalProps: { success: true, message: 'Successfully saved' } });
    await PixaPrints.saveState({ autosave: true });
  };
  openModal({
    type: modalTypes.configurationCalendar,
    dismissable,
    modalProps: { onConfirm },
  });
};
