import React, { useCallback, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { setBackground, unsetBackground } from 'actions/scene';
import Grid from 'components/common/NewGrid';
import classnames from 'classnames';
import { useCurrentBackgroundImageId } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import c from '../Backgrounds.module.scss';
import { TOOLBAR_WIDTH } from '../../Toolbar/Toolbar';

const PhotoMemo = memo(function Photo({ id, src, thumbnailSrc, isActive, onClick, ...rest }) {
  const handleClick = useCallback(() => {
    store.dispatch(setBackground({ id, src, thumbnailSrc, ...rest }));
  }, [id, rest, src, thumbnailSrc]);
  return (
    <Grid.Item key={id} className={classnames({ checked: isActive }, c.photoOption)} onClick={onClick || handleClick}>
      <img alt={id} src={thumbnailSrc} />
    </Grid.Item>
  );
});

const UnsetBackgroundMemo = memo(function UnsetBackground({ isActive, thumbnailSrc }) {
  const onClick = useCallback(() => {
    store.dispatch(unsetBackground());
  }, []);
  return (
    <Grid.Item key="none" className={classnames({ checked: isActive }, c.photoOption)} onClick={onClick}>
      <img alt="none" src={thumbnailSrc} />
    </Grid.Item>
  );
});

const BackgroundImages = () => {
  const photos = useSelector(({ photos: { backgrounds } }) => backgrounds || []);
  const currentBackgroundImageId = useCurrentBackgroundImageId();
  const isMobile = useIsMobile();
  const photoSizeWidth = useMemo(() => (isMobile ? 100 : '50%'), [isMobile]);
  const photoSizeHeight = useMemo(() => (isMobile ? 100 : TOOLBAR_WIDTH / 2), [isMobile]);
  return (
    <Grid doNotWrapIntoMotionDiv={photos.length > 20} width={photoSizeWidth} height={photoSizeHeight}>
      <UnsetBackgroundMemo
        isActive={!currentBackgroundImageId}
        id="none"
        thumbnailSrc="https://www.snapfont.com/wp-content/uploads/2020/01/img_0325.jpg"
      />
      {photos.map((photo) => (
        <PhotoMemo key={photo.id} isActive={currentBackgroundImageId === photo.id} photo={photo} {...photo} />
      ))}
    </Grid>
  );
};

export default BackgroundImages;
