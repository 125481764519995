import React from 'react';
import ShapeDelete from '../components/ShapeDelete';
import ActionsContainer from '../components/ActionsContainer';
import OrderObjects from '../components/OrderObjects';

export const ActionBarClipart = ({ hide }) => (
  <ActionsContainer hide={hide}>
    <OrderObjects />
    <ShapeDelete />
  </ActionsContainer>
);

export default ActionBarClipart;
