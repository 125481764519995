import { useSelector } from 'react-redux';
import SceneHelper from 'utils/SceneHelper';

const useOrderObjectsDropdownVisibility = () => {
  const isShow = useSelector((storeState) => {
    const shapes = SceneHelper.getShapesInSelectedPage(storeState);
    if (!shapes?.length) return false;
    if (shapes.filter((shape) => !(SceneHelper.isCalendar(shape) || SceneHelper.isBackground(shape))).length <= 1) {
      return false;
    }
    return true;
  });
  return isShow;
};

export default useOrderObjectsDropdownVisibility;
