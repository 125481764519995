import store from 'store';
import useIsMobile from 'hooks/useIsMobile';
import SceneHelper from 'utils/SceneHelper';
import { usePageConfigContext } from 'context/pageConfig';

const defaultPropsTransformer = ({ isMobile, pageId }) => {
  const storeState = store.getState();
  const {
    scene: {
      config: {
        dimensions: { width },
      },
    },
  } = storeState;
  const sizes = SceneHelper.getEditorSizePx(storeState, pageId);
  if (isMobile) {
    return {
      strokeWidth: 0.1 * (width / sizes[0]),
      rotationHeight: 50 * (width / sizes[0]),
      radius: 8 * (width / sizes[0]),
      hitRadius: 15 * (width / sizes[0]),
      stroke: '#00c3d2',
    };
  }
  return {
    strokeWidth: 0.1 * (width / sizes[0]),
    rotationHeight: 40 * (width / sizes[0]),
    radius: 6 * (width / sizes[0]),
    hitRadius: 6 * (width / sizes[0]),
    stroke: '#00c3d2',
  };
};

export const useDefaultPropsTransformer = () => {
  const isMobile = useIsMobile();
  const pageConfig = usePageConfigContext();
  return defaultPropsTransformer({ isMobile, pageId: pageConfig?.pageId }); // TODO: use selector
};
