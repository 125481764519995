import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from 'components/common/NewGrid';
import classnames from 'classnames';
import { last } from 'lodash';
import { useActivePhotoId } from 'hooks';
import GalleryExplorer from 'utils/gallery-explorer';
import Loading from 'components/layout/MainLayout/Loading';
import './PhotoLibrary.scss';
import Switch from '../Switch';
import BackButton from '../GridBackButton';
import Photo from './components/Photo';
import Folder from './components/Folder';
import ClickImport from './components/ClickImport';
import UploadButton from './components/UploadButton';

const PhotoLibrary = ({ itemWidth, itemHeight, compact, alwaysShowWhiteRectangle, scrollToTop, maxLine }) => {
  const activePhotoIds = useActivePhotoId();
  const isLoading = useSelector(({ photos: { loading } }) => loading);
  const [hideUsedPhotos, setHideUsedPhotos] = useState(false);
  const toggleHideUsedPhotos = useCallback(() => setHideUsedPhotos(!hideUsedPhotos), [hideUsedPhotos]);
  const photos = useSelector(({ photos: { photos } }) => photos);
  const showPhotos = useSelector(({ photos: { showPhotos } }) => showPhotos);
  const folders = useSelector(({ photos: { folders } }) => folders);
  const foldersHistory = useSelector(({ photos: { foldersHistory } }) => foldersHistory);
  const currentFolder = last(foldersHistory);
  const gridRef = useRef();
  const isDataGrid = useMemo(
    () => !folders?.length && !photos?.length && !foldersHistory?.length,
    [folders?.length, foldersHistory?.length, photos?.length],
  );
  useEffect(() => {
    if (typeof scrollToTop === 'function') scrollToTop();
    if (typeof gridRef.current?.resetScroll === 'function') gridRef.current.resetScroll();
  }, [currentFolder, scrollToTop]);
  return (
    <div className={classnames('photo-library', { compact })}>
      <div className={`buttons-photos ${isDataGrid ? 'flex-column' : ''}`}>
        <div className="buttons-container">
          <UploadButton />
        </div>
        <ClickImport hide={!isDataGrid} />
        {!isDataGrid ? (
          <Switch
            label="Hide used photos"
            checked={hideUsedPhotos}
            onChange={toggleHideUsedPhotos}
            className="hide-used-photos"
          />
        ) : null}
      </div>
      <Loading loading={isLoading} className="photoLibrary">
        <Grid ref={gridRef} disableItemsTransition={photos.length > 10} width={itemWidth} height={itemHeight}>
          {foldersHistory?.length ? <BackButton onClick={() => GalleryExplorer.goBack()} /> : null}
          {folders?.map((folder) => (
            <Folder
              key={folder.id}
              scrollToTop={scrollToTop}
              maxLine={maxLine}
              explorer={GalleryExplorer}
              {...folder}
            />
          ))}
          {showPhotos?.map((photo) =>
            hideUsedPhotos && activePhotoIds.includes(photo.id) ? null : (
              <Photo
                key={photo.id}
                alwaysShowWhiteRectangle={alwaysShowWhiteRectangle}
                isActive={activePhotoIds.includes(photo.id)}
                photo={photo}
                {...photo}
              />
            ),
          )}
        </Grid>
      </Loading>
    </div>
  );
};

export default PhotoLibrary;
