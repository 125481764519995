import React from 'react';
import { useToggleBlackAndWhite } from 'hooks';
import VerticalSelect from 'components/common/VerticalSelect';
import { useSelector } from 'react-redux';

const options = [
  {
    value: true,
    label: 'Black and White',
  },
  {
    value: false,
    label: 'Colorful',
  },
];

const Backgrounds = () => {
  const toggleBlackAndWhite = useToggleBlackAndWhite();
  const value = useSelector(
    ({
      scene: {
        config: { blackAndWhite },
      },
    }) => !!blackAndWhite,
  );
  return (
    <div className="borders-container">
      <div className="border-button">
        <VerticalSelect
          options={options}
          onChange={toggleBlackAndWhite}
          value={value}
          onlyInnerDelimiters
          preservePaddingForCheck
        />
      </div>
    </div>
  );
};

export default Backgrounds;
