/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { setDesign } from 'actions/scene';
import Grid from 'components/common/NewGrid';
import PixaPrints from 'api';
import { flatten } from 'lodash';
import { modalTypes, modes } from 'constants/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { close } from 'actions/modal';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import './Designs.scss';
import Button from 'components/common/Button';
import { useAdmin, useMode, usePageDesignId } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import { openModal } from 'utils/utils';

const Photo = ({ id, thumbnailSrc, isActive, design, isAdmin, mode }) => {
  const handleClick = useCallback(() => {
    const setDesignContainer =
      ({ forCurrentPage }) =>
      async () => {
        store.dispatch(setDesign({ ...design, forCurrentPage }));
        await PixaPrints.saveState({ autosave: true });
        store.dispatch(close());
      };
    if (!isAdmin && mode === modes.calendar) {
      openModal({
        type: modalTypes.confirm,
        dismissable: true,
        modalProps: {
          onConfirm: setDesignContainer({ forCurrentPage: true }),
          onClose: setDesignContainer({ forCurrentPage: false }),
          header: 'Apply design',
          text: 'Apply design to current page or apply it to all pages?',
          confirmText: 'Current page',
          cancelText: 'All pages',
        },
      });
    } else {
      setDesignContainer({ forCurrentPage: false })();
    }
  }, [design, isAdmin, mode]);
  return (
    <Grid.Item key={id} innerClassName={classnames({ checked: isActive })}>
      <img alt={id} src={thumbnailSrc} onClick={handleClick} />
    </Grid.Item>
  );
};
const MemoPhoto = React.memo(Photo);

const Designs = () => {
  const designs = useSelector(
    ({
      config: { designs },
      scene: {
        config: { productId },
      },
    }) => designs?.filter((design) => design.product_id === productId) || [],
  );
  const admin = useAdmin();
  const mode = useMode();
  const designId = usePageDesignId();
  const desktopGridItemWidth = designs.length ? '50%' : '100%';
  const mobileGridItemWidth = '33.3%';
  const isMobile = useIsMobile();
  const cleanDesign = () => {
    store.dispatch(setDesign());
  };
  const handleDeleteDesign = useCallback((designId, designTitle) => {
    const onConfirm = () => {
      PixaPrints.deleteDesign(designId);
      openModal({ type: 'loading', modalProps: { success: true, message: 'Design deleted' } });
    };
    openModal({ type: 'delete-design', modalProps: { onConfirm, title: designTitle } });
  }, []);
  const gridItemWidth = isMobile ? mobileGridItemWidth : desktopGridItemWidth;
  return (
    <>
      {admin ? (
        <Button size="lg" color="azure" textSize="md" rounded onClick={cleanDesign}>
          <FontAwesomeIcon.Memo icon={faTrash} style={{ height: '1em', width: '1em', marginRight: '0.8em' }} />
          <span>Reset</span>
        </Button>
      ) : null}
      <Grid
        disableItemsTransition={designs.length > 20}
        width={gridItemWidth}
        height={`calc(${gridItemWidth} * 0.75)`}
        wrapOnMobile
      >
        {flatten(
          designs.map((design) => [
            <MemoPhoto
              key={`photo${design.id}`}
              isAdmin={admin}
              thumbnailSrc={
                design.preview ||
                'https://res.cloudinary.com/dt6b7ywnt/image/upload/v1605623652/No_Image_Available_e5swgh.jpg'
              }
              isActive={designId === design.id}
              design={design}
              mode={mode}
            />,
            admin && (
              <Grid.Item key={`delete${design.id}`}>
                <div style={{ width: '100%' }}>
                  <div className="delete-design">
                    <FontAwesomeIcon.Memo
                      icon={faTrash}
                      style={{ height: '1.5em', width: '1.5em', marginLeft: 'auto' }}
                      onClick={() => handleDeleteDesign(design.id, design.title)}
                    />
                  </div>
                  <div className="design-title">{design.title}</div>
                  <div className="design-description">{design.description}</div>
                  <div className="design-description">ID {design.id}</div>
                </div>
              </Grid.Item>
            ),
          ]),
        ).filter(Boolean)}
      </Grid>
    </>
  );
};

export default Designs;
