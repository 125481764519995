import { useCallback } from 'react';
import store from 'store';
import useUpdateIsEditing from 'hooks/useUpdateIsEditing';
import { useIsEditing } from 'hooks';

export const useSetIsEditing = () => {
  const updateConfig = useUpdateIsEditing();
  const setIsEditing = useCallback(
    (newValue) => {
      updateConfig(newValue);
    },
    [updateConfig],
  );
  return setIsEditing;
};
export const useToggleIsEditing = () => {
  const setIsEditing = useSetIsEditing();
  const toggleIsEditing = useCallback(() => {
    setIsEditing(!store.getState().scene.config.isEditing);
  }, [setIsEditing]);
  return toggleIsEditing;
};

const useIsEditingAll = () => {
  const isEditing = useIsEditing();
  const setIsEditing = useSetIsEditing();
  const toggleIsEditing = useToggleIsEditing();
  return [isEditing, toggleIsEditing, setIsEditing];
};

export default useIsEditingAll;
