import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { orientationOptions } from 'constants/editor';
import GridSelect from 'components/common/NewGridSelect';
import { find } from 'lodash';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import { useIsShowOrientationSwitch, useMode, useSetOrientation, useUpdateProduct, useViewAllPages } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import { modes } from 'constants/index';
import SceneHelper from 'utils/SceneHelper';
import c from './Sizes.module.scss';
import LayoutGutterTypeSelect from '../Layouts/components/LayoutGutterTypeSelect';
// import CoverSelect from '../Layouts/components/CoverSelect';

const Sizes = () => {
  const orientation = useSelector(({ scene }) => scene.config.orientation);
  const showOrientationSwitch = useIsShowOrientationSwitch();
  const setOrientation = useSetOrientation();
  const sizeOptions = useSelector((state) => SceneHelper.getSizes(state));

  const sizeOptionsById = useMemo(
    () => sizeOptions.map(({ id, label, description }) => ({ label, description, value: id })),
    [sizeOptions],
  );
  const sizeId = useSelector(
    ({
      scene: {
        config: { productId },
      },
    }) => productId,
  );
  const updateSize = useUpdateProduct();
  const setSizeId = useCallback(
    (sizeId) => {
      const option = find(sizeOptions, { id: sizeId });
      if (option) {
        updateSize(option);
      } else console.warn('unknown size option with id ', sizeId);
    },
    [sizeOptions, updateSize],
  );
  const mode = useMode();
  const viewAllPages = useViewAllPages();
  const isMobile = useIsMobile();
  return (
    <div className={c.container}>
      {showOrientationSwitch ? (
        <div className={c.orientationButton}>
          <MultiOptionButton options={orientationOptions} onChange={setOrientation} value={orientation} />
        </div>
      ) : null}
      {/* {mode === modes.photoBook && viewAllPages ? <CoverSelect /> : null} */}
      {mode === modes.photoBook && viewAllPages ? <LayoutGutterTypeSelect /> : null}
      <GridSelect
        options={sizeOptionsById}
        onChange={setSizeId}
        value={sizeId}
        width={isMobile ? '33.3%' : '50%'}
        preserveDescriptionHeight
        wrapOnMobile
      />
    </div>
  );
};

export default Sizes;
