import { useToggleIsEditArea } from 'hooks';
import React from 'react';
import ActionsContainer from '../components/ActionsContainer';

export const ActionBarLayoutArea = ({ hide }) => {
  const toggleEditArea = useToggleIsEditArea();
  return <ActionsContainer hide={hide} onClickDone={toggleEditArea} />;
};

export default ActionBarLayoutArea;
