import { combineReducers } from 'redux';
import app from './app';
import onboarding from './onboarding';
import scene from './scene';
import photos from './photos';
import config from './config';
import toolbar from './toolbar';
import modal from './modal';

const rootReducer = combineReducers({
  app,
  onboarding,
  scene,
  photos,
  config,
  toolbar,
  modal,
});

export default rootReducer;
