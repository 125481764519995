import React from 'react';
import { useClippingMask, useDimensions, useIsRotateMask } from 'hooks';
import { usePageConfigContext } from 'context/pageConfig';

const MaskClipPath = ({ width }) => {
  const dimensions = useDimensions();
  const clippingMask = useClippingMask();
  const isRotateMask = useIsRotateMask();
  const pageConfig = usePageConfigContext();
  return (
    <defs>
      <mask id={`mask-clip-path${pageConfig?.pageId}`}>
        <g>
          {clippingMask ? (
            <image
              xlinkHref={clippingMask}
              width="100%"
              height="100%"
              transform={isRotateMask ? `translate(${width}, 0) rotate(90, ${0}, ${0})` : null}
            />
          ) : (
            <rect
              x={dimensions.frame}
              y={dimensions.frame}
              width={dimensions.width}
              height={dimensions.height}
              fill="#fff"
            />
          )}
        </g>
      </mask>
    </defs>
  );
};

export default MaskClipPath;
