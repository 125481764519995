import React, { useCallback, useMemo, useState } from 'react';
import { faSlidersH, faCropAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useIsCrop from 'components/editors/NewEditor/hooks/useIsCrop';
import { useAdmin, useMode, useUpdateShape } from 'hooks';
import { modes } from 'constants/index';
import Switch from 'components/common/Switch';
import SliderStyle from '../components/SliderStyle';
import './ActionBarDropZone.scss';
import ShapeLock from '../components/ShapeLock';
import ShapeDelete from '../components/ShapeDelete';
import ActionsContainer, { SubActionsContainer } from '../components/ActionsContainer';
import FitImage from '../components/FitImage';
import OrderObjects from '../components/OrderObjects';
// eslint-disable-next-line no-unused-vars
import ResetCropping from '../components/ResetCropping';
import Effects from '../components/Effects';

export const ActionBarDropZone = ({ hide, shape }) => {
  const { image } = shape;
  const [, toggleIsCrop] = useIsCrop();
  const onChange = useUpdateShape(shape.id);
  const [showAdjust, setShowAdjust] = useState(false);
  const [showEffects, setShowEffects] = useState(false);
  const mode = useMode();
  const admin = useAdmin();
  const toggleShowAdjust = useCallback(() => setShowAdjust(!showAdjust), [showAdjust]);
  const toggleShowEffects = useCallback(() => setShowEffects(!showEffects), [showEffects]);
  const isImageBlackAndWhite = useMemo(() => {
    return image?.saturation === -100;
  }, [image?.saturation]);

  return (
    <>
      <ActionsContainer hide={hide}>
        {image ? (
          <>
            <div className="icon-button-container" onClick={toggleShowAdjust}>
              <FontAwesomeIcon.Memo className="icon-button" icon={faSlidersH} />
              <p>Adjust</p>
            </div>
            <Effects shape={shape} />
            <FitImage shape={shape} />
            {/* <ResetCropping shape={shape} /> */}
            <div className="icon-button-container" onClick={toggleIsCrop}>
              <FontAwesomeIcon.Memo className="icon-button" icon={faCropAlt} />
              <p>Crop</p>
            </div>
          </>
        ) : null}
        <OrderObjects />
        {admin ? (
          <>
            <div>
              x:
              <input
                className="number-input"
                type="number"
                onChange={(e) => onChange({ x: parseFloat(e.target.value) || 0 })}
                value={shape.x}
              />
            </div>
            <div>
              y:
              <input
                className="number-input"
                type="number"
                onChange={(e) => onChange({ y: parseFloat(e.target.value) || 0 })}
                value={shape.y}
              />
            </div>
            <div>
              H:
              <input
                className="number-input"
                type="number"
                onChange={(e) => onChange({ height: parseFloat(e.target.value) })}
                value={shape.height}
              />
            </div>
            <div>
              W:
              <input
                className="number-input"
                type="number"
                onChange={(e) => onChange({ width: parseFloat(e.target.value) })}
                value={shape.width}
              />
            </div>
          </>
        ) : null}
        <ShapeLock object={shape} />
        {mode !== modes.photoPrint ? <ShapeDelete /> : null}
      </ActionsContainer>
      {image ? (
        <SubActionsContainer show={showAdjust} clickAway={toggleShowAdjust}>
          <div className="edit-contrast">
            <SliderStyle
              key="sliderContrast"
              objectId={shape.id}
              image={image}
              label="Contrast"
              parameter="contrast"
              min={-50}
              max={50}
            />
            <SliderStyle
              key="sliderBrightness"
              objectId={shape.id}
              image={image}
              label="Brightness"
              parameter="brightness"
              min={-50}
              max={50}
            />
            <SliderStyle
              key="sliderSaturation"
              objectId={shape.id}
              image={image}
              label="Saturation"
              parameter="saturation"
              min={-100}
              max={50}
            />
          </div>
        </SubActionsContainer>
      ) : null}
      {image ? (
        <SubActionsContainer show={showEffects} className="effects" clickAway={toggleShowEffects}>
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Switch
              label="Black and White"
              checked={isImageBlackAndWhite}
              onChange={() => onChange({ image: { saturation: isImageBlackAndWhite ? 0 : -100 } })}
            />
          </div>
        </SubActionsContainer>
      ) : null}
    </>
  );
};

export default ActionBarDropZone;
