import React from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useCurrentPageLabel,
  useNextPage,
  usePreviousPage,
  useGoToPreviousPage,
  useViewAllPages,
  usePageIds,
  useMode,
  usePageLabelByPageId,
  usePageLabel,
} from 'hooks';
import classNames from 'classnames';
import { modes } from 'constants/index';
import useIsMobile from 'hooks/useIsMobile';
import c from './PageNavigation.module.scss';
import useNextPageIcon from './hooks/useNextPageIcon';
import useOnClickNextPage from './hooks/useOnClickNextPage';
import useIsNextPageButtonDisabled from './hooks/useIsNextPageButtonDisabled';

export const SmallLabel = () => {
  const mode = useMode();
  const label = usePageLabel();
  if (mode !== modes.photoBook) return null;
  return <div className={classNames(c.smallLabel)}>{label}</div>;
};

const NextPageButton = () => {
  const viewAllPages = useViewAllPages();

  const nextPage = useNextPage();
  const nextPageLabel = usePageLabelByPageId(nextPage?.id);
  const onClick = useOnClickNextPage();

  const icon = useNextPageIcon();
  const isDisabled = useIsNextPageButtonDisabled();
  if (viewAllPages) return null;
  return (
    <div className={classNames(c.pageSwitchBtnContainer, c.next)}>
      <button type="button" disabled={isDisabled} className={c.btn} onClick={onClick}>
        <FontAwesomeIcon.Memo icon={icon} />
      </button>
      <div className={c.btnMonthLabel}>{nextPageLabel}</div>
    </div>
  );
};

const PageNavigation = () => {
  const label = useCurrentPageLabel();
  const isMobile = useIsMobile();
  const viewAllPages = useViewAllPages();
  const previousPage = usePreviousPage();
  const previousPageLabel = usePageLabelByPageId(previousPage?.id);
  const goToPreviousPage = useGoToPreviousPage();
  if (viewAllPages) return null;
  return (
    <>
      {!isMobile ? <SmallLabel /> : null}
      <div className={c.container}>
        <div className={classNames(c.pageSwitchBtnContainer, c.prev)}>
          <button type="button" disabled={!previousPage} className={c.btn} onClick={goToPreviousPage}>
            <FontAwesomeIcon.Memo icon={faChevronRight} />
          </button>
          <div className={c.btnMonthLabel}>{previousPageLabel}</div>
        </div>
        <div className={c.pageLabel}>{label}</div>
        <NextPageButton />
      </div>
    </>
  );
};

const PageNavigationContainer = () => {
  const pageIds = usePageIds();
  const mode = useMode();
  if (pageIds.length < 2 || mode === modes.photoPrint) return null;
  return <PageNavigation />;
};

export default PageNavigationContainer;
