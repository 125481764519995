import React from 'react';
import Grid from 'components/common/NewGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import LinesEllipsis from 'react-lines-ellipsis';

const Folder = React.memo(({ id, name, maxLine = '3', size, explorer }) => (
  <Grid.Item key={id} className="folder" innerClassName="folder" onClick={() => explorer.go(id)} size={size}>
    <FontAwesomeIcon.Memo icon={faFolder} />
    <LinesEllipsis text={name} maxLine={maxLine} ellipsis="..." trimRight basedOn="letters" />
  </Grid.Item>
));

export default Folder;
