/**
 * @typedef {keyof modalTypes} ModalType
 */

const modalTypes = {
  loading: 'loading',
  order: 'order',
  confirm: 'confirm',
  warning: 'warning',
  saveDesign: 'save-design',
  deleteDesign: 'delete-design',
  configurationCalendar: 'configuration-calendar',
  event: 'event',
  configurationPhotoBook: 'configuration-photo-book',
};

export default modalTypes;
