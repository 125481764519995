import React, { useCallback } from 'react';
import { faLock, faLockOpen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useUpdateShape } from 'hooks';
import classNames from 'classnames';
import c from './ShapeLock.module.scss';

export const ShapeLock = ({ object }) => {
  const admin = useSelector(
    ({
      scene: {
        config: { admin },
      },
    }) => admin,
  );
  const updateShape = useUpdateShape();
  const lockObject = useCallback(() => {
    updateShape({ id: object.id, lock: !object.lock });
  }, [object.id, object.lock, updateShape]);
  if (!admin) return null;
  return (
    <div className={classNames(c.shapeLock, 'icon-button-container')} onClick={() => lockObject()}>
      <FontAwesomeIcon.Memo className="icon-button" icon={object.lock ? faLock : faLockOpen} />
      <div>{object.lock ? 'Locked' : 'Unlocked'}</div>
    </div>
  );
};

export default ShapeLock;
