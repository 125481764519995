import React, { memo } from 'react';
import { useDeleteObject, useCopyObject, useBringBackward, useBringForward } from 'hooks';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import { useSetIsEditing } from 'components/editors/NewEditor/hooks/useIsEditing';

const TransformerKeyboardEventHandler = ({ objectId, drawTransform, onDragEnd, shapeTransformer }) => {
  const setIsEditing = useSetIsEditing();
  const deleteObject = useDeleteObject(objectId);
  const bringForward = useBringForward();
  const bringBackward = useBringBackward();
  const copyObject = useCopyObject(objectId);
  return (
    <KeyboardEventHandler
      handleKeys={['esc', 'up', 'right', 'down', 'left', 'ctrl+c', 'meta+c', 'delete', 'meta+up', 'meta+down']}
      onKeyEvent={(key) => {
        try {
          key === 'esc' && setIsEditing(false);
          if (key === 'up') {
            drawTransform({ ...shapeTransformer.current, y: shapeTransformer.current.y - 1 });
            onDragEnd();
          }
          if (key === 'right') {
            drawTransform({ ...shapeTransformer.current, x: shapeTransformer.current.x + 1 });
            onDragEnd();
          }
          if (key === 'down') {
            drawTransform({ ...shapeTransformer.current, y: shapeTransformer.current.y + 1 });
            onDragEnd();
          }
          if (key === 'left') {
            drawTransform({ ...shapeTransformer.current, x: shapeTransformer.current.x - 1 });
            onDragEnd();
          }
          if (key === 'delete') {
            deleteObject();
          }
          if (key === 'ctrl+c' || key === 'meta+c') {
            copyObject();
          }
          if (key === 'meta+up') {
            bringForward();
          }
          if (key === 'meta+down') {
            bringBackward();
          }
        } catch (e) {
          console.error('error:', e.message);
        }
      }}
    />
  );
};

export default memo(TransformerKeyboardEventHandler, fastDeepEqual);
