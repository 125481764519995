import { createAction, handleActions } from 'redux-actions';
import { merge } from 'lodash';

const defaultState = {
  isOpen: null,
  type: '',
  modalProps: {},
  dismissable: false,
};

const prefix = 'MODAL_';
export const close = createAction(`${prefix}CLOSE`);
export const open = createAction(`${prefix}OPEN`);
export const update = createAction(`${prefix}UPDATE`);
export const updateModalProps = createAction(`${prefix}UPDATE_MODAL_PROPS`);
export const setModalProps = createAction(`${prefix}SET_MODAL_PROPS`);

const reducer = handleActions(
  {
    [close]: (state) => ({ ...state, isOpen: null }),
    [open]: (state, action) => ({
      ...state,
      isOpen: true,
      dismissable: action.payload.dismissable || false,
      ...action.payload,
    }),
    [update]: (state, action) => merge({}, state, action.payload),
    [setModalProps]: (state, action) => ({ ...state, modalProps: action.payload }),
    [updateModalProps]: (state, action) => ({ ...state, modalProps: merge({}, state.modalProps, action.payload) }),
  },
  defaultState,
);

export default reducer;
