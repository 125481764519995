export class GalleryExplorerHelper {
  normalizePhoto = ({ photo_id: id, images, photo_data_width: naturalWidth, photo_data_height: naturalHeight }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth,
    naturalHeight,
    width: 100,
    height: (naturalHeight / naturalWidth) * 100,
  });

  normalizeBackground = ({ id, images, width: naturalWidth, height: naturalHeight }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth,
    naturalHeight,
    width: 100,
    height: (naturalHeight / naturalWidth) * 100,
  });

  normalizeClipart = ({ filename: id, images, width, height }) => ({
    id,
    uploaded: true,
    thumbnailSrc: images.small,
    src: images.large,
    originalSrc: images.original,
    imageVariants: images,
    naturalWidth: width,
    naturalHeight: height,
    width: 100,
    height: (height / width) * 100,
  });

  normalizeFolder = ({ album_id: id, album_parentid: parent, album_name: name }) => ({ id, parent, name });
}

export default GalleryExplorerHelper;
