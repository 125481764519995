import React from 'react';
import ActionBarDropZone from 'components/actionBar/ActionBarDropZone';
import useIsCrop from 'components/editors/NewEditor/hooks/useIsCrop';
import ActionBarClipart from 'components/actionBar/ActionBarClipart';
import ActionBarLayout from 'components/actionBar/ActionBarLayout';
import ActionBarText from 'components/actionBar/ActionBarText';
import { useEditedShape, useIsEditArea } from 'hooks';
import SceneHelper from 'utils/SceneHelper';
import ActionBarCalendar from 'components/actionBar/ActionBarCalendar';
import ActionBarLayoutArea from 'components/actionBar/ActionBarLayoutArea';

const ActionBar = () => {
  const [isCrop] = useIsCrop();
  const objectEdit = useEditedShape();
  const isEditLayoutArea = useIsEditArea();
  if (!objectEdit) return null;
  return (
    <>
      {isCrop ? null : (
        <>
          {isEditLayoutArea ? <ActionBarLayoutArea /> : null}
          {SceneHelper.isDropZone(objectEdit) ? <ActionBarDropZone key={objectEdit.id} shape={objectEdit} /> : null}
          {SceneHelper.isClipart(objectEdit) ? <ActionBarClipart key={objectEdit.id} /> : null}
          {SceneHelper.isLayout(objectEdit) ? <ActionBarLayout key={objectEdit.id} shape={objectEdit} /> : null}
          {SceneHelper.isText(objectEdit) ? <ActionBarText key={objectEdit.id} shape={objectEdit} /> : null}
          {SceneHelper.isCalendar(objectEdit) ? <ActionBarCalendar key={objectEdit.id} shape={objectEdit} /> : null}
        </>
      )}
    </>
  );
};

export default ActionBar;
