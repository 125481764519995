import React, { useState, useCallback } from 'react';
import Button from 'components/common/Button';
import TextInput from 'components/form/TextInput';
import DayMonthPicker from 'components/form/DayMonthPicker';
import FormField from 'components/form/FormField';
import store from 'store';
import { useCanAddEvent } from 'hooks/sceneHooks';
import WarningText from 'components/common/WarningText';
import { addEvent, updateEvent } from 'actions/scene';
import getDate from 'date-fns/getDate';
import { openModal } from 'utils/utils';

const EventModal = ({ confirmText = 'Confirm & Save', event }) => {
  const [title, setTitle] = useState(event?.event ?? '');
  const [date, setDate] = useState(event?.day ? new Date(event.year || 2000, event.month, event.day) : new Date());

  const onConfirm = useCallback(
    async ({ title, date }) => {
      const _event = { event: title, month: date.getMonth(), day: getDate(date) };
      store.dispatch(event?.id ? updateEvent({ id: event.id, ..._event }) : addEvent(_event));
      openModal({ type: 'loading', modalProps: { success: true, message: 'Successfully saved' } });
    },
    [event?.id],
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onConfirm({ date, title });
    },
    [date, onConfirm, title],
  );

  const [isCanSave, errors] = useCanAddEvent({ title, date, id: event?.id });

  return (
    <form onSubmit={onSubmit}>
      <div className="modal-header">
        <div className="modal-header-text">{event?.id ? 'Update Event' : 'New Event'}</div>
      </div>
      {errors.map((error) => (
        <WarningText text={error} />
      ))}
      <div className="modal-body">
        <FormField label="Name">
          <TextInput autoFocus label="Name" name="title" value={title} onChange={setTitle} required />
        </FormField>
        <FormField label="Date">
          <DayMonthPicker label="Date" name="date" value={date} onChange={setDate} />
        </FormField>
      </div>

      <div className="action-buttons-vertical">
        <Button color="azure" rounded size="lg" type="submit" disabled={!isCanSave}>
          {confirmText}
        </Button>
      </div>
    </form>
  );
};

export default EventModal;
