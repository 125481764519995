import React, { useMemo } from 'react';
import GridSelect from 'components/common/NewGridSelect';
import { find } from 'lodash';
import { useSetBackgroundColor, useBackgroundColor, useBackgroundOption } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import { TOOLBAR_WIDTH } from '../../Toolbar/Toolbar';
import c from '../Backgrounds.module.scss';

const OptionComponent = ({ value, selected, colors }) => {
  const color = find(colors, { value }) || {};
  return (
    <div className="color-option-container color-picker">
      <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet">
        <circle
          cx="50%"
          cy="50%"
          r={selected ? '42%' : '42%'}
          stroke={selected ? 'var(--accent-light-color)' : '#bebfc1'}
          strokeWidth={selected ? '4' : '2'}
          fill="white"
        />
        <circle
          cx="50%"
          cy="50%"
          r="34%"
          stroke="#bebfc1"
          strokeWidth="0.5"
          fill={color.value}
          style={{ cursor: 'pointer' }}
        />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
  );
};

const BackgroundColors = () => {
  const backgroundOptions = useBackgroundOption();
  const currentBackground = useBackgroundColor();
  const setCurrentBackground = useSetBackgroundColor();
  const isMobile = useIsMobile();
  const photoSizeWidth = useMemo(() => (isMobile ? 100 : '50%'), [isMobile]);
  const photoSizeHeight = useMemo(() => (isMobile ? 100 : TOOLBAR_WIDTH / 2), [isMobile]);
  return (
    <GridSelect
      options={backgroundOptions}
      OptionComponent={(props) => <OptionComponent colors={backgroundOptions} {...props} />}
      onChange={setCurrentBackground}
      value={currentBackground}
      width={photoSizeWidth}
      height={photoSizeHeight}
      optionClassName={c.backgroundSelectOption}
    />
  );
};

export default BackgroundColors;
