import React, { useCallback } from 'react';
import { useToolbar } from 'hooks';
import ImageWithAction from './Image';

const Background = ({ object }) => {
  const { openToolbar } = useToolbar();
  const onClick = useCallback(() => openToolbar('customize'), [openToolbar]);
  return <ImageWithAction object={object} onClick={onClick} />;
};

export default Background;
