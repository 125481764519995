import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './Switch.scss';

const Switch = (props) => {
  const { label, id, labelStyle, className, switchStyle, checked, ...rest } = props;
  return (
    <div className={`switch ${className || ''}`} style={switchStyle}>
      <Toggle id={id} className="toggle-switch" {...rest} checked={!!checked} />
      {label && (
        <label className={id} style={labelStyle}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Switch;
