import React from 'react';
import { faBorderOuter, faBorderTop } from '@fortawesome/pro-regular-svg-icons';
import { faBorderTop as faBorderTopSelect } from '@fortawesome/pro-solid-svg-icons';
import { useUpdateShape } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolBarColorPicker from '../../components/ToolbarColorPicker';
import Collapse from './Collapse';

export const DayTopBorderStyle = ({ shape, toolbarRef }) => {
  const onChange = useUpdateShape(shape.id);

  return (
    <Collapse text="Day top border">
      <div className="icon-button-container" onClick={() => onChange({ borderTop: !shape.borderTop })}>
        <FontAwesomeIcon.Memo icon={shape.borderTop ? faBorderTopSelect : faBorderTop} className="icon-button" />
      </div>

      <ToolBarColorPicker
        initialValue={shape.borderColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ borderColor: value })}
        icon={faBorderOuter}
      />
      <ToolBarColorPicker
        initialValue={shape.secondaryColor}
        toolbarRef={toolbarRef}
        onChange={(value) => onChange({ secondaryColor: value })}
        icon={faBorderOuter}
      />
    </Collapse>
  );
};

export default DayTopBorderStyle;
