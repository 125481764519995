import { faChevronRight, faPlus } from '@fortawesome/pro-regular-svg-icons';
import fastDeepEqual from 'fast-deep-equal/es6/react';
import { modes } from 'constants/index';
import { useSelector } from 'react-redux';
import SceneHelper from 'utils/SceneHelper';

const useNextPageIcon = () =>
  useSelector((storeState) => {
    const mode = SceneHelper.getMode(storeState);
    const nextPage = SceneHelper.getNextPage(storeState);
    if (mode !== modes.photoBook || nextPage) {
      return faChevronRight;
    }
    return faPlus;
  }, fastDeepEqual);

export default useNextPageIcon;
