import React from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBringFront } from '@fortawesome/pro-regular-svg-icons';

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <div className="icon-button-container">
      <FontAwesomeIcon.Memo className="icon-button" icon={faBringFront} />
    </div>
  </components.SingleValue>
);

export default SingleValue;
