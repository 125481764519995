import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { includes, min, pick, isEqual } from 'lodash';
import { useDimensions, useIsLayoutChanged, useUpdateShape } from 'hooks';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import SceneHelper from 'utils/SceneHelper';
import useUpdateIsEditing from 'hooks/useUpdateIsEditing';
import store from 'store';
import { modalTypes, shapeTypes } from 'constants/index';
import { openModal } from 'utils/utils';
import { close } from 'actions/modal';
import useAllowArea from 'hooks/useAllowArea';
import { usePageConfigContext } from 'context/pageConfig';
import { getCorners, checkIsDraw, handleDragTransformer, redrawTransformer, handleRotateTransformer } from './utils';
import useHandleDragCorner from './useHandleDragCorner';
import { useDefaultPropsTransformer } from './constants';
import styles from './Transformer.module.scss';
import Circle from './Circle';
import { wrapDragFunction } from './wrapDragFunction';
import TransformerKeyboardEventHandler from './TransformerKeyboardEventHandler';

const dragFunction = wrapDragFunction();

const dragTopLeftCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.bottomRightPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ shape, scaleDistance, projection }) => ({
    ...shape,
    width: shape.width * scaleDistance,
    height: shape.height * scaleDistance,
    x: projection.x,
    y: projection.y,
  }),
});

const dragTopRightCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.bottomLeftPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ shape, scaleDistance, projection }) => ({
    ...shape,
    width: shape.width * scaleDistance,
    height: shape.height * scaleDistance,
    x: projection.x,
    y: projection.y,
  }),
});

const dragBottomRightCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.topLeftPos, endPoint: corners.bottomRightPos }),
  getNewShape: ({ shape, scaleDistance }) => ({
    ...shape,
    width: shape.width * scaleDistance,
    height: shape.height * scaleDistance,
  }),
});

const dragBottomLeftCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.topRightPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ shape, scaleDistance, projection }) => ({
    ...shape,
    width: shape.width * scaleDistance,
    height: shape.height * scaleDistance,
    x: projection.x,
    y: projection.y,
  }),
});

const dragCenterRightCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.centerLeft, endPoint: corners.centerRight }),
  getNewShape: ({ distance, shape }) => ({ ...shape, width: distance }),
});

const dragCenterLeftCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.topRightPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ projection, distance, shape }) => ({ ...shape, x: projection.x, y: projection.y, width: distance }),
});

const dragTopCenterCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.bottomLeftPos, endPoint: corners.topLeftPos }),
  getNewShape: ({ projection, distance, shape }) => ({ ...shape, x: projection.x, y: projection.y, height: distance }),
});

const dragBottomCenterCorner = dragFunction({
  getStartEnd: (corners) => ({ startPoint: corners.topLeftPos, endPoint: corners.bottomLeftPos }),
  getNewShape: ({ distance, shape }) => ({ ...shape, height: distance }),
});

const Transformer = ({ object, draggable, rotateEnabled, enabledAnchors, editable, fill = '#ffffff00', onClick }) => {
  const shapeTransformer = useRef();
  const dimensions = useDimensions();
  const isLayoutChanged = useIsLayoutChanged();
  const setIsEditing = useUpdateIsEditing();
  const { strokeWidth, stroke, rotationHeight } = useDefaultPropsTransformer();
  const pageConfig = usePageConfigContext();
  const allowArea = useAllowArea(shapeTypes.dropZone, pageConfig.pageId);
  const editorWidth = dimensions.width + 2 * dimensions.frame;
  const editorHeight = dimensions.height + 2 * dimensions.frame;
  const onChange = useUpdateShape(object.id);
  const onDragEnd = useCallback(() => {
    if (shapeTransformer.current) {
      const fields = ['x', 'y', 'width', 'height'];
      console.log(isLayoutChanged, pick(object, fields), pick(shapeTransformer.current, fields));
      if (!isLayoutChanged && !isEqual(pick(object, fields), pick(shapeTransformer.current, fields))) {
        openModal({
          type: modalTypes.confirm,
          modalProps: {
            onConfirm: () => {
              const { x, y, width, height, rotation } = shapeTransformer.current;
              onChange({ x, y, width, height, rotation });
              store.dispatch(close());
            },
            header: 'Confirm Image Placement',
            text: `Before placing the order, please ensure that the inserted image completely fills the template and is correctly positioned. Proceed only if the image fits perfectly.`,
            confirmText: 'Confirm',
            hideCancelButton: true,
          },
        });
      }
      const { x, y, width, height, rotation } = shapeTransformer.current;
      onChange({ x, y, width, height, rotation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLayoutChanged, onChange, object.x, object.y, object.width, object.height]);

  useEffect(() => {
    const imageQualityIndicator = d3.selectAll(`.image-quality-indicator${object.id}`);
    imageQualityIndicator.attr('opacity', 0);
    return () => {
      imageQualityIndicator.attr('opacity', 1);
    };
  });

  const redrawImage = useCallback(
    (shape) => {
      if (SceneHelper.isCalendar(object)) {
        const calendar = d3.selectAll(`.calendar${object.id}`);
        calendar.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
        const svgDragPhoto = d3.selectAll(`.calendar${object.id} svg`);
        svgDragPhoto.attr('width', shape.width);
        svgDragPhoto.attr('height', shape.height);
      } else {
        const gDragAndDrop = d3.selectAll(`.rect${object.id}`);
        gDragAndDrop.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
        const rectDragPhoto = d3.selectAll(`.rect${object.id} rect`);
        rectDragPhoto.attr('height', shape.height);
        rectDragPhoto.attr('width', shape.width);
        let size = min([dimensions.width, dimensions.height]);
        if (size / shape.height > 7) {
          size = 7 * shape.height;
        }
        if (size / shape.width > 2) {
          size = 2 * shape.width;
        }
        const widthIcon = size / 20;
        const fontSize = size / 20;
        const textDragPhoto = d3.selectAll(`.rect${object.id} text`);
        textDragPhoto.attr('x', shape.width / 2);
        textDragPhoto.attr('y', shape.height / 2);
        textDragPhoto.attr('font-size', fontSize);
        const svgDragPhoto = d3.selectAll(`.rect${object.id} svg`);
        svgDragPhoto.attr('x', shape.width * 0.5 - widthIcon / 2);
        svgDragPhoto.attr('y', shape.height * 0.5 - widthIcon);
        svgDragPhoto.attr('height', widthIcon);
        svgDragPhoto.attr('width', widthIcon);
        if (object.image) {
          const image = d3.selectAll(`.image${object.id}`);
          const oldRatio = object.width / object.height;
          const newRatio = shape.width / shape.height;
          if (Math.abs(oldRatio - newRatio) > 0.0000005) {
            const newPosition = CalculationShapeHelper.fitImageToShape(shape, object.image, { usePercent: false });
            image.attr('width', newPosition.width);
            image.attr('height', newPosition.height);
            image.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
            image.attr('x', newPosition.x);
            image.attr('y', newPosition.y);
          } else {
            const imageWidth = (shape.width * object.image.width) / 100;
            const imageHeight = (shape.width * object.image.height) / 100;
            image.attr('width', imageWidth);
            image.attr('height', imageHeight);
            image.attr('transform', `translate(${shape.x}, ${shape.y}) rotate(${shape.rotation})`);
            image.attr('x', -(object.image.centerX * imageWidth - shape.width / 2));
            image.attr('y', -(object.image.centerY * imageHeight - shape.height / 2));
          }
          const clipPath = d3.selectAll(`#canvas-clip${object.id} > rect`);
          clipPath.attr('width', shape.width);
          clipPath.attr('height', shape.height);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dimensions.height, dimensions.width, object.height, object.id, object.image, object.width, object.type],
  );

  const drawTransform = useCallback(
    (shape) => {
      const {
        topRightPos,
        bottomLeftPos,
        topLeftPos,
        bottomRightPos,
        rotationPos,
        topCenter,
        centerLeft,
        centerRight,
        bottomCenter,
      } = getCorners({ ...shape, rotationHeight });
      const [draw] = checkIsDraw({ topRightPos, bottomLeftPos, topLeftPos, bottomRightPos, allowArea });
      if (draw) {
        shapeTransformer.current = shape;
        redrawTransformer(object.id, shape, {
          topRightPos,
          bottomLeftPos,
          topLeftPos,
          bottomRightPos,
          rotationPos,
          topCenter,
          centerLeft,
          centerRight,
          bottomCenter,
        });
        redrawImage(shape);
      }
    },
    [allowArea, object.id, redrawImage, rotationHeight],
  );

  useEffect(() => {
    const shape = {
      x: object.x,
      y: object.y,
      width: object.width,
      height: object.height,
      rotation: object.rotation || 0,
    };
    editable && drawTransform(shape);
  }, [drawTransform, editable, object.height, object.rotation, object.width, object.x, object.y]);
  const handleDragCorner = useHandleDragCorner(drawTransform, onDragEnd);
  useEffect(() => {
    draggable &&
      handleDragTransformer(`#transformer${object.id}`, shapeTransformer.current, onDragEnd, drawTransform, allowArea);
    const {
      topRightPos,
      bottomLeftPos,
      topLeftPos,
      bottomRightPos,
      rotationPos,
      topCenter,
      centerLeft,
      centerRight,
      bottomCenter,
    } = getCorners({ ...shapeTransformer.current, rotationHeight });
    rotateEnabled &&
      handleRotateTransformer(`#rotate${object.id}`, shapeTransformer.current, drawTransform, onDragEnd, rotationPos);
    if (editable) {
      includes(enabledAnchors, 'top-left') &&
        handleDragCorner(`#topLeft${object.id}`, dragTopLeftCorner, shapeTransformer.current, topLeftPos);
      includes(enabledAnchors, 'top-right') &&
        handleDragCorner(`#topRight${object.id}`, dragTopRightCorner, shapeTransformer.current, topRightPos);
      includes(enabledAnchors, 'bottom-right') &&
        handleDragCorner(`#bottomRight${object.id}`, dragBottomRightCorner, shapeTransformer.current, bottomRightPos);
      includes(enabledAnchors, 'bottom-left') &&
        handleDragCorner(`#bottomLeft${object.id}`, dragBottomLeftCorner, shapeTransformer.current, bottomLeftPos);
      includes(enabledAnchors, 'center-right') &&
        handleDragCorner(`#centerRight${object.id}`, dragCenterRightCorner, shapeTransformer.current, centerRight);
      includes(enabledAnchors, 'center-left') &&
        handleDragCorner(`#centerLeft${object.id}`, dragCenterLeftCorner, shapeTransformer.current, centerLeft);
      includes(enabledAnchors, 'top-center') &&
        handleDragCorner(`#topCenter${object.id}`, dragTopCenterCorner, shapeTransformer.current, topCenter);
      includes(enabledAnchors, 'bottom-center') &&
        handleDragCorner(`#bottomCenter${object.id}`, dragBottomCenterCorner, shapeTransformer.current, bottomCenter);
    }
    d3.select(`#transformer${object.id}`)
      .on('mouseover', () => {
        if (document.body.style.cursor === 'default') document.body.style.cursor = draggable ? 'grab' : 'pointer';
      })
      .on('mouseleave', () => {
        document.body.style.cursor = 'default';
      })
      .on('click', () => {
        document.body.style.cursor = 'default';
        onClick ? onClick() : setIsEditing(false);
      });
  }, [
    allowArea,
    draggable,
    drawTransform,
    editable,
    editorHeight,
    editorWidth,
    enabledAnchors,
    handleDragCorner,
    object,
    onChange,
    onClick,
    onDragEnd,
    rotateEnabled,
    rotationHeight,
    setIsEditing,
  ]);
  const { topRightPos, bottomLeftPos, bottomRightPos, rotationPos, topCenter, bottomCenter, centerLeft, centerRight } =
    useMemo(() => getCorners({ ...object, rotationHeight }), [object, rotationHeight]);
  return (
    <g>
      <TransformerKeyboardEventHandler
        objectId={object.id}
        drawTransform={drawTransform}
        onDragEnd={onDragEnd}
        shapeTransformer={shapeTransformer}
      />
      <g id="transform" className={styles.transform}>
        {rotateEnabled ? (
          <>
            <line
              id={`lineRotate${object.id}`}
              x1={topCenter.x}
              y1={topCenter.y}
              x2={rotationPos.x}
              y2={rotationPos.y}
              stroke={stroke}
              strokeWidth={`${strokeWidth}em`}
            />
            <Circle id={`rotate${object.id}`} cx={rotationPos.x} cy={rotationPos.y} rotate />
          </>
        ) : null}
        <rect
          id={`transformer${object.id}`}
          x={0}
          y={0}
          transform={`translate(${object.x}, ${object.y}) rotate(${object.rotation || 0})`}
          width={object.width}
          height={object.height}
          fill={fill}
          strokeWidth={`${strokeWidth}em`}
          stroke={stroke}
        />
        {includes(enabledAnchors, 'top-left') ? (
          <Circle id={`topLeft${object.id}`} cx={object.x} cy={object.y} />
        ) : null}
        {includes(enabledAnchors, 'top-center') ? (
          <Circle id={`topCenter${object.id}`} cx={topCenter.x} cy={topCenter.y} />
        ) : null}
        {includes(enabledAnchors, 'top-right') ? (
          <Circle id={`topRight${object.id}`} cx={topRightPos.x} cy={topRightPos.y} />
        ) : null}
        {includes(enabledAnchors, 'center-left') ? (
          <Circle id={`centerLeft${object.id}`} cx={centerLeft.x} cy={centerLeft.y} />
        ) : null}
        {includes(enabledAnchors, 'center-right') ? (
          <Circle id={`centerRight${object.id}`} cx={centerRight.x} cy={centerRight.y} />
        ) : null}
        {includes(enabledAnchors, 'bottom-left') ? (
          <Circle id={`bottomLeft${object.id}`} cx={bottomLeftPos.x} cy={bottomLeftPos.y} />
        ) : null}
        {includes(enabledAnchors, 'bottom-center') ? (
          <Circle id={`bottomCenter${object.id}`} cx={bottomCenter.x} cy={bottomCenter.y} />
        ) : null}
        {includes(enabledAnchors, 'bottom-right') ? (
          <Circle id={`bottomRight${object.id}`} cx={bottomRightPos.x} cy={bottomRightPos.y} />
        ) : null}
      </g>
    </g>
  );
};

export default Transformer;
