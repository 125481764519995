import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBringForward } from '@fortawesome/pro-regular-svg-icons';
import { useBringForward } from 'hooks';

const BringForward = () => {
  const bringForward = useBringForward();
  return (
    <div className="icon-button-container order-option" onClick={bringForward}>
      <FontAwesomeIcon.Memo className="icon-button" icon={faBringForward} />{' '}
      <span className="order-option-label">Bring forward</span>
    </div>
  );
};

export default BringForward;
