import React from 'react';
import { useShapeIdsByPageId } from 'hooks';
import ObjectContainer from './ObjectContainer';
import BackgroundColor from './BackgroundColor';
import ObjectContainerSvg from './ObjectContainerSvg';

const EditorContent = ({ scaleText, shapeTypes, onlySvg, showEmptyPhoto, originalSrc = true }) => {
  const shapesIds = useShapeIdsByPageId(shapeTypes);
  return (
    <g id="content">
      <BackgroundColor />
      <g id="calculation-element" /> {/* need for calculate text height */}
      {shapesIds.map((id) =>
        onlySvg ? (
          <ObjectContainerSvg key={id} id={id} showEmptyPhoto={showEmptyPhoto} originalSrc={originalSrc} />
        ) : (
          <ObjectContainer key={id} id={id} scaleText={scaleText} />
        ),
      )}
    </g>
  );
};

export default EditorContent;
