import React, { useMemo } from 'react';
import { useCountEmptyImages, useCountPageWithNoImages, useIsOneImageLoaded } from 'hooks';
import WarningText from 'components/common/WarningText';
import pluralize from 'pluralize';

const WarningPageWithNoImages = ({ className }) => {
  const numberOfPages = useCountPageWithNoImages();
  const numberOfEmptyApertures = useCountEmptyImages();
  const isOneImageLoaded = useIsOneImageLoaded();
  const text = useMemo(
    () =>
      numberOfPages > 0
        ? `We found ${numberOfPages} ${pluralize('page', numberOfPages)} with no ${pluralize(
            'photo',
            numberOfPages,
          )} (${numberOfEmptyApertures} missing in total)`
        : `We found ${numberOfEmptyApertures} empty ${pluralize('photo', numberOfEmptyApertures)}`,
    [numberOfPages, numberOfEmptyApertures],
  );

  if ((!numberOfPages && !numberOfEmptyApertures) || !isOneImageLoaded) return null;
  return <WarningText text={text} className={className} type={numberOfPages ? 'danger' : 'warning'} />;
};

export default WarningPageWithNoImages;
