import React from 'react';
import { useViewAllPages } from 'hooks';
import useIsCrop from './hooks/useIsCrop';
import CropPhotoContainer from './components/CropPhotoComponent';
import AllPages from './components/AllPages';
import EditorOnePage from './components/EditorOnePage';

const EditorContainer = () => {
  const [isCrop] = useIsCrop();
  const viewAllPages = useViewAllPages();
  if (isCrop) {
    return <CropPhotoContainer />;
  }
  if (viewAllPages) {
    return <AllPages />;
  }
  return <EditorOnePage />;
};

export default EditorContainer;
