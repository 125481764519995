import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBringFront } from '@fortawesome/pro-regular-svg-icons';
import { useBringToFront } from 'hooks';

const BringToFront = () => {
  const bringToFront = useBringToFront();
  return (
    <div className="icon-button-container order-option" onClick={bringToFront}>
      <FontAwesomeIcon.Memo className="icon-button" icon={faBringFront} />{' '}
      <span className="order-option-label">Bring to front</span>
    </div>
  );
};

export default BringToFront;
