import React from 'react';

import classNames from 'classnames';
import c from './Select.module.scss';

const Select = ({ options, icon, value, onChange }) => {
  // const selectedIndex = useMemo(() => findIndex(options, (option) => isEqual(option.value, _value)), [_value, options]);
  return (
    <div className={classNames(c.select, icon && c.hasIcon)}>
      {icon ? <div className={c.selectIcon}>{icon}</div> : null}
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map(({ label, value }) => (
          <option key={label} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
