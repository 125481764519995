import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { edgeWrapOptions, frameWidthOptions } from 'constants/editor';
import { setFrame, setEdges } from 'actions/scene';
import VerticalSelect from 'components/common/VerticalSelect';
import './Edges.css';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import { useEdgeWrap, useIsDefaultLayout } from 'hooks';

const useUpdateConfig = () => {
  const dispatch = useDispatch();
  return React.useCallback((config) => dispatch(setFrame(config)), [dispatch]);
};

const useUpdateEdges = () => {
  const dispatch = useDispatch();
  return React.useCallback((edges) => dispatch(setEdges({ edges })), [dispatch]);
};

const Edges = () => {
  const dimensions = useSelector(({ scene }) => scene.config.dimensions);
  const edgeWrap = useEdgeWrap();

  const updateConfig = useUpdateConfig();
  const updateEdges = useUpdateEdges();
  const isDefaultLayout = useIsDefaultLayout();

  const setFrameWidth = React.useCallback((frame) => updateConfig({ frame }), [updateConfig]);
  return (
    <div className="edge-tab">
      <div>
        {isDefaultLayout ? (
          <VerticalSelect
            options={edgeWrapOptions}
            onChange={updateEdges}
            value={edgeWrap}
            onlyInnerDelimiters
            preservePaddingForCheck
          />
        ) : null}
      </div>
      <div>
        <h2>Frame thickness</h2>
        <div>
          <MultiOptionButton options={frameWidthOptions} onChange={setFrameWidth} value={dimensions.frame} />
        </div>
      </div>
    </div>
  );
};

export default Edges;
