import React, { useCallback } from 'react';
import { usePhotoByShapeId, useToolbar, useQualityImageByShapeId } from 'hooks';
import ImageWithDropZone from './ImageWithDropZone';

const Layout = ({ object, onClick: _onClick }) => {
  const image = usePhotoByShapeId(object.id);
  const quality = useQualityImageByShapeId(object.id);
  const { openToolbar } = useToolbar();
  const onClick = useCallback(() => {
    if (image?.id) {
      _onClick();
    } else {
      openToolbar('photos');
    }
  }, [_onClick, image?.id, openToolbar]);
  return <ImageWithDropZone object={object} onClick={onClick} quality={quality} />;
};

export default Layout;
