import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateFit } from 'actions/scene';

const useUpdateFit = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(updateFit()), [dispatch]);
};

export default useUpdateFit;
