import React from 'react';
import store from 'store';
import SceneHelper from 'utils/SceneHelper';
import { Provider } from 'react-redux';
import { modes, defaultPageCoordinates } from 'constants/index';
import { createStore } from 'redux';
import BackgroundColor from 'components/editors/NewEditor/components/BackgroundColor';
import { PageConfigContextProvider, usePageConfigContext } from 'context/pageConfig';
import MaskClipPath from 'components/editors/NewEditor/components/MaskClipPath';
import { useMode } from 'hooks';
import Overlay from 'components/editors/NewEditor/components/Overlay';
import EditorContent from 'components/editors/NewEditor/components/EditorContent';
import Fonts from './components/Fonts';

const svgDefaults = {
  xmlns: 'http://www.w3.org/2000/svg',
  'xmlns:xlink': 'http://www.w3.org/1999/xlink',
  version: '1.1',
};

const getFullSvgSizeSVG = (storeState, pageId) => {
  // it is work norm
  const dimensions = SceneHelper.getDimensionsSync(storeState, pageId);
  const svgWidth = dimensions.originalWidth + 2 * dimensions.halfBleed + 2 * dimensions.frame;
  const svgHeight = dimensions.originalHeight + 2 * dimensions.halfBleed + 2 * dimensions.frame;
  const svgMinX = -(dimensions.halfBleed - (dimensions.width - dimensions.originalWidth) / 2);
  const svgMinY = -(dimensions.halfBleed - (dimensions.height - dimensions.originalHeight) / 2);
  return { width: svgWidth, height: svgHeight, frame: dimensions.frame, minX: svgMinX, minY: svgMinY };
};

const SVGPage = ({ options, store: _store }) => {
  const storeState = _store ? _store.getState() : store.getState();
  const mode = useMode();
  const pageConfig = usePageConfigContext();
  const { width: svgWidth, height: svgHeight, minX, minY } = getFullSvgSizeSVG(storeState, pageConfig.pageId);
  const coordinates = options.coordinates || defaultPageCoordinates;
  const showMask = mode === modes.mask && options.renderMask;
  const svgViewBox = {
    x: minX + (coordinates.x / 100) * svgWidth,
    y: minY + (coordinates.y / 100) * svgHeight,
    width: (coordinates.width / 100) * svgWidth,
    height: (coordinates.height / 100) * svgHeight,
  };
  return (
    <svg
      {...svgDefaults}
      width={`${svgViewBox.width}mm`}
      height={`${svgViewBox.height}mm`}
      viewBox={`${svgViewBox.x} ${svgViewBox.y} ${svgViewBox.width} ${svgViewBox.height}`}
    >
      <BackgroundColor offsetX={svgViewBox.x} offsetY={svgViewBox.y} />
      {showMask ? <MaskClipPath /> : null}
      <defs>
        <Fonts />
        <clipPath id="main-canvas-clip">
          <rect x={svgViewBox.x} y={svgViewBox.y} width={svgViewBox.width} height={svgViewBox.height} />
        </clipPath>
      </defs>
      <g mask={`url(#mask-clip-path)${pageConfig?.pageId}`} clipPath="url(#main-canvas-clip)">
        <EditorContent onlySvg />
      </g>
      {showMask ? <Overlay /> : null}
    </svg>
  );
};

const SVGPageContainer = ({ options, storeProvider: _storeProvider }) => {
  const storeState = _storeProvider ? _storeProvider.getState() : store.getState();
  const storeProvider = createStore((s) => s, storeState);
  const pageId = options.pageId ?? SceneHelper.currentPageId(storeState);
  return (
    <Provider store={storeProvider}>
      <PageConfigContextProvider value={{ pageId }}>
        <SVGPage options={options} store={storeProvider} />
      </PageConfigContextProvider>
    </Provider>
  );
};

export default SVGPageContainer;
