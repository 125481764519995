import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useViewAllPages, useTimeAgo } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';

const SavedTimeAgo = () => {
  const isMobile = useIsMobile();
  const canvasId = useSelector(({ app }) => app.canvasId, shallowEqual);
  const lastSaveDate = useSelector(({ toolbar: { lastSaveDate } }) => lastSaveDate);
  const viewAllPages = useViewAllPages();
  const text = useTimeAgo(lastSaveDate);
  if (!canvasId || viewAllPages || !lastSaveDate || !text || isMobile) return null;
  return <div className="design-id">Saved {text}</div>;
};

export default React.memo(SavedTimeAgo);
