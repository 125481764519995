/* eslint-disable react/no-array-index-key */
import React from 'react';
import PhotoLibrary from 'components/common/PhotoLibrary';
import useIsMobile from 'hooks/useIsMobile';
import { TOOLBAR_WIDTH } from '../Toolbar/Toolbar';

const ToolbarPhotoLibrary = ({ isActive, ...props }) => {
  const isMobile = useIsMobile();
  return (
    <PhotoLibrary
      {...props}
      compact
      itemWidth={isMobile ? 100 : '50%'}
      itemHeight={isMobile ? 100 : TOOLBAR_WIDTH / 2}
      maxLine={isMobile ? '2' : '3'}
    />
  );
};

export default ToolbarPhotoLibrary;
