/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Provider } from 'react-redux';
import MainLayout from 'components/layout/MainLayout';
import { Breakpoints, useBreakpoints } from 'react-use-breakpoints';
import useIsMobile from 'hooks/useIsMobile';
import { usePreview } from 'react-dnd-preview';
import vhCheck from 'vh-check';
import ReactGA from 'react-ga';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ModalContainer from 'components/modal/ConfirmModal/ModalContainer';
import config from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import PixaPrints from 'api';
import { open, close } from 'actions/modal';
import { enablePrevent } from './utils/preventLeave';
import Editor from './pages/Editor';
import store from './store';
import './sentry';

!config.isPreventLeave && enablePrevent();

FontAwesomeIcon.Memo = React.memo(FontAwesomeIcon, isEqual);
// eslint-disable-next-line react-hooks/rules-of-hooks

const dndTouchBackendOptions = {
  scrollAngleRanges: [{ start: 160 }, { end: 20 }], // enable horizontal scroll
};

const ImagePreview = () => {
  const { display, itemType, item, style } = usePreview();
  const {
    windowSize: { innerWidth },
  } = useBreakpoints();
  if (!display || innerWidth > 500) {
    return null;
  }
  return (
    <div
      className="item-list__item"
      style={{ ...style, width: `${innerWidth / 4}px`, height: `${innerWidth / 4}px`, opacity: 0.5 }}
    >
      <img
        alt={itemType}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'relative',
          cursor: 'pointer',
        }}
        src={item.photo.thumbnailSrc}
      />
    </div>
  );
};

const onClose = () => store.dispatch(close());

const showError = (error) =>
  store.dispatch(
    open({
      type: 'loading',
      modalProps: {
        onClose: () => {
          onClose();
          error.onDismiss && error.onDismiss();
        },
        success: false,
        message: error.fakeMessage || 'Initialization error',
        cancellable: error.dismissible,
      },
    }),
  );

const waitForErrorDismiss = (error) =>
  new Promise((resolve) => {
    const oldOnDismiss = error.onDismiss || (() => {});
    error.onDismiss = () => {
      oldOnDismiss();
      resolve();
    };
    showError(error);
  });

const initApp = async (setLoading) => {
  setLoading(true);
  try {
    await PixaPrints.fetchSavedScene();
    await PixaPrints.fetchEditorConfig();
  } catch (error) {
    await waitForErrorDismiss(error);
  } finally {
    setLoading(false);
  }
};

function App() {
  useEffect(() => {
    if (config.isProduction) {
      ReactGA.initialize(config.gaTrackingId);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);
  vhCheck({ redefineVh: true }); // set var(--vh, 1vh) for real 1vh
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    initApp(setLoading).catch(showError);
  }, []);
  const dndBackendProps = React.useMemo(
    () => ({
      backend: isMobile ? TouchBackend : HTML5Backend,
      options: isMobile ? dndTouchBackendOptions : undefined,
    }),
    [isMobile],
  );

  // eslint-disable-next-line spaced-comment
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  if (isIE) {
    alert(
      'Unfortunately IE11 is not supported. It is recommended to view the website using the latest version of Google Chrome',
    );
    return null;
  }

  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Muli:300,400,400i,600,700&display=swap" rel="stylesheet" />
        {config.isProduction ? <script src="https://fast.appcues.com/59799.js" /> : undefined}
      </Helmet>
      <Provider store={store}>
        <DndProvider {...dndBackendProps}>
          <MainLayout loading={loading} isMobile={isMobile}>
            <ModalContainer />
            <Editor loading={loading} />
          </MainLayout>
          <ImagePreview />
        </DndProvider>
      </Provider>
    </>
  );
}
const withBreakpoints = function WithBreakpoints() {
  return (
    <Breakpoints>
      <App />
    </Breakpoints>
  );
};
export default withBreakpoints;
