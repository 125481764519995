import React, { useCallback } from 'react';
import Button from 'components/common/Button';
import PixaPrints from 'api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useAdmin, useSetViewAllPages, useViewAllPages, useToolbar, useMode } from 'hooks';
import { modes } from 'constants/index';

const NextStepButton = () => {
  const admin = useAdmin();
  const setViewAllPages = useSetViewAllPages();
  const { closeToolbar } = useToolbar();
  const onClick = useCallback(() => {
    closeToolbar();
    PixaPrints.saveState({ autosave: true });
    setViewAllPages(true);
  }, [closeToolbar, setViewAllPages]);
  const viewAllPages = useViewAllPages();
  const mode = useMode();
  if (
    admin ||
    viewAllPages ||
    mode === modes.canvas ||
    mode === modes.mask ||
    mode === modes.poster ||
    mode === modes.photoPrint
  ) {
    return null;
  }
  return (
    <Button onClick={onClick} className="nav-bar-button" type="button" size="lg" textSize="md" rounded color="azure">
      <span>Next</span>
      <FontAwesomeIcon.Memo icon={faArrowRight} className="arrow" />
    </Button>
  );
};

export default NextStepButton;
