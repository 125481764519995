import { merge, pick } from 'lodash';

// @ts-check

/**
 * @typedef {Object} Margin
 * @property {number} left
 * @property {number} right
 * @property {number} top
 * @property {number} bottom
 */

/**
 * @typedef {Object} CalendarShapeSize
 * @property {number} [paddingLeft]
 * @property {number} [paddingRight]
 * @property {number} [paddingTop]
 * @property {number} [paddingBottom]
 * @property {Margin} [dayMargin]
 */

export const getDefaultCalendarShapeSize = (calendarType = 1) => {
  let defaultCalendarShapeSize = {};

  // Settings for different calendar types
  if (calendarType === 1) {
    defaultCalendarShapeSize = {
      width: 300,
      height: 180,
      padding: 4,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 4,
      paddingBottom: 4,
      dayMargin: {
        left: 4,
        right: 4,
        top: 4,
        bottom: 4,
      },
    };
  } else if (calendarType === 2) {
    // defaultCalendarShapeSize = { width: 1330, height: 80, padding: 4, dayMargin: 3 };
    defaultCalendarShapeSize = {
      width: 300,
      height: 40,
      padding: 4,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 4,
      paddingBottom: 4,
      dayMargin: {
        left: 3,
        right: 3,
        top: 3,
        bottom: 3,
      },
    };
  }
  return defaultCalendarShapeSize;
};

/**
 * @param {Object} params
 * @param {import("actions/scene.types").CalendarType} [params.calendarType] calendar type
 * @param {Object} [params.calendarShape] calendar type
 * @param {CalendarShapeSize} [params.calendarShapeSize] calendar type
 */
export const getCalendarSettings = ({ calendarType = 1, calendarShapeSize: _calendarShapeSize = {} } = {}) => {
  // Default values for the number of days in a row and column
  /** @type {{ row: number; column: number }} count days in row and count days in column */
  let NumberDaysInRowAndColumn = { row: 7, column: 7 };

  // Settings for different calendar types
  if (calendarType === 1) {
    NumberDaysInRowAndColumn = { row: 7, column: 7 };
  } else if (calendarType === 2) {
    NumberDaysInRowAndColumn = { row: 2, column: 31 };
  }

  /** @type {CalendarShapeSize} */
  const calendarShapeSize = merge(getDefaultCalendarShapeSize(calendarType), _calendarShapeSize);

  const calendarShape = pick(calendarShapeSize, [
    'width',
    'height',
    'padding',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
    'paddingBottom',
  ]);

  const DaysContainerShape = {
    width: calendarShape.width - calendarShapeSize.paddingLeft - calendarShapeSize.paddingRight,
    height: calendarShape.height - calendarShapeSize.paddingTop - calendarShapeSize.paddingBottom,
    margin: calendarShapeSize.padding,
    marginLeft: calendarShapeSize.paddingLeft,
    marginRight: calendarShapeSize.paddingRight,
    marginTop: calendarShapeSize.paddingTop,
    marginBottom: calendarShapeSize.paddingBottom,
  };

  /** @type {{ width: number; height: number; margin: Margin }} */
  const DayShape = {
    width: DaysContainerShape.width / NumberDaysInRowAndColumn.column,
    height: DaysContainerShape.height / NumberDaysInRowAndColumn.row,
    margin: calendarShapeSize.dayMargin,
  };

  const calendarSettings = {
    daysOfWeek: 7,
    NumberDaysInRowAndColumn,
    DayShape,
    DaysContainerShape,
    CalendarShape: calendarShape,
  };
  return calendarSettings;
};
