import React from 'react';
import Button from 'components/common/Button';
import Grid from 'components/common/NewGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import c from './BackButton.module.scss';

const fontAwesomeIconStyle = { marginRight: 10, height: '1.2em', width: '1.2em' };

const BackButton = ({ onClick }) => (
  <Grid.Item className={c.gridItem}>
    <Button type="button" size="lg" textSize="md" rounded outline onClick={onClick} className={c.button}>
      <FontAwesomeIcon.Memo icon={faChevronLeft} style={fontAwesomeIconStyle} />
      <span>Go back</span>
    </Button>
  </Grid.Item>
);

export default BackButton;
