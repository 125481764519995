import React from 'react';
import { useShowSwitchView3d } from 'hooks';
import c from './SwitchView3d.module.scss';
import Switch from '../../../common/Switch';
import useView3d from '../hooks/useView3d';

const SwitchView3d = () => {
  const [view3d, toggleView3d] = useView3d();
  const showSwitchView3d = useShowSwitchView3d();
  if (!showSwitchView3d) return null;
  return <Switch checked={view3d} onChange={toggleView3d} label="3D" className={c.switchView3d} />;
};

export default SwitchView3d;
