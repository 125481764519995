import React from 'react';
import { join, replace } from 'lodash';
import { useCurrentPageFonts } from 'hooks';

const Fonts = () => {
  const currentPageFonts = useCurrentPageFonts();
  return (
    <style xmlns="http://www.w3.org/2000/svg" type="text/css">
      @import url(
      {`https://fonts.googleapis.com/css?family=${join(
        currentPageFonts.map((fontFamily) => replace(fontFamily, ' ', '+')),
        '|',
      )}`}
      );
    </style>
  );
};

export default Fonts;
