import React, { useCallback, useEffect, useRef } from 'react';
import useTextIsEditing from 'components/editors/NewEditor/hooks/useTextEditing';
import './TextInput.scss';
import { getTextStyles, useNormalizeObject } from 'utils/utils';
import store from 'store';
import ContentEditable from 'react-contenteditable';
import { useEditedText, useUpdateShape, useEditedTextInInput, usePlaceholdersObject } from 'hooks';
import { addTextArrToTextObjects } from 'actions/scene';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import StoreSceneHelper from 'actions/StoreSceneHelper';

const textInputId = 'textInput';

const TextInput = () => {
  const ref = useRef();
  const object = useEditedText();
  const updateObject = useUpdateShape();
  const normalizeObject = useNormalizeObject();
  const [, toggleTextEditing] = useTextIsEditing();
  const textInInput = useEditedTextInInput();
  const placeholdersObject = usePlaceholdersObject();
  const onChange = useCallback(
    (props) => {
      updateObject({ id: object.id, ...props });
      store.dispatch(addTextArrToTextObjects());
    },
    [object.id, updateObject],
  );
  // disable paste https://github.com/yabwe/medium-editor/issues/543
  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById(textInputId);
      if (elem) {
        elem.addEventListener('paste', (e) => {
          // cancel paste
          e.preventDefault();
        });
        elem.focus();
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!object]);
  if (!object) return null;
  return (
    <foreignObject
      style={{ pointerEvents: 'none' }}
      className={`text${object.id}`}
      transform={`translate(${object.x},${object.y}) rotate(${object.rotation})`}
      width={object.width}
      height="100%"
      overflow="visible"
      onClick={(e) => {
        toggleTextEditing();
        e.stopPropagation();
      }}
    >
      <div
        xmlns="http://www.w3.org/1999/xhtml"
        style={{ background: '#0000', overflow: 'visible', ...getTextStyles(object, 1) }}
      >
        <div style={{ width: '100%', height: 'min-content', minHeight: `${object.fontSize}px`, pointerEvents: 'all' }}>
          <ContentEditable
            innerRef={ref}
            id={textInputId}
            html={textInInput} // innerHTML of the editable div
            className="textWrapper cursorText"
            onClick={(e) => e.stopPropagation()}
            autoFocus
            onChange={() => {
              const container = document.getElementById(textInputId);
              StoreSceneHelper.unwrap(container, 'span');
              const text = document.getElementById(textInputId).innerHTML;
              const renderText = CalculationShapeHelper.addPlaceholders(text, placeholdersObject);
              const shape = { ...object, text };
              const newTextProps = normalizeObject(shape);
              if (newTextProps) {
                onChange({ text, renderText, ...newTextProps });
              }
            }} // handle innerHTML change
          />
        </div>
      </div>
    </foreignObject>
  );
};

export default TextInput;
