import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as onboardingActions from 'actions/onboarding';

export const useOnboarding = (onboardingComponentsQueue = []) => {
  const dispatch = useDispatch();
  const { completed, step } = useSelector((state) => state.onboarding);
  const next = useCallback(() => {
    if (step === onboardingComponentsQueue.length - 1) {
      dispatch(onboardingActions.complete());
    } else {
      dispatch(onboardingActions.next());
    }
  }, [dispatch, onboardingComponentsQueue, step]);
  return [step, completed, next];
};

export default useOnboarding;
