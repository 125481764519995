import React from 'react';

const Rect = ({ strokeDasharray, x, y, width, height, strokeWidth, stroke = '#fff' }) => (
  <>
    <line
      x1={x}
      y1={y}
      x2={x + width}
      y2={y}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      stroke={stroke}
    />
    <line
      x1={x + width}
      y1={y}
      x2={x + width}
      y2={y + height}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      stroke={stroke}
    />
    <line
      x1={x}
      y1={y + height}
      x2={x + width}
      y2={y + height}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      stroke={stroke}
    />
    <line
      x1={x}
      y1={y}
      x2={x}
      y2={y + height}
      strokeDasharray={strokeDasharray}
      strokeWidth={strokeWidth}
      stroke={stroke}
    />
  </>
);

export default Rect;
