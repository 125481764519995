import { useState } from 'react';
import { useInterval } from 'hooks';

const useTimeAgo = (date) => {
  const [text, setText] = useState();
  useInterval(
    () => {
      const leftSecond = (new Date() - date) / 1000;
      if (leftSecond < 10) {
        setText('just now');
      } else if (leftSecond < 20) {
        setText('20s ago');
      } else if (leftSecond < 30) {
        setText('30s ago');
      } else if (leftSecond < 40) {
        setText('40s ago');
      } else {
        const leftMinute = Math.round(leftSecond / 60);
        setText(`${leftMinute} min ago`);
      }
    },
    date ? 1000 : null,
  );

  return text;
};

export default useTimeAgo;
