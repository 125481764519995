import React from 'react';
import { useDisableLayouts, useAdmin, useToggleDisableLayouts } from 'hooks';
import Switch from 'components/common/Switch';

const SwitchDisableLayout = ({ className }) => {
  const isEditArea = useDisableLayouts();
  const toggleEditArea = useToggleDisableLayouts();
  const admin = useAdmin();
  if (!admin) return null;
  return (
    <Switch
      label="Disable layout on this page for users"
      className={className}
      checked={isEditArea}
      onChange={toggleEditArea}
    />
  );
};

export default SwitchDisableLayout;
