import React, { useState } from 'react';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import { useCurrentBackgroundImageId, useMode } from 'hooks';
import { modes } from 'constants/index';
import c from './Backgrounds.module.scss';
import { BackButton, BackgroundImages, BackgroundColors } from './components';

const selectBackgroundOptions = [
  {
    value: true,
    label: 'Image',
  },
  {
    value: false,
    label: 'Color',
  },
];

const Backgrounds = ({ onClickBackButton }) => {
  const currentBackgroundImageId = useCurrentBackgroundImageId();
  const mode = useMode();
  const [selectBackground, setSelectBackground] = useState(!!currentBackgroundImageId);
  return (
    <div className={c.container}>
      <BackButton className={c.goBackButton} onClick={onClickBackButton} />
      {mode !== modes.photoPrint ? (
        <MultiOptionButton options={selectBackgroundOptions} onChange={setSelectBackground} value={selectBackground} />
      ) : null}
      {selectBackground ? <BackgroundImages /> : <BackgroundColors />}
    </div>
  );
};

export default Backgrounds;
