import {
  removeObject,
  removeEditedShape,
  updateShape,
  updateLayoutArea,
  updatePageId,
  changeLayoutGutterType,
  updateProduct,
  updateViewAllPages,
  copyShape,
  pasteShape,
  nextPage,
  previousPage,
  setBackgroundColor,
  toggleEventActive,
  removeEventById,
  toggleIsEditArea,
  toggleDisableLayouts,
  toggleDisableEventsTab,
  toggleHideEventsInGrid,
  removeLayouts,
  setOrientation,
  toggleOrientation,
  removePage,
  changeQuantity,
  updateBorderType,
  bringForward,
  bringBackward,
  bringToFront,
  bringToBack,
  resetCropping,
  toggleBlackAndWhite,
  addNewPageToPhotoBook,
  sortPage,
} from 'actions/scene';
import { useSetIsEditing } from 'components/editors/NewEditor/hooks/useIsEditing';
import { open, close } from 'actions/modal';
import { throttle } from 'lodash';
import { useToolbar } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store';
import PixaPrints from 'api';
import { modalTypes, modes } from 'constants/index';
import SceneHelper from 'utils/SceneHelper';
import { openModal } from 'utils/utils';
import { usePageConfigContext } from 'context/pageConfig';

export const useDeleteObject = (id) => {
  const dispatch = useDispatch();
  const deleteObject = useCallback((_id) => dispatch(removeObject({ id: _id || id })), [dispatch, id]);
  return deleteObject;
};

export const useDeleteEditedShape = () => {
  const dispatch = useDispatch();
  const deleteObject = useCallback(() => dispatch(removeEditedShape()), [dispatch]);
  return deleteObject;
};

export const useCopyObject = (id) => {
  const dispatch = useDispatch();
  const copy = useCallback((_id) => dispatch(copyShape({ id: _id || id })), [dispatch, id]);
  return copy;
};

export const usePasteObject = (id) => {
  const dispatch = useDispatch();
  const paste = useCallback((_id) => dispatch(pasteShape({ id: _id || id })), [dispatch, id]);
  return paste;
};

export const useSetIsEditingAndCloseFooter = (id) => {
  const { closeFooter } = useToolbar();
  const setIsEditing = useSetIsEditing();
  const onClick = useCallback(() => {
    closeFooter();
    setIsEditing(id);
  }, [closeFooter, id, setIsEditing]);
  return onClick;
};

export const useUpdateShape = (_id) => {
  const dispatch = useDispatch();
  return useCallback(
    ({ id, ...modifier }) =>
      dispatch(_id === 'layoutArea' ? updateLayoutArea({ modifier }) : updateShape({ id: _id || id, modifier })),
    [_id, dispatch],
  );
};

export const useUpdateShapeDebounced = (_id) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    throttle(({ id, ...modifier }) => dispatch(updateShape({ id: _id || id, modifier })), 20),
    [_id, dispatch],
  );
};

export const useUpdatePageId = () => {
  const dispatch = useDispatch();
  return useCallback((id) => dispatch(updatePageId({ id })), [dispatch]);
};

export const useUpdateBordersType = () => {
  const dispatch = useDispatch();
  return useCallback((borderType) => dispatch(updateBorderType({ borderType })), [dispatch]);
};

export const useToggleBlackAndWhite = () => {
  const dispatch = useDispatch();
  return useCallback((blackAndWhite) => dispatch(toggleBlackAndWhite({ blackAndWhite })), [dispatch]);
};

export const useChangeLayoutGutterType = () => {
  const dispatch = useDispatch();
  return useCallback((layoutGutterType) => dispatch(changeLayoutGutterType({ layoutGutterType })), [dispatch]);
};

// export const useChangeCoverOption = () => {
//   const dispatch = useDispatch();
//   const sizeOptions = useSelector(({ config }) => config.sizes);
//   return useCallback(
//     (coverOption) => dispatch(changeCoverOption({ coverOption, sizeOptions })),
//     [dispatch, sizeOptions],
//   );
// };

export const useRemoveLayout = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(removeLayouts()), [dispatch]);
};

export const useUpdateProduct = () => {
  const dispatch = useDispatch();
  return useCallback((product) => dispatch(updateProduct({ product })), [dispatch]);
};

export const useSetOrientation = () => {
  const dispatch = useDispatch();
  return useCallback((orientation) => dispatch(setOrientation({ orientation })), [dispatch]);
};

export const useToggleOrientation = () => {
  const pageConfig = usePageConfigContext();
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleOrientation({ pageId: pageConfig?.pageId })), [dispatch, pageConfig?.pageId]);
};

export const useRemovePage = () => {
  const pageConfig = usePageConfigContext();
  const dispatch = useDispatch();
  return useCallback(() => dispatch(removePage({ pageId: pageConfig?.pageId })), [dispatch, pageConfig?.pageId]);
};

export const useChangeQuantity = () => {
  const pageConfig = usePageConfigContext();
  const dispatch = useDispatch();
  return useCallback(
    (increment) => dispatch(changeQuantity({ pageId: pageConfig?.pageId, increment })),
    [dispatch, pageConfig?.pageId],
  );
};

export const useSetBackgroundColor = () => {
  const dispatch = useDispatch();
  return useCallback(
    (value) => {
      const configStore = store.getState();
      const mode = SceneHelper.getMode(configStore);
      const setBackground = ({ changeOnAllPages = false }) => {
        const changeTextColour =
          ({ changeAll, changeOnAllPages }) =>
          () => {
            dispatch(setBackgroundColor({ value, changeAll, changeOnAllPages }));
            store.dispatch(close());
          };
        if (SceneHelper.isChangedTextColor(configStore, changeOnAllPages)) {
          openModal({
            type: modalTypes.confirm,
            modalProps: {
              onConfirm: changeTextColour({ changeAll: true, changeOnAllPages }),
              onClose: changeTextColour({ changeAll: false, changeOnAllPages }),
              header: 'Change text colour',
              text: 'Do you want to change the colour of previously added text?',
              confirmText: 'Yes',
              cancelText: 'No',
            },
          });
        } else {
          dispatch(setBackgroundColor({ value, changeOnAllPages }));
        }
      };
      if (mode === modes.calendar) {
        openModal({
          type: modalTypes.confirm,
          modalProps: {
            onConfirm: async () => {
              await store.dispatch(close());
              setBackground({ changeOnAllPages: false });
            },
            onClose: async () => {
              await store.dispatch(close());
              setBackground({ changeOnAllPages: true });
            },
            header: 'Apply background',
            text: 'Apply background to current page or apply it to all pages?',
            confirmText: 'Current page',
            cancelText: 'All pages',
          },
        });
      } else {
        setBackground({ changeOnAllPages: false });
      }
    },
    [dispatch],
  );
};

export const useSetViewAllPages = () => {
  const dispatch = useDispatch();
  return useCallback((viewAllPages) => dispatch(updateViewAllPages({ viewAllPages })), [dispatch]);
};

export const useOnClickSortPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(updateViewAllPages({ viewAllPages: true, sortPages: true })), [dispatch]);
};

export const useToggleEventActive = () => {
  const dispatch = useDispatch();
  return useCallback((id) => dispatch(toggleEventActive({ id })), [dispatch]);
};

export const useToggleIsEditArea = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleIsEditArea()), [dispatch]);
};

export const useBringForward = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(bringForward()), [dispatch]);
};

export const useAddNewPageToPhotoBook = (countPages) => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(addNewPageToPhotoBook({ countPages })), [countPages, dispatch]);
};

export const useBringToFront = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(bringToFront()), [dispatch]);
};

export const useBringToBack = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(bringToBack()), [dispatch]);
};

export const useBringBackward = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(bringBackward()), [dispatch]);
};

export const useToggleDisableLayouts = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleDisableLayouts()), [dispatch]);
};

export const useToggleDisableEventsTab = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleDisableEventsTab()), [dispatch]);
};

export const useToggleHideEventsInGrid = () => {
  const dispatch = useDispatch();
  return useCallback(() => dispatch(toggleHideEventsInGrid()), [dispatch]);
};

export const useRemoveEventById = (_id) => {
  const dispatch = useDispatch();
  return useCallback(
    (id) => {
      dispatch(
        open({
          type: modalTypes.confirm,
          modalProps: {
            onConfirm: () => {
              dispatch(removeEventById({ id: _id || id }));
              dispatch(close());
            },
            onClose: () => dispatch(close()),
            header: 'Delete event',
            text: 'Are you sure you want to delete this event?',
            confirmText: 'Delete',
            cancelText: 'Cancel',
          },
        }),
      );
    },
    [_id, dispatch],
  );
};

export const useGoToNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (viewAllPages) => {
      dispatch(nextPage({ viewAllPages }));
      await PixaPrints.saveState({ autosave: true });
    },
    [dispatch],
  );
};

export const useResetCropping = () => {
  const dispatch = useDispatch();
  return useCallback(async () => {
    dispatch(resetCropping());
    await PixaPrints.saveState({ autosave: true });
  }, [dispatch]);
};

export const useGoToPreviousPage = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (viewAllPages) => {
      dispatch(previousPage({ viewAllPages }));
      await PixaPrints.saveState({ autosave: true });
    },
    [dispatch],
  );
};

export const useSortPage = () => {
  const pageConfig = usePageConfigContext();
  const dispatch = useDispatch();
  return useCallback(
    async (newPosition) => {
      dispatch(sortPage({ pageId: pageConfig?.pageId, newPosition }));
      await PixaPrints.saveState({ autosave: true });
    },
    [dispatch, pageConfig?.pageId],
  );
};
