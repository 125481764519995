/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import './Grid.scss';
import { useScrollToTop, useScroll } from 'hooks';
import { motion } from 'framer-motion';

const containerMotionVariants = {
  hidden: {
    opacity: 1,
  },
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.05,
    },
  },
};

const gridItemMotionVariants = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const getGridItemStyle = ({ size }) => ({
  width: size,
  paddingTop: size,
});
const emptyArr = [];
const Grid = ({ children, scrollToTop, ref, scrollDeps = emptyArr, size, doNotWrapIntoMotionDiv }) => {
  const [gridScrollRef, scrollToTopGrid] = useScrollToTop();
  React.useEffect(() => {
    scrollToTop && scrollToTop();
    scrollToTopGrid && scrollToTopGrid();
  }, [...scrollDeps, scrollToTop, scrollToTopGrid]);
  const { scrollLeft, scrollRight } = useScroll(gridScrollRef);
  const fontAwesomeIconStyle = { height: '1em', width: '1em' };
  const defaultStyle = React.useMemo(() => getGridItemStyle({ size }), [size]);
  return (
    <div className="grid-back-container" ref={ref}>
      <div className="grid-container">
        <motion.div
          initial="hidden"
          variants={containerMotionVariants}
          animate="visible"
          ref={gridScrollRef}
          className="grid"
        >
          {!doNotWrapIntoMotionDiv
            ? React.Children.toArray(children).map((child) => (
                <motion.div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`gridChildren${child.key}`}
                  className={classnames('grid-item')}
                  style={{ ...defaultStyle }}
                  variants={gridItemMotionVariants}
                >
                  {child}
                </motion.div>
              ))
            : React.Children.toArray(children).map((child) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`gridChildren${child.key}`}
                  className={classnames('grid-item')}
                  style={{ ...defaultStyle }}
                >
                  {child}
                </div>
              ))}
        </motion.div>
        <div className="scroll-container">
          <div className="scroll-to-top" style={{ display: scrollLeft ? '' : 'none' }}>
            <FontAwesomeIcon.Memo icon={faChevronLeft} style={fontAwesomeIconStyle} />
          </div>
          <div className="scroll-to-end" style={{ display: scrollRight ? '' : 'none' }}>
            <FontAwesomeIcon.Memo icon={faChevronRight} style={fontAwesomeIconStyle} />
          </div>
        </div>
      </div>
    </div>
  );
};

const GridItem = ({ children, className, innerClassName, innerStyle, size, ...rest }) => (
  <div className={classnames('grid-item-inner', innerClassName)} style={innerStyle} {...rest}>
    {children}
  </div>
);

GridItem.variants = gridItemMotionVariants;

Grid.Item = GridItem;

export default Grid;
