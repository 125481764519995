import React from 'react';
import { useIsOneImageLoaded } from 'hooks';
import WarningText from 'components/common/WarningText';

const text = `Please insert at least one photo`;
const WarningEmptyImage = ({ className }) => {
  const isOneImageLoaded = useIsOneImageLoaded();
  if (isOneImageLoaded) return null;
  return <WarningText text={text} className={className} type="danger" />;
};

export default WarningEmptyImage;
