import React from 'react';
import './CropFooter.scss';
import ImageQualityToolbar from '../components/ImageQualityToolbar';
import ActionsContainer from '../components/ActionsContainer';

export const CropFooter = (props) => {
  const { hide, quality } = props;
  return (
    <ActionsContainer hide={hide}>
      <ImageQualityToolbar quality={quality} />
    </ActionsContainer>
  );
};

export default CropFooter;
