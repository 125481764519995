/* eslint-disable no-nested-ternary */
import store from 'store';

export const BOUNDARY_RELATIVE_MAX_HEIGHT = 80 / 100;
export const BOUNDARY_RELATIVE_MAX_WIDTH = 100 / 100;
export const MOBILE_BOUNDARY_RELATIVE_MAX_HEIGHT = 70 / 100;
export const MOBILE_BOUNDARY_RELATIVE_MAX_WIDTH = 85 / 100;

/**
 * Used to calculate scene size by window width and height
 * @param {number} width - viewport width
 * @param {number} height - viewport height
 * @param {number} isMobile - boolean
 */
export const getSceneSize = ({ width, height, isMobile }) => {
  const {
    scene: {
      config: {
        boundaryRelativeMaxHeight,
        boundaryRelativeMaxWidth,
        mobileBoundaryRelativeMaxHeight,
        mobileBoundaryRelativeMaxWidth,
      },
    },
  } = store.getState();

  return [
    width *
      (isMobile
        ? mobileBoundaryRelativeMaxWidth || MOBILE_BOUNDARY_RELATIVE_MAX_WIDTH
        : boundaryRelativeMaxWidth || BOUNDARY_RELATIVE_MAX_WIDTH),
    height *
      (isMobile
        ? mobileBoundaryRelativeMaxHeight || MOBILE_BOUNDARY_RELATIVE_MAX_HEIGHT
        : boundaryRelativeMaxHeight || BOUNDARY_RELATIVE_MAX_HEIGHT),
  ];
};
