import React from 'react';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import c from './VerticalSelect.module.scss';

const Select = ({
  options,
  value,
  onChange,
  disabled,
  onlyInnerDelimiters,
  preserveDescriptionHeight,
  preservePaddingForCheck,
}) => (
  <div className={classnames(c.verticalSelectContainer, { [c.onlyInnerDelimiters]: onlyInnerDelimiters })}>
    {options.map((option) => (
      <Option
        key={JSON.stringify(option.value)}
        {...option}
        selected={isEqual(option.value, value)}
        onChange={onChange}
        disabled={disabled}
        preserveDescriptionHeight={preserveDescriptionHeight}
        preservePaddingForCheck={preservePaddingForCheck}
      />
    ))}
  </div>
);

const Option = ({
  value,
  label,
  image,
  selected,
  preserveDescriptionHeight,
  description = preserveDescriptionHeight && ' ',
  onChange,
  preservePaddingForCheck,
  disabled,
}) => (
  <div
    className={c.verticalSelectOption}
    onClick={() => {
      !disabled && onChange(value);
    }}
  >
    <div
      className={classnames(c.verticalSelectOptionContent, 'no-selection', {
        checked: selected,
        'disabled-cursor': disabled,
        // hasImage: !!image,
        [c.emptyDescription]: !description,
      })}
    >
      {image && <img alt={label} src={image} />}
      <div>
        <label>{label}</label>
        <div className={classnames(c.small, { [c.preservePaddingForCheck]: preservePaddingForCheck })}>
          <small>{description}</small>
        </div>
      </div>
    </div>
  </div>
);

export default Select;
